import { useLazyQuery } from '@apollo/client';
import LoadingScreen from 'components/LoadingScreen';
import { Types } from 'contexts/JWTContext';
import { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { endCheckUser, getUserLogin, logoutSuccess, startCheckUser } from 'redux/slices/auth';
import { RootState } from 'redux/store';
import { GET_USER_DETAIL } from '_apis_/queries/user';
// pages
import Login from '../pages/authentication/Login';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const isCheckUser = useSelector((state: RootState) => state.auth.isCheckUser);
  const dispatch = useDispatch();
  const [dataUser, setDataUser] = useState<any>(null);
  const { pathname } = useLocation();
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(startCheckUser());
      getUserDetail();
    } else {
      setDataUser(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
  const [getUserDetail] = useLazyQuery(GET_USER_DETAIL, {
    onCompleted(response) {
      const dataLogin = response.getUserDetail;
      setDataUser(dataLogin);
      dispatch(getUserLogin(dataLogin));
      localStorage.setItem('user', JSON.stringify(dataLogin));
      dispatch({
        type: Types.Login,
        payload: {
          user: dataLogin
        }
      });
      dispatch(endCheckUser());
    },
    onError(error) {
      dispatch(endCheckUser());
      dispatch(logoutSuccess());
      setDataUser(null);
      localStorage.clear();
      window.location.reload();
    },
    fetchPolicy: 'network-only'
  });

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  if (!dataUser?.id) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return isCheckUser ? <LoadingScreen /> : <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
