import { OBFAQQuestion } from '@type/faq';
import { PreviewQuestion } from './previewQuestion';

interface IPreviewQuestionsProps {
  questions: OBFAQQuestion[];
}
export const PreviewQuestions = (props: IPreviewQuestionsProps) => {
  const { questions } = props;
  return (
    <>
      {questions.map((question, index) => (
        <PreviewQuestion key={question.id} FAQQuestion={question} />
      ))}
    </>
  );
};
