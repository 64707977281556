import mock from './mock';
import mockData from '../utils/mock-data';

// ----------------------------------------------------------------------

mock.onGet('/api/booking/all').reply(() => {
  const bookings = [...Array(24)].map((_, index) => ({
    id: mockData.id(index),
    table: index + 1,
    bookingNumber: `000000${index + 1}`,
    createTime: '2022-06-20',
    total: '$300',
    guest: 'Mr.Bean',
    bookingType: `Checkin`,
    numberPeople: index + 1,
    promotion: `Decrease ${index + 1}%`,
    qrcode: 'JRT5KQOTqnawYNiRbgHD'
  }));

  return [200, { bookings }];
});
