import { styled } from '@mui/material/styles';
import { Button, FormLabel } from '@mui/material';

export const PercentageDiscountFormStyle = styled('div')(({ theme }) => ({
  '.recurring-form': {
    '&__content': {
      padding: 10,
      border: '1.5px dashed #DBDEE5',
      '.MuiFormControl-root': {
        marginTop: 0,
        marginBottom: 0
      }
    }
  },
  '.add-other-day': {
    border: '1.5px dashed #DBDEE5',
    width: '100%',
    padding: 10
  },
  '.add-other-time': {
    width: '100%',
    padding: 10
  }
}));

export const AddButton = styled(Button)({
  backgroundColor: '#00AB55',
  color: '#fff',
  width: '100%',
  padding: 10,
  '&:hover': { backgroundColor: '#007B55' }
});

export const DeleteButton = styled(Button)({
  backgroundColor: '#637381',
  color: '#fff',
  width: '100%',
  padding: 10,
  '&:hover': { backgroundColor: '#637381' }
});

export const Label = styled(FormLabel)({
  fontWeight: 600,
  color: '#637381'
});
