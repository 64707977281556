import { AppSection } from '@type/appSection';
import {
  AppContentLocation,
  AppContentType,
  AppSectionInput,
  AppViewType,
  ContentStatus
} from '__generated__/globalTypes';

export const initAppSection: AppSection = {
  id: '',
  contentType: AppContentType.New,
  viewType: AppViewType.verticalList,
  sort: 0,
  location: AppContentLocation.home,
  merchants: [],
  merchantIds: [],
  items: [],
  image: '',
  title: '',
  description: '',
  status: ContentStatus.draft
};

export const convertAppSectionInput = (appSection: AppSection): AppSectionInput => {
  // merchantIds is an array of strings which is present in appSection.merchants
  const merchantIds = appSection.merchants?.map((merchant) => merchant.merchantId) || [];
  const input: AppSectionInput = {
    contentType: appSection.contentType,
    viewType: appSection.viewType,
    sort: appSection.sort,
    location: appSection.location,
    merchantIds: merchantIds,
    items: appSection.items?.map((item) => item.id) || [],
    title: appSection.title,
    description: appSection.description,
    images: appSection.image,
    status: appSection.status || ContentStatus.draft
  };
  if (appSection.radiumInKm) {
    input.radiumInKm = Number(appSection.radiumInKm);
  }
  if (appSection.coordinate) {
    const { latitude, longitude } = appSection.coordinate;
    input.coordinate = { latitude: Number(latitude), longitude: Number(longitude) };
  }
  return input;
};
