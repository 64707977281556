import { gql } from '@apollo/client';

export const GET_ALL_PROMOTIONS = gql`
  query getPromotions {
    getPromotions {
      id
      name
      description
      status
      type
      numberOfDeals
      usedQuantity
      createdAt
      minimumAmount
      merchantId
      orders {
        orderNumber
        sourceType
        createdAt
      }
      bookings {
        id
        createdAt
        merchantId
      }
      canBeUsedWithOtherPromotions
      effectiveDateTime {
        startDate
        endDate
        recurringWeekDays
        hours {
          openStart
          openEnd
        }
      }
      applyOptionDiscount
      excludedPromotionTags
      includedPromotionTags
      includedGetItemsPromoTags
      discountedItemQty
      availableType
      buyN
      freeN
      percentage
      discountAmount
      isPercentage
      upToAmount
      isOffer
      isSignUpPromotion
      isHiddenInApp
      offerVoucherSettings {
        offerName
        offerDescription
        offerExcludedItemTags
        effectiveHoursAfterOfferActivated
        effectiveDateTime {
          startDate
          endDate
          recurringWeekDays
          hours {
            openStart
            openEnd
          }
        }
        minimumAmount
        upToAmount
        voucherTerms
      }
      type
      useType
      isLoyalty
      entrySpendAmountCents
      prizeDescription
      winnerCount
      entryLimitPerUser
      winCountLimitPerPerson
      isEntryAutomatic
      resultingPromotions
      isPaymentRequired
      loyaltyDetails {
        expiryDays
        loyaltyReviewDate
      }
      loyaltySettings {
        pointsPerDollar
        maxPointsPerTransaction
        expiryDays
        loyaltyReviewDate
        loyaltyBenefits {
          id
          name
          description
          requiredPoints
          benefitPromotionIds
        }
      }
    }
  }
`;

export const GET_PROMOTIONS_ON_LUCKY_DRAW = gql`
  query getPromotionsOnLuckyDraw {
    getPromotions {
      id
      name
      status
      effectiveDateTime {
        startDate
        endDate
        recurringWeekDays
        hours {
          openStart
          openEnd
        }
      }
      isOffer
      type
    }
  }
`;

export const GET_PUBLISHED_MENU_CATEGORIES = gql`
  query getPublishedMenuCategories {
    getPublishedMenuCategories {
      name
      id
      altName
      active_begin
      description
      active_end
      hidden_until
      active
      menuId
      cookingPriority
      groups {
        id
        name
        allow_quantity
        force_max
        force_min
        menuId
        options {
          id
          name
          shortName
          default
          groupId
          price
          sort
        }
      }
      items {
        id
        tags
        price
        active
        description
        menuId
        image
        hidden_until
        sort
        name
        active_end
        displayNumber
        altName
        ingredients
        active_begin
        cookingCategory
        categoryId
        sizes {
          name
          price
          id
          default
          groups {
            id
            name
            allow_quantity
            force_max
            force_min
            menuId
            options {
              id
              name
              shortName
              default
              groupId
              price
              sort
            }
          }
        }
      }
    }
  }
`;

export const GET_PERCENTAGE_PROMOTION = gql`
  query getPercentagePromotion($promotionId: String!) {
    getPercentagePromotion(promotionId: $promotionId) {
      id
      name
      description
      percentage
      discountAmount
      isPercentage
      excludedPromotionTags
      includedPromotionTags
      includedGetItemsPromoTags
      numberOfDeals
      availableType
      minimumAmount
      isHiddenInApp
      effectiveDateTime {
        startDate
        endDate
        recurringWeekDays
        hours {
          openStart
          openEnd
        }
      }
      canBeUsedWithOtherPromotions
      isSignUpPromotion
      upToAmount
      isOffer
      offerVoucherSettings {
        offerName
        offerDescription
        offerExcludedItemTags
        effectiveHoursAfterOfferActivated
        effectiveDateTime {
          startDate
          endDate
          recurringWeekDays
          hours {
            openStart
            openEnd
          }
        }
        minimumAmount
        upToAmount
        voucherTerms
      }
      useType
      applyOptionDiscount
      isLoyalty
      loyaltyDetails {
        expiryDays
        loyaltyReviewDate
      }
    }
  }
`;

export const GET_LOYALTY_PROMOTION = gql`
  query getLoyaltyPromotion($promotionId: String!) {
    loyaltyPromotion(promotionId: $promotionId) {
      id
      name
      description
      status
      type
      numberOfDeals
      merchantId
      useType
      isHiddenInApp
      isLoyalty
      includedPromotionTags
      excludedPromotionTags
      canBeUsedWithOtherPromotions
      effectiveDateTime {
        startDate
        endDate
        recurringWeekDays
        hours {
          openStart
          openEnd
        }
      }
      loyaltySettings {
        pointsPerDollar
        maxPointsPerTransaction
        expiryDays
        loyaltyReviewDate
        loyaltyBenefits {
          id
          name
          description
          requiredPoints
          benefitPromotionIds
        }
      }
    }
  }
`;

export const GET_SPEND_PROMOTION = gql`
  query getSpendPromotion($promotionId: String!) {
    getSpendPromotion(promotionId: $promotionId) {
      name
      description
      percentage
      discountAmount
      isPercentage
      numberOfDeals
      availableType
      excludedPromotionTags
      includedPromotionTags
      effectiveDateTime {
        startDate
        endDate
        recurringWeekDays
        hours {
          openStart
          openEnd
        }
      }
      isHiddenInApp
      canBeUsedWithOtherPromotions
      includedGetItemsPromoTags
      discountedItemQty
      minimumAmount
      isSignUpPromotion
      upToAmount
      isOffer
      offerVoucherSettings {
        offerName
        offerDescription
        offerExcludedItemTags
        effectiveHoursAfterOfferActivated
        effectiveDateTime {
          startDate
          endDate
          recurringWeekDays
          hours {
            openStart
            openEnd
          }
        }
        minimumAmount
        upToAmount
        voucherTerms
      }
      useType
      applyOptionDiscount
      isLoyalty
      loyaltyDetails {
        expiryDays
        loyaltyReviewDate
      }
    }
  }
`;

export const GET_BUY_GET_FREE_PROMOTION = gql`
  query getBuyNGetNFreePromotion($promotionId: String!) {
    getBuyNGetNFreePromotion(promotionId: $promotionId) {
      id
      name
      description
      buyN
      freeN
      numberOfDeals
      availableType
      minimumAmount
      effectiveDateTime {
        startDate
        endDate
        recurringWeekDays
        hours {
          openStart
          openEnd
        }
      }
      includedGetItemsPromoTags
      excludedPromotionTags
      includedPromotionTags
      canBeUsedWithOtherPromotions
      percentage
      discountAmount
      isPercentage
      upToAmount
      isOffer
      isHiddenInApp
      offerVoucherSettings {
        offerName
        offerDescription
        offerExcludedItemTags
        effectiveHoursAfterOfferActivated
        effectiveDateTime {
          startDate
          endDate
          recurringWeekDays
          hours {
            openStart
            openEnd
          }
        }
        minimumAmount
        upToAmount
        voucherTerms
      }
      useType
      isSignUpPromotion
      applyOptionDiscount
      isLoyalty
      loyaltyDetails {
        expiryDays
        loyaltyReviewDate
      }
    }
  }
`;

export const PUBLISH_PROMOTION = gql`
  mutation publishPromotion($promotionId: String!) {
    publishPromotion(promotionId: $promotionId)
  }
`;

export const UNPUBLISH_PROMOTION = gql`
  mutation unpublishPromotion($promotionId: String!) {
    unpublishPromotion(promotionId: $promotionId)
  }
`;

export const DELETE_PROMOTION = gql`
  mutation deletePromotion($promotionId: String!) {
    deletePromotion(promotionId: $promotionId)
  }
`;

export const CREATE_PERCENTAGE_PROMOTION = gql`
  mutation createPercentagePromotion($promotion: PercentagePromotionInput!) {
    createPercentagePromotion(promotion: $promotion)
  }
`;

export const CREATE_LOYALTY_PROMOTION = gql`
  mutation createLoyaltyPromotion($promotion: LoyaltyPromotionInput!) {
    createLoyaltyPromotion(promotion: $promotion)
  }
`;

export const UPDATE_PERCENTAGE_PROMOTION = gql`
  mutation updatePercentagePromotion($promotion: PercentagePromotionInput!, $promotionId: String!) {
    updatePercentagePromotion(promotion: $promotion, promotionId: $promotionId)
  }
`;

export const UPDATE_LOYALTY_PROMOTION = gql`
  mutation updateLoyaltyPromotion($promotion: LoyaltyPromotionInput!, $promotionId: String!) {
    updateLoyaltyPromotion(promotion: $promotion, promotionId: $promotionId)
  }
`;

export const CREATE_SPEND_PROMOTION = gql`
  mutation createSpendPromotion($promotion: SpendPromotionInput!) {
    createSpendPromotion(promotion: $promotion)
  }
`;

export const UPDATE_SPEND_PROMOTION = gql`
  mutation updateSpendPromotion($promotion: SpendPromotionInput!, $promotionId: String!) {
    updateSpendPromotion(promotion: $promotion, promotionId: $promotionId)
  }
`;

export const UPDATE_BUY_GET_FREE_PROMOTION = gql`
  mutation updateBuyNGetNFreePromotion(
    $promotion: BuyNGetNFreePromotionInput!
    $promotionId: String!
  ) {
    updateBuyNGetNFreePromotion(promotion: $promotion, promotionId: $promotionId)
  }
`;

export const CREATE_BUY_GET_FREE = gql`
  mutation createBuyNGetNFreePromotion($promotion: BuyNGetNFreePromotionInput!) {
    createBuyNGetNFreePromotion(promotion: $promotion)
  }
`;
// GET_LUCKY_DRAW_PROMOTION
export const GET_LUCKY_DRAW = gql`
  query getLuckyDrawPromotion($promotionId: String!) {
    getLuckyDrawPromotion(promotionId: $promotionId) {
      id
      name
      description
      numberOfDeals
      availableType
      effectiveDateTime {
        startDate
        endDate
        recurringWeekDays
        hours {
          openStart
          openEnd
        }
      }
      isPublished
      useType
      isSignUpPromotion
      prizeDescription
      winnerCount
      entryLimitPerUser
      winCountLimitPerPerson
      isEntryAutomatic
      resultingPromotions
      isPaymentRequired
      isLoyalty
      loyaltyDetails {
        expiryDays
        loyaltyReviewDate
      }
    }
  }
`;

// createLuckyDrawPromotion
export const CREATE_LUCKY_DRAW = gql`
  mutation createLuckyDrawPromotion($promotion: LuckyDrawPromotionInput!) {
    createLuckyDrawPromotion(promotion: $promotion)
  }
`;

// updateLuckyDrawPromotion
export const UPDATE_LUCKY_DRAW = gql`
  mutation updateLuckyDrawPromotion($promotion: LuckyDrawPromotionInput!, $promotionId: String!) {
    updateLuckyDrawPromotion(promotion: $promotion, promotionId: $promotionId)
  }
`;
