import { Box, Button, Card, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Page from 'components/Page';

export const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5)
}));

export const ContainerStyle = styled(Container)(() => ({
  width: '60vw',
  height: '90vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const CardStyle = styled(Card)(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const TypographyCenter = styled(Typography)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const BoxWarrperButton = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const ButtonAction = styled(Button)(() => ({
  margin: '20px',
  padding: '10px'
}));

export const useStyles = makeStyles(() => ({
  image: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '300px',
    height: 'cover'
  },
  success: {
    color: '#00AB55'
  },
  error: {
    color: '#FF3030'
  }
}));
