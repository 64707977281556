// ----------------------------------------------------------------------
export type BookingData = {
  id: string | number;
  name: string;
  table: number | string;
  description: string;
  bookingNumber: string;
  createTime: string;
  total: number | string;
  guest: string;
  bookingType: string;
  numberPeople: number;
  promotion: string;
  qrcode: string;
};

export type BookingDetail = {
  bookingTime?: number;
  numberOfGuests?: number;
  specialInstructions: string;
  promotionId: number | string;
};

export type Geolocation = {
  coordinate?: number;
  latitude?: number;
};

export type Customer = {
  name: string;
  email?: string;
  phoneNumber?: string;
  guestNum?: string;
  address?: string;
  postcode?: string;
  foodAllergies: string;
  geolocation?: Geolocation;
};

export type Booking = {
  id: string;
  createdAt: number;
  merchantId: string;
  customer?: Customer;
  bookingDetails?: BookingDetail;
};

export const DATE_BOOKING = 'dateBooking';
