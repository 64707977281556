import { Box, Checkbox, FormControlLabel, FormGroup, Typography, useTheme } from '@mui/material';
import { Weekday } from '__generated__/globalTypes';
import { useTranslation } from 'react-i18next';
import { useEffectDateTimeList, weekdayList } from './type/type';

interface RecurringWeekDaysProps {
  weekDays: Weekday[];
  index: number;
}

export const RecurringWeekDays: React.FC<RecurringWeekDaysProps> = (props) => {
  const { weekDays, index } = props;
  const { t } = useTranslation();
  const { updateWeekday, isOffer = false } = useEffectDateTimeList();
  const theme = useTheme();
  const handleChangeRecurringWeekday = (
    event: React.ChangeEvent<HTMLInputElement>,
    weekday: Weekday
  ) => {
    const newWeekdays = event.target.checked
      ? [...weekDays, weekday]
      : weekDays.filter((day) => day !== weekday);
    updateWeekday(index, newWeekdays);
  };
  const colorStyle = isOffer ? { color: '#DCC179' } : { color: theme.palette.primary.main };
  const textColor = isOffer ? '#DCC179' : theme.palette.primary.main;
  return (
    <>
      <FormGroup>
        <Typography
          sx={{ color: textColor, fontWeight: 700 }}
          variant="body2"
          gutterBottom
          component="div"
        >
          {t('Recurring week days')}
        </Typography>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          {Object.values(weekdayList).map((weekday) => (
            <FormControlLabel
              sx={{ minWidth: '100px' }}
              key={weekday}
              control={
                <Checkbox
                  checked={weekDays.includes(weekday)}
                  onChange={(event) => handleChangeRecurringWeekday(event, weekday)}
                  sx={{
                    color: colorStyle.color,
                    '&.Mui-checked': {
                      color: colorStyle.color
                    }
                  }}
                />
              }
              label={t(`${weekday}`)}
            />
          ))}
        </Box>
      </FormGroup>
    </>
  );
};
