import { OrderType } from '__generated__/globalTypes';

export type OrderMerchant = {
  merchantId: string;
  tableId?: string;
};

export enum OrderForm {
  ORDERBUDDY_WEB = 'orderbuddyweb',
  ORDERBUDDY_APP_IOS = 'orderbuddyAppIos',
  ORDERBUDDY_APP_ANDROID = 'orderbuddyAppAndroid',
  THIRD_PARTY = 'thirdParty'
}

export type MenuOption = {
  id: string;
  name: string;
  shortName?: string;
  default: Boolean;
  groupId: string;
  menutItemId?: string;
  menuId: string;
  price: number;
  sort: number;
};

export type MenuGroup = {
  id: string;
  name: string;
  allow_quanity: Boolean;
  force_max: string;
  force_min: string;
  menuId: string;
  options: MenuOption;
};

export type MenuItemSize = {
  name: string;
  price: number;
  id: string;
  default: Boolean;
  groups?: MenuGroup;
};

export enum OrderAdjustmentType {
  DISCOUNT = 'discount',
  TAX = 'tax',
  TIP = 'tip',
  HOLIDAY_SURCHARGE = 'holidaySurcharge',
  CARD_SURCHANGE = 'cardSurcharge',
  ORTHER_SURCHANGE = 'otherSurcharge'
}

export type OrderAdjustment = {
  type?: OrderAdjustmentType;
  amount?: number;
  voucherNo?: string;
  description?: string;
};

export enum PaymentType {
  CASH = 'cash',
  CARD = 'card',
  PAYPAL = 'paypal',
  WECHATPAY = 'wechatpay',
  ALIPAY = 'alipay',
  COUNTER = 'counter',
  PAYLATER = 'paylater',
  OTHER = 'other'
}

export type OptionItem = {
  id: string;
  quantity: string;
  price: number;
  option: MenuOption;
};

export type MenuItem = {
  id: string;
  tags?: string[];
  price: number;
  active: Boolean;
  description: string;
  menuId: string;
  image?: string;
  hidden_until?: string;
  sizes: MenuItemSize[];
  sort: number;
  name: string;
  active_end?: number;
  groups?: MenuGroup[];
  displayNumber?: string;
  altName?: string;
  ingredients?: string;
  active_begin?: number;
  cookingCategory?: string;
  categoryId: string;
};

export type OrderItem = {
  size?: MenuItemSize;
  subTotal?: number;
  quantity?: number;
  specialInstructions?: string;
  isTakeaway?: Boolean;
  id: string;
  options: OptionItem[];
  dish: MenuItem;
};

export type Order = {
  pickupTimeType?: string;
  pickupTime?: string;
  selectedDate?: string;
  total: number;
  paymentType: PaymentType;
  type: OrderType;
  notes?: string;
  orderTime?: number;
  adjustments?: OrderAdjustment[];
  items: OrderItem[];
};

export type Geolocation = {
  coordinate?: number;
  latitude?: number;
};

export type Customer = {
  name: string;
  email?: string;
  phoneNumber?: string;
  guestNum?: string;
  address?: string;
  postcode?: string;
  foodAllergies?: string;
  geolocation?: Geolocation;
};

export type OBOrder = {
  mobileModel?: string;
  sourceType?: string;
  merchant: OrderMerchant;
  orderFrom: OrderForm;
  order: Order;
  orderNumber: string;
  user: Customer;
};

export const DATE_ORDER = 'dateOrder';
