import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { BookingSessionsData } from '../../@type/booking-sessions';

// ----------------------------------------------------------------------

type BookingSessionsState = {
  isLoading: boolean;
  error: boolean;
  bookingSessionsList: BookingSessionsData[];
};

const initialState: BookingSessionsState = {
  isLoading: false,
  error: false,
  bookingSessionsList: []
};

const slice = createSlice({
  name: 'booking-sessions',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state: any) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state: any, action: any) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROMOTIONS
    getBookingsSessionsSuccess(state: any, action: any) {
      state.isLoading = false;
      state.bookingSessionsList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;
export const { getBookingsSessionsSuccess } = slice.actions;

// Actions

// ----------------------------------------------------------------------

export function getBookingSessionsList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/booking-sessions/all');
      dispatch(slice.actions.getBookingsSessionsSuccess(response.data.bookings));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
