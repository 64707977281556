import { Grid } from '@mui/material';
import React, { useCallback, useEffect } from 'react';

import { EffectiveDateTimeType } from '../../../effectiveDateTime/type/type';
import { useMultipleEffectiveDateTime } from '../../../effectiveDateTime';
import { DeliveredTypeSelector } from 'components/_dashboard/promotion/components/promoDeliveredTypeSelector';
import {
  checkEffectiveDateTimeListValid,
  getOfferConditionValidationList
} from 'components/_dashboard/promotion/tools';
import { CommonInput } from 'components/_dashboard/promotion/formik/commonInput';
import { renderContent, tabContent } from '../tabContent';
import { IPromoProps } from '../../type';

export const OfferConditions: React.FC<IPromoProps> = (props) => {
  const { formik, setCanMoveFunc, setFieldTouchedNameFunc } = props;
  const { values } = formik;
  const setEffectiveDateTime = (newList: EffectiveDateTimeType[]) => {
    formik.setFieldValue('effectiveDateTime', newList);
  };
  const { effectiveDateTime } = useMultipleEffectiveDateTime({
    dateTimesList: formik.values.effectiveDateTime,
    setDateTimeList: setEffectiveDateTime,
    isOffer: true
  });

  const canMove = useCallback(async () => {
    let message = '';
    let canMove = true;
    try {
      const list = getOfferConditionValidationList();
      for (const path of list) {
        if (path) {
          await setFieldTouchedNameFunc(path);
        }
      }
      checkEffectiveDateTimeListValid(values.effectiveDateTime);
    } catch (e) {
      message = e.message;
      canMove = false;
    } finally {
      return {
        message: message,
        canMove: canMove
      };
    }
  }, [values, setFieldTouchedNameFunc]);

  const wrappedCanMove = useCallback(() => canMove(), [canMove]);
  useEffect(() => {
    setCanMoveFunc(() => wrappedCanMove);
  }, [setCanMoveFunc, wrappedCanMove]);

  const commonContents: tabContent[] = [
    {
      mdSize: 12,
      node: <DeliveredTypeSelector formik={formik} />
    },
    {
      mdSize: 12,
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'offerVoucherSettings.effectiveHoursAfterOfferActivated'}
          type="number"
          label="Next Visit Voucher Activation Time"
          TooltipText="Number of hours to wait after the offer is activated before it can be used."
          placeholder="Effective Hours"
        />
      )
    },
    {
      mdSize: 12,
      node: effectiveDateTime
    }
  ];

  return (
    <Grid sx={{ width: '100%' }} container spacing={1}>
      {renderContent(commonContents)}
    </Grid>
  );
};
