// material
import { useLazyQuery, useQuery } from '@apollo/client';
import { Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getAuthMerchantSuccess } from 'redux/slices/auth';
import { RootState, useSelector } from 'redux/store';
import { fDateString } from 'utils/formatTime';
import { setLocalStorage } from 'utils/localStorage';
import { BOOKINGS } from '_apis_/queries/booking';
import { GET_MERCHANT } from '_apis_/queries/merchant';
import { ORDERS } from '_apis_/queries/order';
// components
import Page from '../../components/Page';
import {
  AppAreaInstalled,
  AppCurrentDownload,
  TodayBooking,
  AppTotalSales,
  AppTotalBooking,
  AppTotalOrder,
  AppWelcome,
  TodayTakeawayOrders,
  ActivePromotions
} from '../../components/_dashboard/general-app';
import useSettings from '../../hooks/useSettings';

export const TIMEZONE = 'timezone';
// ----------------------------------------------------------------------

export default function GeneralApp() {
  const { themeStretch } = useSettings();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const today = new Date();
  const [loading, setLoading] = useState(true);
  const user = useSelector((state: RootState) => state.auth.user);
  const merchantQuery = useQuery(GET_MERCHANT, {
    variables: { merchantId: user?.merchantId },
    fetchPolicy: 'network-only'
  });

  const [detailMerchant] = useLazyQuery(GET_MERCHANT, {
    onCompleted(response) {
      setLocalStorage(TIMEZONE, response?.getMerchant?.timezone);
      dispatch(getAuthMerchantSuccess(response?.getMerchant));
    },
    onError(error) {
      console.log('Get merchant fail: ', error);
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    const variables = {
      merchantId: user?.merchantId
    };
    detailMerchant({ variables });
  }, [user, detailMerchant]);

  const [bookingsTodayQuery, { data: dataBookings }] = useLazyQuery(BOOKINGS, {
    fetchPolicy: 'network-only'
  });

  const [ordersTodayQuery, { data: dataOrders }] = useLazyQuery(ORDERS, {
    onCompleted(response) {
      setLoading(false);
    },
    onError(error) {
      setLoading(false);
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    const variables = {
      dateString: fDateString(today)
    };
    bookingsTodayQuery({ variables });
    ordersTodayQuery({ variables });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allSales = dataBookings?.bookings?.length + dataOrders?.orders?.length || 0;

  return (
    <Page title={t('General App')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <AppWelcome
              displayName={user?.name}
              merchantName={merchantQuery?.data?.getMerchant?.name}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppTotalSales data={allSales} loading={loading} />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppTotalOrder data={dataOrders?.orders?.length || 0} loading={loading} />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppTotalBooking data={dataBookings?.bookings?.length || 0} loading={loading} />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentDownload
              dataBooking={dataBookings?.bookings?.length}
              dataOrder={dataOrders?.orders?.length}
              loading={loading}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppAreaInstalled />
          </Grid>

          <Grid item xs={12} lg={12}>
            <TodayBooking />
          </Grid>

          <Grid item xs={12} lg={12}>
            <TodayTakeawayOrders />
          </Grid>

          <Grid item xs={12} lg={12}>
            <ActivePromotions />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
