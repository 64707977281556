import { Box } from '@mui/material';
import PaymentSuccessIcon from 'assets/payment_success_icon';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'routes/paths';
import {
  BoxWarrperButton,
  ButtonAction,
  CardStyle,
  ContainerStyle,
  RootStyle,
  TypographyCenter,
  useStyles
} from './style';

const PaymentSuccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const styles = useStyles();

  const onSubscriptions = () => {
    navigate(PATH_DASHBOARD.merchant.subscriptions);
  };

  const onContinueSubscribe = () => {
    navigate(PATH_DASHBOARD.merchant.productList);
  };

  return (
    <RootStyle title="Payment Success">
      <ContainerStyle>
        <CardStyle>
          <Box>
            <PaymentSuccessIcon />
          </Box>
          <Box style={{ padding: '20px' }}>
            <TypographyCenter className={styles.success} variant="h3">
              {t('Your Payment is Successfully!')}
            </TypographyCenter>
            <TypographyCenter variant="h4">{t('Thank you for payment!')}</TypographyCenter>
            <TypographyCenter variant="body1">
              {t('You can continue to pay for your next product!')}
            </TypographyCenter>
          </Box>
          <BoxWarrperButton>
            <ButtonAction onClick={onSubscriptions} variant="contained">
              {t('ALL SUBSCRIPTIONS')}
            </ButtonAction>
            <ButtonAction onClick={onContinueSubscribe} variant="contained">
              {t('CONTINUE SUBSCRIBE')}
            </ButtonAction>
          </BoxWarrperButton>
        </CardStyle>
      </ContainerStyle>
    </RootStyle>
  );
};

export default PaymentSuccess;
