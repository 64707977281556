import { useEffect, useState } from 'react';
// material
import {
  Box,
  Button,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
// utils
//
import { useLazyQuery } from '@apollo/client';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Icon } from '@iconify/react';
import { Booking, DATE_BOOKING } from '@type/booking';
import LoadingComponent from 'components/LoadingComponent';
import SearchNotFound from 'components/SearchNotFound';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'routes/paths';
import { fDateString, fDateTimeSecond } from 'utils/formatTime';
import { applySortFilter, getComparator } from 'utils/functions';
import { setLocalStorage } from 'utils/localStorage';
import { BOOKINGS } from '_apis_/queries/booking';
import Scrollbar from '../../Scrollbar';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function TodayBooking() {
  const { t } = useTranslation();
  const today = new Date();
  const navigate = useNavigate();
  const [bookingsToday, setBookingsToday] = useState<Booking[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortList, setSortList] = useState<Booking[]>([]);

  const [getBookingsQuery] = useLazyQuery(BOOKINGS, {
    onCompleted(response) {
      if (response.bookings) {
        setBookingsToday(response.bookings);
      }
      setIsLoading(false);
    },
    onError(error) {
      setIsLoading(false);
    }
  });

  const handleSeeAll = () => {
    setLocalStorage(DATE_BOOKING, fDateString(today));
    navigate(PATH_DASHBOARD.booking.list);
  };

  useEffect(() => {
    const variables = {
      dateString: fDateString(today)
    };
    getBookingsQuery({ variables });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isNoItem = bookingsToday?.length === 0;

  useEffect(() => {
    const tempBookings = bookingsToday?.map((booking: Booking) => {
      return {
        ...booking,
        bookingTime: booking?.bookingDetails?.bookingTime
      };
    });
    const temp = applySortFilter(tempBookings, getComparator('desc', 'bookingTime'), '');
    setSortList(temp);
  }, [bookingsToday]);

  return (
    <Card>
      <CardHeader title={t("Today's Booking")} sx={{ mb: 3 }} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '25%' }}>{t('Booking ID')}</TableCell>
                <TableCell style={{ width: '25%' }}>{t('Customer Name')}</TableCell>
                <TableCell style={{ width: '25%' }}>{t('Booking Time')}</TableCell>
                <TableCell style={{ width: '25%' }}>{t('Number Of Guest')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow style={{ height: 53 }}>
                  <TableCell colSpan={6}>
                    <LoadingComponent />
                  </TableCell>
                </TableRow>
              ) : (
                sortList?.map((row) => {
                  const { id, customer, bookingDetails } = row;
                  return (
                    <TableRow key={id}>
                      <TableCell>{id.slice(0, 4)}</TableCell>
                      <TableCell>{customer?.name}</TableCell>
                      <TableCell>
                        {(bookingDetails?.bookingTime &&
                          fDateTimeSecond(bookingDetails?.bookingTime)) ||
                          '---'}
                      </TableCell>
                      <TableCell>{bookingDetails?.numberOfGuests}</TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
            {isNoItem && !isLoading && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound isNoItem={isNoItem} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          size="small"
          color="inherit"
          onClick={handleSeeAll}
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          {t('View All')}
        </Button>
      </Box>
    </Card>
  );
}
