import { Grid } from '@mui/material';
import React, { useCallback, useEffect } from 'react';

import { PromotionType } from '__generated__/globalTypes';
import { DeliveredTypeSelector } from 'components/_dashboard/promotion/components/promoDeliveredTypeSelector';
import {
  getVoucherConditionValidationList,
  voucherConditionCheck
} from 'components/_dashboard/promotion/tools';
import { useMultipleEffectiveDateTime } from '../../../effectiveDateTime';
import { EffectiveDateTimeType } from '../../../effectiveDateTime/type/type';
import { IPromoProps } from '../../type';
import { renderContent, tabContent } from '../tabContent';

export const VoucherConditions: React.FC<IPromoProps> = (props) => {
  const { formik, setCanMoveFunc, setFieldTouchedNameFunc } = props;
  const { values } = formik;
  const promoType = formik.values.type;
  const setEffectiveDateTime = (newList: EffectiveDateTimeType[]) => {
    formik.setFieldValue('effectiveDateTime', newList);
  };
  const { effectiveDateTime } = useMultipleEffectiveDateTime({
    dateTimesList: formik.values.effectiveDateTime,
    setDateTimeList: setEffectiveDateTime
  });

  const canMove = useCallback(async () => {
    let message = '';
    let canMove = true;
    try {
      // getVoucherConditionValidationList
      const list = getVoucherConditionValidationList(values);
      for (const path of list) {
        await setFieldTouchedNameFunc(path);
      }
      voucherConditionCheck(values);
    } catch (e) {
      message = e.message;
      canMove = false;
    } finally {
      return {
        message: message,
        canMove: canMove
      };
    }
  }, [values, setFieldTouchedNameFunc]);

  const wrappedCanMove = useCallback(() => canMove(), [canMove]);
  useEffect(() => {
    setCanMoveFunc(() => wrappedCanMove);
  }, [setCanMoveFunc, wrappedCanMove]);

  const commonContents: tabContent[] = [
    {
      mdSize: 12,
      node: <DeliveredTypeSelector formik={formik} />,
      isHidden: promoType === PromotionType.loyalty
    },
    {
      mdSize: 12,
      node: effectiveDateTime
    }
  ];

  return (
    <Grid sx={{ width: '100%' }} container spacing={1}>
      {renderContent(commonContents)}
    </Grid>
  );
};
