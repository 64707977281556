import { useTranslation } from 'react-i18next';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useState } from 'react';
import { OBApp } from '__generated__/globalTypes';

export const useSelectedAPPRadio = () => {
  const { t } = useTranslation();
  const [selectedOBAppValue, setSelectedOBAppValue] = useState(OBApp.orderbuddyApp);
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOBAppValue(event.target.value as OBApp);
  };

  const OBAppRadioGroups = () => (
    <FormControl component="fieldset">
      <RadioGroup row name="OBApp" value={selectedOBAppValue} onChange={handleRadioChange}>
        {Object.values(OBApp).map((status) => (
          <FormControlLabel
            key={status}
            value={status}
            control={<Radio />}
            label={t(status.charAt(0).toUpperCase() + status.slice(1))}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );

  return { OBAppRadioGroups, selectedOBAppValue };
};
