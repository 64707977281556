import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { OBOrder } from '../../@type/order';

// ----------------------------------------------------------------------

type OrderState = {
  isLoading: boolean;
  error: boolean;
  orderList: OBOrder[];
};

const initialState: OrderState = {
  isLoading: false,
  error: false,
  orderList: []
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state: any) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state: any, action: any) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getOrdersSuccess(state: any, action: any) {
      state.isLoading = false;
      state.orderList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;
export const { getOrdersSuccess } = slice.actions;

// Actions

// ----------------------------------------------------------------------

export function getOrderList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/order/all');
      dispatch(slice.actions.getOrdersSuccess(response.data.orders));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
