import { Paper, PaperProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

interface SearchNotFoundProps extends PaperProps {
  searchQuery?: string;
  isSearch?: boolean;
  isNoItem?: boolean;
}

export default function SearchNotFound({
  searchQuery = '',
  isSearch = true,
  isNoItem = false,
  ...other
}: SearchNotFoundProps) {
  const { t } = useTranslation();

  return (
    <Paper {...other}>
      {!isNoItem ? (
        <>
          <Typography gutterBottom align="center" variant="subtitle1">
            {t('Not found')}
          </Typography>
          <Typography variant="body2" align="center">
            {t('No results found for')}&nbsp;
            {isSearch ? <strong>&quot;{searchQuery}&quot;</strong> : t('filter')}.{' '}
            {t('Try checking for typos or using complete words')}.
          </Typography>{' '}
        </>
      ) : (
        <Typography variant="body2" align="center">
          {t('No items')}.
        </Typography>
      )}
    </Paper>
  );
}
