import { useLocation, useParams } from 'react-router-dom';
// material
import { Container } from '@mui/material';
// redux
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import { CreateOfferForm } from 'components/_dashboard/promotion/create/createOfferForm';
import { CreatePromotionForm } from 'components/_dashboard/promotion/create/createPromotionForm';
import { useTranslation } from 'react-i18next';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Page from '../../../components/Page';

// ----------------------------------------------------------------------

enum DisplayForm {
  promotion = 'promotion',
  offer = 'offer'
}

enum PromotionType {
  Percentage = 'Amount/Percentage',
  Item = 'Item',
  StackableSpend = 'Stackable Spend',
  LuckyDraw = 'Lucky Draw',
  LoyaltyPointsEdit = 'Edit Loyalty Points',
  LoyaltyPointsCreate = 'Loyalty Points'
}

interface PromotionProps {
  isLoyalty?: boolean;
}

export const Promotion = (props: PromotionProps) => {
  const { themeStretch } = useSettings();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { name = '' } = useParams();
  const { isLoyalty = false } = props;
  const isOffer = pathname.includes('offer');
  const displayForm = isOffer ? DisplayForm.offer : DisplayForm.promotion;

  const getPromotionType = (pathname: string, name?: string): string => {
    if (pathname.includes('percentage')) {
      return PromotionType.Percentage;
    }
    if (pathname.includes('buynGetFree')) {
      return PromotionType.Item;
    }
    if (pathname.includes('spend')) {
      return PromotionType.StackableSpend;
    }
    if (pathname.includes('luckyDraw')) {
      return PromotionType.LuckyDraw;
    }
    if (
      pathname.includes('loyalty') &&
      !pathname.includes('percentage') &&
      !pathname.includes('buynGetFree')
    ) {
      return name ? PromotionType.LoyaltyPointsEdit : PromotionType.LoyaltyPointsCreate;
    }
    return '';
  };

  const getPromotionSuffix = (isLoyalty: boolean, isOffer: boolean): string => {
    if (isLoyalty) {
      return 'Loyalty';
    }
    if (isOffer) {
      return 'Offer';
    }
    return 'Promotion';
  };

  const getTitle = (
    name: string,
    pathname: string,
    isLoyalty: boolean,
    isOffer: boolean,
    t: (key: string) => string
  ): string => {
    let title = name ? t('Edit') : t('Create');

    const promotionType = getPromotionType(pathname, name);
    if (promotionType) {
      title += ` ${t(promotionType)}`;
    }

    const promotionSuffix = getPromotionSuffix(isLoyalty, isOffer);
    title += ` ${t(promotionSuffix)}`;

    return title;
  };

  return (
    <Page title={t('Create a new promotion')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading={getTitle(name, pathname, isLoyalty, isOffer, t)}
          links={[
            { name: t('Dash Board'), href: PATH_DASHBOARD.root },
            { name: t('Promotion'), href: PATH_DASHBOARD.promotion.list },
            { name: getTitle(name, pathname, isLoyalty, isOffer, t) }
          ]}
        />
        {displayForm === DisplayForm.promotion && <CreatePromotionForm isLoyalty={isLoyalty} />}
        {displayForm === DisplayForm.offer && <CreateOfferForm isLoyalty={isLoyalty} />}
      </Container>
    </Page>
  );
};
