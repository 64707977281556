// material
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function PaymentSuccessIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        style={{ width: 300, height: 'cover' }}
        viewBox="0 0 512 512"
        enableBackground="new 0 0 512 512"
        xmlSpace="preserve"
      >
        <path
          fill="#FFDC64"
          d="M418.472,367.165H25.119c-9.446,0-17.102-7.656-17.102-17.102V93.528
	c0-9.446,7.656-17.102,17.102-17.102h393.353c9.446,0,17.102,7.656,17.102,17.102v256.534
	C435.574,359.508,427.918,367.165,418.472,367.165z"
        />
        <g>
          <path
            fill="#FFC850"
            d="M136.284,204.693H67.875c-4.722,0-8.551-3.829-8.551-8.551v-51.307c0-4.722,3.829-8.551,8.551-8.551
		h68.409c4.722,0,8.551,3.829,8.551,8.551v51.307C144.835,200.864,141.006,204.693,136.284,204.693z"
          />
          <path
            fill="#FFC850"
            d="M401.37,204.693c-70.839,0-128.267,57.427-128.267,128.267c0,11.865,1.739,23.3,4.753,34.205
		h140.616c9.445,0,17.102-7.658,17.102-17.102V209.448C424.67,206.432,413.234,204.693,401.37,204.693z"
          />
        </g>
        <circle fill="#FF507D" cx="294.48" cy="166.213" r="38.48" />
        <circle fill="#FFC850" cx="345.787" cy="166.213" r="38.48" />
        <path
          fill="#FF8C66"
          d="M307.307,166.213c0,11.352,5.008,21.451,12.827,28.493c7.819-7.043,12.827-17.142,12.827-28.493
	c0-11.352-5.008-21.451-12.827-28.493C312.315,144.762,307.307,154.861,307.307,166.213z"
        />
        <circle fill="#B4E66E" cx="401.37" cy="332.96" r="102.614" />
        <path
          d="M273.102,359.148H25.119c-5.01,0-9.086-4.076-9.086-9.086V93.528c0-5.01,4.076-9.086,9.086-9.086h393.353
	c5.01,0,9.086,4.076,9.086,9.086v111.167c0,4.427,3.589,8.017,8.017,8.017c4.427,0,8.017-3.589,8.017-8.017V93.528
	c0-13.851-11.268-25.119-25.119-25.119H25.119C11.268,68.409,0,79.677,0,93.528v256.534c0,13.851,11.268,25.119,25.119,25.119
	h247.983c4.427,0,8.017-3.589,8.017-8.017C281.119,362.738,277.53,359.148,273.102,359.148z"
        />
        <path
          d="M401.37,222.33c-61.002,0-110.63,49.629-110.63,110.63s49.629,110.63,110.63,110.63S512,393.962,512,332.96
	S462.371,222.33,401.37,222.33z M401.37,427.557c-52.161,0-94.597-42.436-94.597-94.597s42.436-94.597,94.597-94.597
	s94.597,42.436,94.597,94.597S453.53,427.557,401.37,427.557z"
        />
        <path
          d="M67.875,212.71h68.409c9.136,0,16.568-7.432,16.568-16.568v-51.307c0-9.136-7.432-16.568-16.568-16.568H67.875
	c-9.136,0-16.568,7.432-16.568,16.568v51.307C51.307,205.278,58.739,212.71,67.875,212.71z M136.818,144.835v51.307
	c0,0.295-0.239,0.534-0.534,0.534h-34.739v-18.171h9.086c4.427,0,8.017-3.589,8.017-8.017s-3.589-8.017-8.017-8.017h-9.086v-18.171
	h34.739C136.579,144.301,136.818,144.54,136.818,144.835z M67.34,144.835c0-0.295,0.239-0.534,0.534-0.534h17.637v52.376H67.875
	c-0.295,0-0.534-0.239-0.534-0.534V144.835z"
        />
        <path
          d="M320.155,127.445c-7.571-5.017-16.489-7.729-25.675-7.729c-25.638,0-46.497,20.858-46.497,46.497
	s20.858,46.497,46.497,46.497c9.47,0,18.284-2.853,25.641-7.735c7.572,5.013,16.499,7.735,25.666,7.735
	c25.638,0,46.497-20.858,46.497-46.497s-20.858-46.497-46.497-46.497C336.32,119.716,327.509,122.567,320.155,127.445z
	 M264.017,166.213c0-16.798,13.666-30.463,30.463-30.463c4.781,0,9.448,1.127,13.652,3.234c-5.555,7.66-8.842,17.065-8.842,27.229
	c0,9.885,3.145,19.378,8.824,27.23c-4.106,2.064-8.735,3.233-13.634,3.233C277.683,196.676,264.017,183.011,264.017,166.213z
	 M376.251,166.213c0,16.798-13.666,30.463-30.463,30.463c-4.773,0-9.444-1.129-13.65-3.237c5.554-7.66,8.84-17.064,8.84-27.227
	c0-4.427-3.589-8.017-8.017-8.017c-4.427,0-8.017,3.589-8.017,8.017c0,6.037-1.772,11.666-4.814,16.404
	c-3.102-4.849-4.806-10.52-4.806-16.404c0-16.798,13.666-30.463,30.463-30.463C362.585,135.749,376.251,149.415,376.251,166.213z"
        />
        <path
          d="M59.324,272.568h68.409c4.427,0,8.017-3.589,8.017-8.017c0-4.427-3.589-8.017-8.017-8.017H59.324
	c-4.427,0-8.017,3.589-8.017,8.017C51.307,268.979,54.896,272.568,59.324,272.568z"
        />
        <path
          d="M59.324,323.875h205.228c4.427,0,8.017-3.589,8.017-8.017c0-4.427-3.589-8.017-8.017-8.017H59.324
	c-4.427,0-8.017,3.589-8.017,8.017C51.307,320.285,54.896,323.875,59.324,323.875z"
        />
        <path
          d="M230.347,272.568c4.427,0,8.017-3.589,8.017-8.017c0-4.427-3.589-8.017-8.017-8.017h-68.409
	c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017H230.347z"
        />
        <path
          d="M281.653,256.534h-17.102c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017h17.102
	c4.427,0,8.017-3.589,8.017-8.017C289.67,260.124,286.081,256.534,281.653,256.534z"
        />
        <path
          d="M466.896,293.087c-3.131-3.131-8.207-3.131-11.337,0l-71.292,71.291l-37.087-37.087c-3.131-3.131-8.207-3.131-11.337,0
	c-3.131,3.131-3.131,8.206,0,11.337l42.756,42.756c1.565,1.566,3.617,2.348,5.668,2.348s4.103-0.782,5.668-2.348l76.96-76.96
	C470.027,301.293,470.027,296.218,466.896,293.087z"
        />
      </svg>
    </Box>
  );
}
