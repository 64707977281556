import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { Checkbox, FormControl, FormControlLabel, Typography } from '@mui/material';
import { Label } from '../styles';
import { useTranslation } from 'react-i18next';
import { CSSProperties, SyntheticEvent } from 'react';
import { PromotionType } from '__generated__/globalTypes';

interface promoCustomerSegmentProps {
  formik: FormikProps<Promotion>;
  sx?: CSSProperties;
}
export const PromoCustomerSegment: React.FC<promoCustomerSegmentProps> = (props) => {
  const { formik, sx = {} } = props;
  const { isSignUpPromotion, type, isLoyalty } = formik.values;
  const { t } = useTranslation();
  const defaultFormControlStyles: CSSProperties = {
    width: '100%',
    ...sx
  };

  const handleIsSignUpPromotionOnChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    formik.setFieldValue('isSignUpPromotion', checked);
  };

  const isSignUpPromotionDisabled = () => {
    if (!type) {
      return true;
    }
    if (isLoyalty) {
      return true;
    }
    if (
      type === PromotionType.buynGetFree ||
      type === PromotionType.percentage ||
      type === PromotionType.spend
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      <FormControl sx={defaultFormControlStyles}>
        <Label>{t('Customer Segment')}</Label>
        <FormControlLabel
          value={isSignUpPromotion}
          checked={isSignUpPromotion}
          onChange={handleIsSignUpPromotionOnChange}
          control={<Checkbox />}
          label={<Typography variant="body1">New customer sign up</Typography>}
          labelPlacement="end"
          disabled={isSignUpPromotionDisabled()}
        />
        <FormControlLabel
          disabled={true}
          control={<Checkbox />}
          label={<Typography variant="body1">Returning customer</Typography>}
          labelPlacement="end"
        />
      </FormControl>
    </>
  );
};
