import { Button, Card, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SubscriptionItem from './SubscriptionItem';
import EastIcon from '@mui/icons-material/East';
import { PATH_DASHBOARD } from 'routes/paths';
import { useNavigate } from 'react-router';

export interface Subscription {
  name: string;
  unit_amount: number;
  status: string;
  currentPeriodStart: string;
}

const MOCK_SUBSCRIPTIONS: Subscription[] = [
  {
    name: 'Lizard',
    unit_amount: 10,
    status: 'active',
    currentPeriodStart: '20-08-2022'
  },
  {
    name: 'Lizard',
    unit_amount: 10,
    status: 'active',
    currentPeriodStart: '20-08-2022'
  },
  {
    name: 'Lizard',
    unit_amount: 10,
    status: 'active',
    currentPeriodStart: '20-08-2022'
  },
  {
    name: 'Lizard',
    unit_amount: 10,
    status: 'active',
    currentPeriodStart: '20-08-2022'
  }
];

const CurrentSubscriptions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onAddASubscription = () => {
    navigate(PATH_DASHBOARD.merchant.productList);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{ p: 3 }} style={{ minHeight: 600 }}>
          <Button
            endIcon={<EastIcon />}
            sx={{ margin: '20px 0 10px' }}
            variant="outlined"
            onClick={onAddASubscription}
          >
            {t('ADD A SUBSCRIPTION')}
          </Button>
          <Typography sx={{ margin: '10px 0 20px' }} gutterBottom variant="h5" component="div">
            {t('Subscriptions')}
          </Typography>
          <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
            {MOCK_SUBSCRIPTIONS.map((subscription: Subscription, index: number) => {
              return <SubscriptionItem key={index} subscription={subscription} />;
            })}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CurrentSubscriptions;
