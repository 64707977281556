import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { PromotionType } from '__generated__/globalTypes';
import { PercentageSelector } from 'components/_dashboard/promotion/components/percentageSelector';
import { PromoDiscountAmount } from 'components/_dashboard/promotion/components/promoDiscountAmount';
import { PromoFreeN } from 'components/_dashboard/promotion/components/promoFreeN';
import { PromoLoyaltyBenefits } from 'components/_dashboard/promotion/components/promoLoyaltyBenefits';
import { PromoQtyRestricted } from 'components/_dashboard/promotion/components/promoQtyRestricted';
import { TagsIcons } from 'components/_dashboard/promotion/components/tagsIcons';
import { CommonCheckBox } from 'components/_dashboard/promotion/formik/commonCheckBox';
import { CommonInput } from 'components/_dashboard/promotion/formik/commonInput';
import { getVoucherRewardsValidationList } from 'components/_dashboard/promotion/tools';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { PromoTagsSelector } from '../../../components/promoTagsSelector';
import { IPromoProps } from '../../type';
import { renderContent, tabContent } from '../tabContent';

export const VoucherReward: React.FC<IPromoProps> = (props) => {
  const params = useParams();
  const { formik, setCanMoveFunc, setFieldTouchedNameFunc } = props;
  // get type from url params
  const { values } = formik;
  const { isPercentage, isLoyalty } = values;

  const [isExcludeTagsChecked, setIsExcludeTagsChecked] = useState(
    formik.values.excludedPromotionTags?.length
      ? formik.values.excludedPromotionTags?.length > 0
      : false
  );

  const [isUpToAmountChecked, setIsUpToAmountChecked] = useState(
    formik.values.upToAmount ? formik.values.upToAmount > 0 : false
  );

  const handleExcludedTagsCheckBoxOnChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    if (!checked) {
      formik.setFieldValue('excludedPromotionTags', []);
    }
    setIsExcludeTagsChecked(checked);
  };
  const handleUpToAmountCheckBoxOnChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    if (!checked) {
      formik.setFieldValue('offerVoucherSettings.upToAmount', 0);
    }
    setIsUpToAmountChecked(checked);
  };

  const canMove = useCallback(async () => {
    // name is required
    // numberOfDeals is required and greater than 0
    let message = '';
    let canMove = true;
    try {
      const list = getVoucherRewardsValidationList(values);
      for (const path of list) {
        await setFieldTouchedNameFunc(path);
      }
    } catch (e) {
      message = e.message;
      canMove = false;
    } finally {
      return {
        message: message,
        canMove: canMove
      };
    }
  }, [values, setFieldTouchedNameFunc]);

  const wrappedCanMove = useCallback(() => canMove(), [canMove]);
  useEffect(() => {
    setCanMoveFunc(() => wrappedCanMove);
  }, [setCanMoveFunc, wrappedCanMove]);

  const percentageContents: tabContent[] = [
    {
      mdSize: 12,
      node: <PercentageSelector formik={formik} isOffer={false} />
    },
    {
      node: <PromoDiscountAmount formik={formik} />
    },
    {
      node: (
        <PromoTagsSelector
          formik={formik}
          fieldPath="includedGetItemsPromoTags"
          label="Applicable Items"
        />
      )
    },
    {
      mdSize: 12,
      node: <TagsIcons formik={formik} fieldPath="includedGetItemsPromoTags" />
    },
    {
      node: (
        <FormControlLabel
          value={isExcludeTagsChecked}
          checked={isExcludeTagsChecked}
          onChange={handleExcludedTagsCheckBoxOnChange}
          control={<Checkbox />}
          label="Exclude items from promotion"
          labelPlacement="end"
        />
      )
    },
    {
      mdSize: 12,
      node: (
        <PromoTagsSelector
          formik={formik}
          fieldPath="offerVoucherSettings.offerExcludedItemTags"
          label="Inapplicable Items"
        />
      ),
      isHidden: !isExcludeTagsChecked
    },
    {
      mdSize: 12,
      node: <TagsIcons formik={formik} fieldPath="offerVoucherSettings.offerExcludedItemTags" />
    },
    {
      node: (
        <CommonCheckBox
          formik={formik}
          fieldPath="applyOptionDiscount"
          label="Apply discount to options"
        />
      )
    },
    {
      node: (
        <CommonCheckBox
          formik={formik}
          value={isUpToAmountChecked}
          handleOnChange={handleUpToAmountCheckBoxOnChange}
          label="Limit total discount amount"
        />
      ),
      isHidden: !isPercentage
    },
    {
      mdSize: 12,
      node: {
        node: (
          <CommonInput
            formik={formik}
            fieldPath={'offerVoucherSettings.upToAmount'}
            type="text"
            endAdornment="AUD"
            label="Up to Amount"
            placeholder="Up to $50"
          />
        )
      },
      isHidden: !isUpToAmountChecked
    }
  ];

  const buyNFreeNContents: tabContent[] = [
    {
      mdSize: 12,
      node: <PromoQtyRestricted formik={formik} />,
      isHidden: isLoyalty
    },
    {
      node: <PromoFreeN formik={formik} label="Get how many? (next visit)" />
    },
    {
      node: (
        <PromoTagsSelector
          formik={formik}
          fieldPath="includedGetItemsPromoTags"
          label="Applicable Items"
        />
      )
    },
    {
      mdSize: 12,
      node: <TagsIcons formik={formik} fieldPath="includedGetItemsPromoTags" />
    },
    {
      mdSize: 12,
      node: (
        <CommonCheckBox
          formik={formik}
          value={isExcludeTagsChecked}
          handleOnChange={handleExcludedTagsCheckBoxOnChange}
          label="Exclude items from promotion"
        />
      )
    },
    {
      node: (
        <PromoTagsSelector
          formik={formik}
          fieldPath="offerVoucherSettings.offerExcludedItemTags"
          label="Inapplicable Items"
        />
      ),
      isHidden: !isExcludeTagsChecked
    },
    {
      mdSize: 12,
      node: <TagsIcons formik={formik} fieldPath="offerVoucherSettings.offerExcludedItemTags" />,
      isHidden: !isExcludeTagsChecked
    },
    {
      mdSize: 12,
      node: <PercentageSelector formik={formik} isOffer={false} label="Get Item Discount" />
    },
    {
      mdSize: 12,
      node: { node: <PromoDiscountAmount formik={formik} /> }
    },
    {
      node: (
        <CommonCheckBox
          formik={formik}
          fieldPath="applyOptionDiscount"
          label="Apply discount to options"
        />
      )
    }
  ];

  const loyaltyContents: tabContent[] = [
    {
      mdSize: 12,
      node: <PromoLoyaltyBenefits formik={formik} />
    }
  ];
  const render = () => {
    switch (params.promoType) {
      case PromotionType.percentage:
        return renderContent(percentageContents);
      case PromotionType.buynGetFree:
        return renderContent(buyNFreeNContents);
      case PromotionType.loyalty:
        return renderContent(loyaltyContents);
      default:
        return null;
    }
  };
  return (
    <Grid sx={{ width: '100%' }} container spacing={1}>
      {render()}
    </Grid>
  );
};
