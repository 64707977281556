import { Container } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import useSettings from 'hooks/useSettings';
import { useTranslation } from 'react-i18next';
import { RootState, useSelector } from 'redux/store';
import { PATH_DASHBOARD } from 'routes/paths';
import { UserRole } from '__generated__/globalTypes';
import CurrentSubscriptions from './currentSubscriptions/CurrentSubscriptions';

const Subscriptions = () => {
  const { t } = useTranslation();
  const { themeStretch } = useSettings();
  const authUser = useSelector((state: RootState) => state.auth.user) || null;
  const hrefMerchant =
    authUser?.role === UserRole.merchantAdmin
      ? PATH_DASHBOARD.merchant.updateMerchant
      : PATH_DASHBOARD.merchant.list;

  return (
    <Page title={t('Subscriptions')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={t('Subscriptions')}
          links={[
            { name: t('Dash Board'), href: PATH_DASHBOARD.root },
            { name: t('Merchant'), href: hrefMerchant },
            { name: t('Subscriptions') }
          ]}
        />
        <CurrentSubscriptions />
      </Container>
    </Page>
  );
};

export default Subscriptions;
