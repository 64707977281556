import { gql } from '@apollo/client';

export const BOOKINGS = gql`
  query bookings($dateString: String!) {
    bookings(dateString: $dateString) {
      id
      merchantId
      createdAt
      customer {
        name
        email
        phoneNumber
        guestNum
        address
        postcode
        foodAllergies
        geolocation {
          longitude
          latitude
        }
      }
      bookingDetails {
        bookingTime
        numberOfGuests
        specialInstructions
        promotionId
      }
    }
  }
`;

export const BOOKING = gql`
  query booking($dateString: String!, $bookingId: String!) {
    booking(dateString: $dateString, bookingId: $bookingId) {
      id
      merchantId
      createdAt
      customer {
        name
        email
        phoneNumber
        guestNum
        address
        postcode
        foodAllergies
        geolocation {
          longitude
          latitude
        }
      }
      bookingDetails {
        bookingTime
        numberOfGuests
        specialInstructions
        promotionId
      }
    }
  }
`;
