import { gql } from '@apollo/client';

export const TOP_RESTAURANT_TYPES = gql`
  query topRestaurantTypes {
    topRestaurantTypes
  }
`;

export const CREATE_OR_UPDATE_TOP_RESTAURANT_TYPES = gql`
  mutation CreateOrUpdateTopRestaurantTypes($types: [RestaurantType]!) {
    createOrUpdateTopRestaurantTypes(types: $types)
  }
`;
