import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Stack,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { restaurantTypes } from '@type/popular-restaurant-categories';
import { convertCamelCase } from 'utils/convertCamelCase';

const PopularRestaurantCategoriesForm = (props: any) => {
  const { createTopRestaurantTypes, setLoading, allPopular } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [listSelected, setListSelected] = useState<any[]>([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddNewRestaurantTypes = () => {
    if (listSelected) {
      const variables = {
        types: listSelected
      };
      createTopRestaurantTypes({ variables });
    }
    setOpen(false);
    setLoading(true);
  };

  const handleChangeCheckBox = (item: any) => {
    const index = listSelected.findIndex((itemSelected: any) => {
      return itemSelected === item?.value;
    });
    let newSelected: string[] = [];
    if (index === -1) {
      newSelected = newSelected.concat(listSelected, item.value);
    } else if (index === 0) {
      newSelected = newSelected.concat(listSelected.slice(1));
    } else if (index === listSelected.length - 1) {
      newSelected = newSelected.concat(listSelected.slice(0, -1));
    } else if (index > 0) {
      newSelected = newSelected.concat(listSelected.slice(0, index), listSelected.slice(index + 1));
    }
    setListSelected(newSelected);
  };

  useEffect(() => {
    setListSelected(allPopular);
  }, [allPopular, open]);

  return (
    <>
      <Button sx={{ marginTop: '16px' }} variant="contained" onClick={handleClickOpen}>
        {t('Update List')}
      </Button>

      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle sx={{ marginBottom: '20px' }}>{t('Update List')}</DialogTitle>
        <DialogContent sx={{ height: '70vh' }}>
          <Grid container spacing={3} sx={{ marginTop: '16px' }}>
            <Grid item xs={12}>
              <Stack spacing={3} width="100%">
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <FormControl fullWidth>
                    <FormGroup>
                      {restaurantTypes?.map((item: any) => {
                        const { name } = item;
                        const isSelected = listSelected?.find((selectedItem: any) => {
                          return item.value === selectedItem;
                        });
                        return (
                          <FormControlLabel
                            key={name}
                            control={
                              <Checkbox
                                checked={Boolean(isSelected)}
                                onChange={() => handleChangeCheckBox(item)}
                              />
                            }
                            label={convertCamelCase(name)}
                          />
                        );
                      })}
                    </FormGroup>
                  </FormControl>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <Button variant="contained" onClick={handleAddNewRestaurantTypes}>
            {t('Update')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PopularRestaurantCategoriesForm;
