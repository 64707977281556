import { ReactNode } from 'react';
// material
import { ThemeProvider, useTheme } from '@mui/material/styles';
// hooks
//

// ----------------------------------------------------------------------

type ThemePrimaryColorProps = {
  children: ReactNode;
};

export default function ThemePrimaryColor({ children }: ThemePrimaryColorProps) {
  const defaultTheme = useTheme();

  return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>;
}
