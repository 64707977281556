import { useEffect, useState } from 'react';
// material
import {
  Box,
  Button,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
// utils
//
import { useLazyQuery } from '@apollo/client';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Icon } from '@iconify/react';
import { DATE_ORDER, OBOrder } from '@type/order';
import LoadingComponent from 'components/LoadingComponent';
import SearchNotFound from 'components/SearchNotFound';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'routes/paths';
import { fDateString, fDateTimeSecond } from 'utils/formatTime';
import { applySortFilter, getComparator } from 'utils/functions';
import { setLocalStorage } from 'utils/localStorage';
import { ORDERS } from '_apis_/queries/order';
import Scrollbar from '../../Scrollbar';
import { OrderType } from '__generated__/globalTypes';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function TodayTakeawayOrders() {
  const { t } = useTranslation();
  const today = new Date();
  const navigate = useNavigate();
  const [ordersTakeawayToday, setOrdersTakeawayToday] = useState<OBOrder[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortList, setSortList] = useState<OBOrder[]>([]);

  const [ordersQuery] = useLazyQuery(ORDERS, {
    onCompleted(response) {
      if (response.orders) {
        const temp = response?.orders?.filter((item: OBOrder) => {
          return item.order.type === OrderType.pickup;
        });
        setOrdersTakeawayToday(temp);
      }
      setIsLoading(false);
    },
    onError(error) {
      setIsLoading(false);
    },
    fetchPolicy: 'network-only'
  });

  const handleSeeAll = () => {
    setLocalStorage(DATE_ORDER, fDateString(today));
    navigate(PATH_DASHBOARD.order.list);
  };

  useEffect(() => {
    const variables = {
      dateString: fDateString(today)
    };
    ordersQuery({ variables });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isNoItem = ordersTakeawayToday?.length === 0;

  useEffect(() => {
    const tempOrders = ordersTakeawayToday?.map((order: OBOrder) => {
      return {
        ...order,
        orderTime: order?.order?.orderTime
      };
    });
    const temp = applySortFilter(tempOrders, getComparator('desc', 'orderTime'), '');
    setSortList(temp);
  }, [ordersTakeawayToday]);

  return (
    <Card>
      <CardHeader title={t("Today's Takeaway Orders")} sx={{ mb: 3 }} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '25%' }}>{t('Order No')}</TableCell>
                <TableCell style={{ width: '25%' }}>{t('Order Time')}</TableCell>
                <TableCell style={{ width: '25%' }}>{t('User Name')}</TableCell>
                <TableCell style={{ width: '25%' }}>{t('Subtotal')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow style={{ height: 53 }}>
                  <TableCell colSpan={6}>
                    <LoadingComponent />
                  </TableCell>
                </TableRow>
              ) : (
                sortList?.map((row, index) => {
                  const { orderNumber, user, order } = row;
                  return (
                    <TableRow key={index}>
                      <TableCell>{orderNumber.slice(0, 4)}</TableCell>
                      <TableCell>
                        {(order?.orderTime && fDateTimeSecond(order?.orderTime)) || '---'}
                      </TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{`$${order.total}`}</TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
            {isNoItem && !isLoading && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound isNoItem={isNoItem} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          size="small"
          color="inherit"
          onClick={handleSeeAll}
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          {t('View All')}
        </Button>
      </Box>
    </Card>
  );
}
