import mock from './mock';
import mockData from '../utils/mock-data';

// ----------------------------------------------------------------------

mock.onGet('/api/merchant/all').reply(() => {
  const merchants = [...Array(24)].map((_, index) => ({
    id: mockData.id(index),
    name: 'string',
    description: 'string',
    address: 'string',
    contactPhoneNumber: 'string',
    abn: 'string',
    merchantType: 'restaurant',
    gps: {
      latitude: '-29.271225',
      longitude: '-51.1895903'
    },
    openHours: 'set open hours',
    status: 'open',
    image: 'string'
  }));

  return [200, { merchants }];
});
