import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// utils
import { createUserVariables } from '__generated__/createUser';
import { deleteUserVariables } from '__generated__/deleteUser';
import { updateUserVariables } from '__generated__/updateUser';
import { CREATE_USER, DELETE_USER, GET_ALL_USER, UPDATE_USER } from '_apis_/queries/user';
import { removeTypenameKey } from 'components/_dashboard/promotion/tools';
import { client } from 'index';
import { cloneDeep } from 'lodash';
import {
  CreditCard,
  Follower,
  Friend,
  Gallery,
  NotificationSettings,
  Profile,
  User,
  UserAddressBook,
  UserData,
  UserInvoice,
  UserPost
} from '../../@type/user';

// ----------------------------------------------------------------------

type UserState = {
  isLoading: boolean;
  error: boolean;
  myProfile: null | Profile;
  posts: UserPost[];
  users: UserData[];
  userList: User[];
  followers: Follower[];
  friends: Friend[];
  gallery: Gallery[];
  cards: CreditCard[] | null;
  addressBook: UserAddressBook[];
  invoices: UserInvoice[];
  notifications: NotificationSettings | null;
};

const initialState: UserState = {
  isLoading: false,
  error: false,
  myProfile: null,
  posts: [],
  users: [],
  userList: [],
  followers: [],
  friends: [],
  gallery: [],
  cards: null,
  addressBook: [],
  invoices: [],
  notifications: null
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state: any) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state: any, action: any) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET MANAGE USERS
    getUserList(state: any, action: any) {
      state.isLoading = false;
      state.userList = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserList.fulfilled, (state, { payload }) => {
        state.userList = payload;
        state.isLoading = false;
      })
      .addCase(fetchUserList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteUserMutation.fulfilled, (state, action) => {
        // get email from request
        const email = action.meta.arg.email;
        if (!email) return;
        // remove user from userList by email property
        state.userList = state.userList.filter((user) => user.email !== email);
      })
      .addCase(updateUserMutation.fulfilled, (state, action) => {
        const userInput = action.meta.arg.user;
        if (!userInput) return;
        if (!userInput.id) return;
        // find user by id
        const index = state.userList.findIndex((user) => user.id === userInput.id);
        if (index === -1) return;
        // get user by index
        const user = state.userList[index];
        if (userInput.name) user.name = userInput.name;
        if (userInput.phoneNumber) user.phoneNumber = userInput.phoneNumber;
        if (userInput.role) user.role = userInput.role;
        if (userInput.email) user.email = userInput.email;
        if (userInput.merchantId) user.merchantId = userInput.merchantId;
        // update user in userList
        state.userList[index] = user;
      });
  }
});

// Reducer
export default slice.reducer;

export const fetchUserList = createAsyncThunk(
  'user/fetchUserList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query<{ getAllUsers: User[] }>({
        query: GET_ALL_USER,
        fetchPolicy: 'network-only'
      });
      if (response.errors) return rejectWithValue(JSON.stringify(response.errors));
      if (!response.data) rejectWithValue('No data returned');
      // response.data.appSections is read-only object
      const copy = cloneDeep(response.data.getAllUsers);
      removeTypenameKey(copy);
      return copy;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// export const DELETE_USER = gql`
//   mutation deleteUser($email: String!) {
//     deleteUser(email: $email)
//   }
// `;
export const deleteUserMutation = createAsyncThunk<
  Boolean,
  deleteUserVariables,
  { rejectValue: string }
>('user/deleteUserMutation', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{ deleteUser: Boolean }>({
      mutation: DELETE_USER,
      variables: input
    });
    if (response.errors) return rejectWithValue(JSON.stringify(response.errors));
    if (!response.data) rejectWithValue('No data returned');
    if (response.data) {
      return response.data.deleteUser;
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createUserMutation = createAsyncThunk<
  Boolean,
  createUserVariables,
  { rejectValue: string }
>('user/createUserMutation', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{ createUser: Boolean }>({
      mutation: CREATE_USER,
      variables: input
    });
    if (response.errors) return rejectWithValue(JSON.stringify(response.errors));
    if (!response.data) rejectWithValue('No data returned');
    if (response.data) {
      return response.data.createUser;
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// updateUser(
//   user: AccountInput!
//   ): Boolean!
export const updateUserMutation = createAsyncThunk<
  Boolean,
  updateUserVariables,
  { rejectValue: string }
>('user/updateUserMutation', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{ updateUser: Boolean }>({
      mutation: UPDATE_USER,
      variables: input
    });
    if (response.errors) return rejectWithValue(JSON.stringify(response.errors));
    if (!response.data) rejectWithValue('No data returned');
    if (response.data) {
      return response.data.updateUser;
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// Actions
export const { getUserList } = slice.actions;
