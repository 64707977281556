import { Grid } from '@mui/material';

export interface tabContent {
  node: React.ReactElement | JSX.Element | tabContent;
  mdSize?: number;
  sxSize?: number;
  isHidden?: boolean;
}

export const renderContent = (contents: tabContent[]) => {
  return contents.map((content, index) => {
    if (content.isHidden) {
      return null;
    }
    // Check if node is a nested tabContent
    if ((content.node as tabContent).node) {
      // If it's a nested tabContent, recursively render its content
      return (
        <Grid key={index} item md={content.mdSize || 6} xs={content.sxSize || 12}>
          {renderContent([content.node as tabContent])}
        </Grid>
      );
    }
    return (
      <Grid key={index} item md={content.mdSize || 6} xs={content.sxSize || 12}>
        {content.node}
      </Grid>
    );
  });
};
