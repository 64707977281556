import { gql } from '@apollo/client';

export const GET_LOYALTY_MEMBERS = gql`
  query loyaltyMembers {
    loyaltyMembers {
      loyaltyId
      userId
      userName
      userPhone
      userEmail
      merchantId
      loyaltyPoints
      spendProgress {
        currentSpend
        targetSpend
        promotionId
      }
      stampProgress {
        currentStamps
        targetStamps
        promotionId
      }
      redeemHistory {
        promotionId
        usedStamps
        usedPoints
        usedAmount
        createdAt
        voucherId
        description
      }
      earnHistory {
        promotionId
        earnedStamps
        earnedPoints
        earnedAmount
        createdAt
        orderNumber
        orderTotal
        merchantId
        orderItems {
          loyaltyPromotionId
          itemId
          name
          tags
          quantity
          price
          orderTime
        }
        description
      }
    }
  }
`;

export const GET_PROMOTIONS_ON_LOYALTY_MEMBERS = gql`
  query getPromotionsOnLoyaltyMembers {
    getPromotions {
      id
      name
      status
    }
  }
`;
