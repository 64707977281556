import { gql } from '@apollo/client';

export const GET_ALL_MENU = gql`
  query getMenus {
    getMenus {
      id
      name
      description
      isEnabled
      status
      type
      createdAt
      promotionTags
    }
  }
`;

export const CREATE_MENU = gql`
  mutation createMenu($menu: MenuInput!) {
    createMenu(menu: $menu)
  }
`;

export const UPDATE_MENU = gql`
  mutation updateMenu($menu: MenuInput!, $menuId: String!) {
    updateMenu(menu: $menu, menuId: $menuId)
  }
`;

export const DELETE_MENU = gql`
  mutation deleteMenu($menuId: String!) {
    deleteMenu(menuId: $menuId)
  }
`;

export const GET_MENU_GROUPS = gql`
  query MenuGroup($menuId: String!) {
    getMenuGroups(menuId: $menuId) {
      id
      name
      allow_quantity
      force_max
      force_min
      options {
        id
        name
        shortName
        groupId
        default
        price
        sort
      }
      sort
    }
  }
`;

export const CREATE_MENU_GROUP = gql`
  mutation createMenuGroup($group: MenuGroupInput!) {
    createMenuGroup(group: $group)
  }
`;

export const DELETE_MENU_GROUP = gql`
  mutation deleteMenuGroup($menuId: String!, $groupId: String!) {
    deleteMenuGroup(menuId: $menuId, groupId: $groupId)
  }
`;

export const GET_MENU = gql`
  query getMenu($menuId: String!) {
    getMenu(menuId: $menuId) {
      name
      description
      isEnabled
      status
      createdAt
      type
      promotionTags
    }
  }
`;

export const GET_MENU_CATEGORIES = gql`
  query getMenuCategories($menuId: String!) {
    getMenuCategories(menuId: $menuId) {
      name
      id
      altName
      active_begin
      description
      active_end
      hidden_until
      active
      menuId
      cookingPriority
      sort
      promotionTags
      items {
        id
        tags
        price
        active
        description
        menuId
        image
        hidden_until
        sort
        name
        active_end
        displayNumber
        altName
        ingredients
        active_begin
        cookingCategory
        categoryId
        promotionTags
        sizes {
          name
          price
          id
          default
          groups {
            id
            name
            allow_quantity
            force_max
            force_min
            menuId
            options {
              id
              name
              shortName
              default
              groupId
              price
              sort
            }
          }
        }
        groups {
          id
          sort
        }
      }
    }
  }
`;

export const CREATE_MENU_CATEGORY = gql`
  mutation createMenuCategory($category: MenuCategoryInput) {
    createMenuCategory(category: $category)
  }
`;

export const UPDATE_MENU_CATEGORY = gql`
  mutation updateMenuCategory(
    $menuId: String!
    $categroyId: String!
    $category: MenuCategoryInput!
  ) {
    updateMenuCategory(menuId: $menuId, categroyId: $categroyId, category: $category)
  }
`;
export const DELETE_MENU_CATEGORY = gql`
  mutation deleteMenuCategory($menuId: String!, $categroyId: String!) {
    deleteMenuCategory(menuId: $menuId, categroyId: $categroyId)
  }
`;

export const CREATE_MENU_ITEM = gql`
  mutation createMenuItem($item: MenuItemInput) {
    createMenuItem(item: $item)
  }
`;

export const CREATE_MENU_GROUP_OPTION = gql`
  mutation createMenuGroupOption($option: MenuOptionInput!) {
    createMenuGroupOption(option: $option)
  }
`;

export const UPDATE_MENU_GROUP_OPTION = gql`
  mutation updateMenuGroupOptions(
    $menuId: String!
    $groupId: String!
    $options: [MenuOptionInput]!
  ) {
    updateMenuGroupOptions(menuId: $menuId, groupId: $groupId, options: $options)
  }
`;

export const UPDATE_MENU_GROUP = gql`
  mutation updateMenuGroup($menuId: String!, $groupId: String!, $group: MenuGroupInput!) {
    updateMenuGroup(menuId: $menuId, groupId: $groupId, group: $group)
  }
`;

export const UPDATE_MENU_ITEMS_GROUPS = gql`
  mutation updateMenuItemGroups(
    $menuId: String!
    $categroyId: String!
    $menuItemId: String!
    $groups: [MenuItemGroupsInput]
  ) {
    updateMenuItemGroups(
      menuId: $menuId
      categroyId: $categroyId
      menuItemId: $menuItemId
      groups: $groups
    )
  }
`;

export const UPDATE_MENU_ITEM = gql`
  mutation updateMenuItem($menuId: String!, $categroyId: String!, $menuItem: MenuItemInput!) {
    updateMenuItem(menuId: $menuId, categroyId: $categroyId, menuItem: $menuItem)
  }
`;
export const UPDATE_MENU_ITEMS = gql`
  mutation updateMenuItems($menuId: String!, $categroyId: String!, $items: [MenuItemInput]!) {
    updateMenuItems(menuId: $menuId, categroyId: $categroyId, items: $items)
  }
`;

export const PUBLISH_MENU = gql`
  mutation publishMenu($menuId: String!) {
    publishMenu(menuId: $menuId)
  }
`;

export const DELETE_MENU_ITEM_IMAGE = gql`
  mutation deleteMenuItemImage($menuId: String!, $categroyId: String!, $menuItemId: String!) {
    deleteMenuItemImage(menuId: $menuId, categroyId: $categroyId, menuItemId: $menuItemId)
  }
`;

export const MOVE_MENU_ITEM_IMAGE = gql`
  mutation moveMenuItemImage(
    $menuItemId: ID!
    $fromCategoryId: ID!
    $toCategoryId: ID!
    $menuId: ID!
  ) {
    moveMenuItemImage(
      menuItemId: $menuItemId
      fromCategoryId: $fromCategoryId
      toCategoryId: $toCategoryId
      menuId: $menuId
    )
  }
`;
