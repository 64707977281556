import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { Card, Grid } from '@mui/material';
// routes
// @types
import { Promotion } from '../../../../@type/promotion';

// import DatePicker from '@mui/lab/DatePicker';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import LoadingComponent from 'components/LoadingComponent';
import {
  createPromoFunc,
  getInitialPromotionState,
  queryPromoFunc,
  updatePromoFunc
} from '../tools';
import { useSnackbarHelper } from 'components/useSnackbarHelper';
import { PromotionType } from '__generated__/globalTypes';
import { usePromoStepper } from './stepper/stepper';
import { StepLabel } from './stepper/steps';
import { OfferBaseInfo } from './tabs/offer/baseInfo';
import { OfferDetails } from './tabs/offer/details';
import { OfferConditions } from './tabs/offer/conditions';
import { OfferVoucherDetails } from './tabs/offer/voucherDetails';
import { OfferSummary } from './tabs/offer/summary';
import { OfferVoucherBaseInfo } from './tabs/offer/voucherBaseInfo';
import { OfferVoucherConditions } from './tabs/offer/voucherConditions';
import { promoValidationSchema } from '../promoValidationSchema';
import { RootState, useSelector } from 'redux/store';
import { PromotionProps } from './type';
import { PATH_DASHBOARD } from 'routes/paths';
import { DeepKeyOf } from '../formik/type';
import { get } from 'lodash';

export const CreateOfferForm = (props: PromotionProps) => {
  const { showSnackbar } = useSnackbarHelper();
  const navigate = useNavigate();
  const params = useParams();
  const user = useSelector((state: RootState) => state.auth.user);
  const { isLoyalty = false } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isEdit = params.name ? true : false;
  const [promoType] = useState<PromotionType>(PromotionType.buynGetFree);
  const [promotion] = useState<Promotion>(
    getInitialPromotionState(promoType, true, isLoyalty, user?.merchantId)
  );
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: promotion,
    validationSchema: promoValidationSchema,
    onSubmit: async () => {}
  });

  const { setFieldTouched, setValues } = formik;
  const { currentStep, setCanMoveFunc, BreadcrumbsComponent, MoveToStepComponent } =
    usePromoStepper({
      isSubmitting,
      action: CreateOrUpdatePromotion(isEdit),
      promotion: formik.values
    });

  const setFieldTouchedName = useCallback(
    async (name: DeepKeyOf<Promotion>) => {
      if (!name) return;
      const formikErrors = await setFieldTouched(name, true, true);
      if (!formikErrors) return;
      const error = get(formikErrors, name) as string | undefined;
      if (!error) return;
      throw new Error(error);
    },
    [setFieldTouched]
  );

  const fetchData = useCallback(async () => {
    if (!params.name) return;
    try {
      setIsLoading(true);
      const promotion = await queryPromoFunc(promoType, params.name);
      if (!promotion) return;
      setValues(promotion);
    } catch (error) {
      console.error('Error fetching promotion:', error);
    } finally {
      setIsLoading(false);
    }
  }, [params.name, promoType, setValues]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function createPromotion(isPublished: boolean) {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const isSuccess = await createPromoFunc(formik.values, promoType, isPublished);
      if (isSuccess) {
        showSnackbar('Offer created successfully', 'success');
        navigate(PATH_DASHBOARD.promotion.list);
      }
    } catch (error) {
      showSnackbar(error.message, 'error');
    } finally {
      setIsSubmitting(false);
    }
  }

  async function updatePromotion(isPublished: boolean) {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const isSuccess = await updatePromoFunc(formik.values, promoType, isPublished);
      if (isSuccess) {
        showSnackbar('Offer update successfully', 'success');
        setIsSubmitting(false);
        navigate(PATH_DASHBOARD.promotion.list);
      }
    } catch (error) {
      showSnackbar(error.message, 'error');
      setIsSubmitting(false);
    }
  }

  function CreateOrUpdatePromotion(isEdit: boolean) {
    if (isEdit) {
      return updatePromotion;
    } else {
      return createPromotion;
    }
  }

  if (isLoading && isEdit) {
    return <LoadingComponent />;
  }

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off">
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={10} md={10}>
            <Card sx={{ p: 3 }}>
              <BreadcrumbsComponent />
              {currentStep === StepLabel.OfferBaseInfo && (
                <OfferBaseInfo
                  formik={formik}
                  setCanMoveFunc={setCanMoveFunc}
                  setFieldTouchedNameFunc={setFieldTouchedName}
                />
              )}
              {currentStep === StepLabel.OfferDetails && (
                <OfferDetails
                  formik={formik}
                  setCanMoveFunc={setCanMoveFunc}
                  setFieldTouchedNameFunc={setFieldTouchedName}
                />
              )}
              {currentStep === StepLabel.OfferConditions && (
                <OfferConditions
                  formik={formik}
                  setCanMoveFunc={setCanMoveFunc}
                  setFieldTouchedNameFunc={setFieldTouchedName}
                />
              )}
              {currentStep === StepLabel.VoucherBaseInfo && (
                <OfferVoucherBaseInfo
                  formik={formik}
                  setCanMoveFunc={setCanMoveFunc}
                  setFieldTouchedNameFunc={setFieldTouchedName}
                />
              )}
              {currentStep === StepLabel.VoucherDetails && (
                <OfferVoucherDetails
                  formik={formik}
                  setCanMoveFunc={setCanMoveFunc}
                  setFieldTouchedNameFunc={setFieldTouchedName}
                />
              )}
              {currentStep === StepLabel.VoucherConditions && (
                <OfferVoucherConditions
                  formik={formik}
                  setCanMoveFunc={setCanMoveFunc}
                  setFieldTouchedNameFunc={setFieldTouchedName}
                />
              )}
              {currentStep === StepLabel.OfferSummary && <OfferSummary formik={formik} />}
              <MoveToStepComponent />
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};
