import { Grid } from '@mui/material';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import {
  DiscountType,
  useDiscountTypeSelector
} from 'components/_dashboard/promotion/components/useDiscountTypeSelector';
import { PromoTagsSelector } from '../../../components/promoTagsSelector';
import { getOfferDetailsValidationList } from 'components/_dashboard/promotion/tools';
import { CommonInput } from 'components/_dashboard/promotion/formik/commonInput';
import { renderContent, tabContent } from '../tabContent';
import { TagsIcons } from 'components/_dashboard/promotion/components/tagsIcons';
import { CommonCheckBox } from 'components/_dashboard/promotion/formik/commonCheckBox';
import { IPromoProps } from '../../type';

export const OfferDetails: React.FC<IPromoProps> = (props) => {
  const { formik, setCanMoveFunc, setFieldTouchedNameFunc } = props;
  const { values } = formik;
  // const type = formik.values.type;

  const [isExcludeTagsChecked, setIsExcludeTagsChecked] = useState(
    formik.values.excludedPromotionTags?.length
      ? formik.values.excludedPromotionTags?.length > 0
      : false
  );

  const handleExcludedTagsCheckBoxOnChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    if (!checked) {
      formik.setFieldValue('excludedPromotionTags', []);
    }
    setIsExcludeTagsChecked(checked);
  };

  const { discountTypeElement, discountType } = useDiscountTypeSelector({ formik });

  const canMove = useCallback(async () => {
    let message = '';
    let canMove = true;
    try {
      console.log('values', values);
      const list = getOfferDetailsValidationList(values);
      for (const path of list) {
        await setFieldTouchedNameFunc(path);
      }
    } catch (e) {
      message = e.message;
      canMove = false;
    } finally {
      return {
        message: message,
        canMove: canMove
      };
    }
  }, [setFieldTouchedNameFunc, values]);

  const wrappedCanMove = useCallback(() => canMove(), [canMove]);
  useEffect(() => {
    setCanMoveFunc(() => wrappedCanMove);
  }, [setCanMoveFunc, wrappedCanMove]);

  const baseContent: tabContent[] = [
    {
      mdSize: 12,
      node: discountTypeElement
    }
  ];

  const buyNFreeNContents: tabContent[] = [
    ...baseContent,
    {
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'minimumAmount'}
          type="text"
          endAdornment="AUD"
          label="Minimum Spend"
          placeholder="Spend $50 or more to get 10% off"
        />
      ),
      isHidden: discountType !== DiscountType.spend
    },
    {
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'buyN'}
          type="number"
          label="Buy how many?"
          placeholder="Buy 3 items"
        />
      ),
      isHidden: discountType !== DiscountType.buyN
    },
    {
      node: <PromoTagsSelector formik={formik} fieldPath="includedPromotionTags" label="Of what?" />
    },
    {
      mdSize: 12,
      node: <TagsIcons formik={formik} fieldPath="includedPromotionTags" />
    },
    {
      mdSize: 12,
      node: (
        <CommonCheckBox
          formik={formik}
          value={isExcludeTagsChecked}
          handleOnChange={handleExcludedTagsCheckBoxOnChange}
          sx={{
            color: '#DCC179',
            '&.Mui-checked': { color: '#DCC179' }
          }}
          label="Exclude items from promotion"
        />
      )
    },
    {
      node: (
        <PromoTagsSelector
          formik={formik}
          fieldPath="excludedPromotionTags"
          label="Inapplicable Items"
        />
      ),
      isHidden: !isExcludeTagsChecked
    },
    {
      mdSize: 12,
      node: <TagsIcons formik={formik} fieldPath="excludedPromotionTags" />,
      isHidden: !isExcludeTagsChecked
    }
  ];

  return (
    <Grid sx={{ width: '100%' }} container spacing={1}>
      {renderContent(buyNFreeNContents)}
    </Grid>
  );
};
