import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

const LoadingComponent = () => {
  return (
    <Box
      sx={{ width: '100%', marginTop: '10px' }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingComponent;
