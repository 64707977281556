/* eslint-disable react-hooks/rules-of-hooks */
import { DEFAULT_TIME_ZONE, INVALID_DATE } from '@type/global';
import { formatDistanceToNow } from 'date-fns';
import { TIMEZONE } from 'pages/dashboard/GeneralApp';
import { getLocalStorage } from './localStorage';
import { DateTime } from 'luxon';

type DateInput = Date | string | number;
const timezone = getLocalStorage(TIMEZONE);

// ----------------------------------------------------------------------

export function fDate(date: DateInput) {
  return DateTime.fromJSDate(new Date(date)).toFormat('dd MMMM yyyy');
}

export function fDateTime(date: DateInput) {
  return DateTime.fromJSDate(new Date(date)).toFormat('dd MMM yyyy p');
}

export function fTimestamp(date: DateInput) {
  return DateTime.fromJSDate(new Date(date)).toMillis();
}

export function fDateTimeSuffix(date: DateInput) {
  return DateTime.fromJSDate(new Date(date)).toFormat('dd/MM/yyyy hh:mm a');
}

export function fToNow(date: DateInput) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function fDateTimeSecond(date: number) {
  return DateTime.fromMillis(date * 1000)
    .setZone(timezone || DEFAULT_TIME_ZONE)
    .toFormat('dd/MM/yyyy HH:mm:ss');
}

// rm the parameter moment.Moment because there are no relevant calls to it.
export function fDateString(date: DateInput | null): string {
  const dateTime = parseDate(date);
  return dateTime instanceof DateTime ? dateTime.toFormat('yyyy-MM-dd') : dateTime;
}

export function fDateStringDD_MM_YYYY(date: DateInput | null): string {
  const dateTime = parseDate(date);
  return dateTime instanceof DateTime ? dateTime.toFormat('dd-MM-yyyy') : dateTime;
}

export function fDateStringDD_MM_YY(date: DateInput | null): string {
  const dateTime = parseDate(date);
  return dateTime instanceof DateTime ? dateTime.toFormat('dd-MM-yy') : dateTime;
}

export function fDateStringYY_MM_DD(date: DateInput | null): string {
  const dateTime = parseDate(date);
  return dateTime instanceof DateTime ? dateTime.toFormat('yy-MM-dd') : dateTime;
}

export function formatDate(dateStr: string): string {
  const date = DateTime.fromISO(dateStr);
  return date.toFormat('d LLL yyyy');
}

export function parseDateStringDD_MM_YY(dateStr: string): Date {
  const dt = DateTime.fromFormat(dateStr, 'dd-MM-yy');
  const timestamp = dt.toMillis(); //=> 1630435200000
  return new Date(timestamp);
}

function parseDate(date: DateInput | null): DateTime | string {
  if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  } else if (typeof date === 'string') {
    return DateTime.fromISO(date);
  } else if (typeof date === 'number') {
    return DateTime.fromMillis(date);
  }
  return INVALID_DATE;
}
