import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OBFAQ, OBFAQQuestion, OBFAQSection } from '@type/faq';
import { createFAQQuestionVariables } from '__generated__/createFAQQuestion';
import { createFAQSectionVariables } from '__generated__/createFAQSection';
import { deleteFAQQuestionVariables } from '__generated__/deleteFAQQuestion';
import { deleteFAQSectionVariables } from '__generated__/deleteFAQSection';
import { faqVariables } from '__generated__/faq';
import { OBApp } from '__generated__/globalTypes';
import { updateFAQQuestionVariables } from '__generated__/updateFAQQuestion';
import { updateFAQSectionVariables } from '__generated__/updateFAQSection';
import {
  CREATE_FAQ_QUESTION,
  CREATE_FAQ_SECTION,
  DELETE_FAQ_QUESTION,
  DELETE_FAQ_SECTION,
  QUERY_FAQ_SECTIONS,
  UPDATE_FAQ_QUESTION,
  UPDATE_FAQ_SECTION
} from '_apis_/queries/faq';

import { client } from 'index';
import { dispatch } from 'redux/store';

// ----------------------------------------------------------------------

type FAQState = {
  isLoading: boolean;
  error: boolean;
  FAQData: Partial<Record<OBApp, OBFAQSection[]>>;
};

const initialState: FAQState = {
  isLoading: false,
  error: false,
  FAQData: {}
};

const slice = createSlice({
  name: 'FAQ',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state: any) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state: any, action: any) {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateFAQSections(state, action: PayloadAction<{ app: OBApp; sections: OBFAQSection[] }>) {
      const { app, sections } = action.payload;
      state.FAQData[app] = sections;
    },
    updateSection(
      state,
      action: PayloadAction<{
        app: OBApp;
        sectionId: string;
        title?: string;
        description?: string;
        questions?: OBFAQQuestion[];
        sort?: number;
      }>
    ) {
      const { app, sectionId, title, description, questions, sort } = action.payload;
      const sections = state.FAQData[app];
      if (!sections) {
        // create a new section
        state.FAQData[app] = [
          {
            id: sectionId,
            title: title || '',
            description: description || '',
            questions: questions || [],
            sort: sort || 0
          }
        ];
      }
      if (sections) {
        const section = sections.find((sec) => sec.id === sectionId);
        if (section) {
          if (title) {
            section.title = title;
          }
          if (description) {
            section.description = description;
          }
          if (questions) {
            section.questions = questions;
          }
        } else {
          // create a new section
          sections.push({
            id: sectionId,
            title: title || '',
            description: description || '',
            questions: questions || [],
            sort: sort || 0
          });
        }
      }
    },
    deleteSection(state, action: PayloadAction<{ app: OBApp; sectionId: string }>) {
      const { app, sectionId } = action.payload;
      const sections = state.FAQData[app];
      if (sections) {
        const sectionIndex = sections.findIndex((sec) => sec.id === sectionId);
        if (sectionIndex > -1) {
          sections.splice(sectionIndex, 1);
        }
      }
    },
    updateQuestion(
      state,
      action: PayloadAction<{
        app: OBApp;
        sectionId: string;
        questionId: string;
        question: Partial<OBFAQSection>;
      }>
    ) {
      const { app, sectionId, questionId, question } = action.payload;
      const sections = state.FAQData[app];

      if (sections) {
        // Find the section
        const section = sections.find((sec) => sec.id === sectionId);
        if (section) {
          // Find the question index
          const questionIndex = section.questions.findIndex((q) => q.id === questionId);
          if (questionIndex > -1) {
            // Update the question by creating a new array with updated question
            section.questions = [
              ...section.questions.slice(0, questionIndex),
              { ...section.questions[questionIndex], ...question },
              ...section.questions.slice(questionIndex + 1)
            ];
            section.questions.sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0));
          }
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(queryAllOBFAQ.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(queryAllOBFAQ.fulfilled, (state, action) => {
        state.isLoading = false;
        // make a deep copy of the data
        const sortedFAQData = { ...action.payload };
        // sort the sections and questions
        Object.keys(sortedFAQData).forEach((app) => {
          sortedFAQData[app as OBApp] = sortedFAQData[app as OBApp]
            ?.map((section) => {
              const sortedQuestions = [...section.questions].sort((a, b) => a.sort - b.sort);
              return {
                ...section,
                questions: sortedQuestions
              };
            })
            .sort((a, b) => a.sort - b.sort);
        });
        state.FAQData = sortedFAQData;
      })
      .addCase(queryAllOBFAQ.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        console.error(action.payload);
      })
      .addCase(queryFAQ.pending, (state) => {
        state.error = false;
      })
      .addCase(queryFAQ.fulfilled, (state, action) => {
        const { app, sections } = action.payload;
        state.FAQData[app] = sections;
      })
      .addCase(queryFAQ.rejected, (state, action) => {
        state.error = true;
        console.error(action.payload);
      })
      // create question
      .addCase(createFAQQuestionMutation.pending, (state) => {
        state.error = false;
      })
      .addCase(createFAQQuestionMutation.rejected, (state, action) => {
        state.error = true;
        console.error(action.payload);
      })
      // delete question
      .addCase(deleteFAQQuestionMutation.pending, (state) => {
        state.error = false;
      })
      .addCase(deleteFAQQuestionMutation.fulfilled, () => {})
      .addCase(deleteFAQQuestionMutation.rejected, (state, action) => {
        state.error = true;
        console.error(action.payload);
      })
      // update section
      .addCase(updateFAQData, (state, action) => {
        const { app, updatedSections } = action.payload;
        state.FAQData[app] = updatedSections;
      });
  }
});

export const { updateSection, deleteSection, updateQuestion, updateFAQSections } = slice.actions;

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

// faq(
//   app: OBApp!
//   ): OBFAQ
export const queryFAQ = createAsyncThunk<OBFAQ, faqVariables, { rejectValue: string }>(
  'FAQ/queryFAQ',
  async (input, { rejectWithValue }) => {
    try {
      const response = await client.query<{ faq: OBFAQ }>({
        query: QUERY_FAQ_SECTIONS,
        variables: input,
        fetchPolicy: 'network-only'
      });
      const faq = response.data.faq;
      if (!faq) return rejectWithValue('No data returned');
      return faq;
    } catch (error) {
      return rejectWithValue('Failed to query FAQ');
    }
  }
);

// queryOBFAQ
export const queryAllOBFAQ = createAsyncThunk<
  Partial<Record<OBApp, OBFAQSection[]>>,
  void,
  { rejectValue: string }
>('FAQ/queryAllOBFAQ', async (_, { rejectWithValue }) => {
  try {
    const appValues = Object.values(OBApp);
    const results = await Promise.all(appValues.map((app) => dispatch(queryFAQ({ app })).unwrap()));
    const FAQData: Partial<Record<OBApp, OBFAQSection[]>> = {};
    results.forEach((result, index) => {
      const app = appValues[index];
      if (result.sections) {
        FAQData[app] = result.sections;
      }
    });
    return FAQData;
  } catch (error) {
    console.error(error);
    return rejectWithValue('Failed to query all FAQ sections');
  }
});

// createFAQSection
// done
export const createFAQSectionMutation = createAsyncThunk<
  Boolean,
  createFAQSectionVariables,
  { rejectValue: string }
>('FAQ/createFAQSectionMutation', async (input, { rejectWithValue }) => {
  try {
    const { data } = await client.mutate<{ createFAQSection: Boolean }>({
      mutation: CREATE_FAQ_SECTION,
      variables: input
    });
    if (data) {
      return data.createFAQSection;
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// updateFAQSection
export const updateFAQSectionMutation = createAsyncThunk<
  Boolean,
  updateFAQSectionVariables,
  { rejectValue: string }
>('FAQ/updateFAQSectionMutation', async (input, { rejectWithValue }) => {
  try {
    const { data } = await client.mutate<{ updateFAQSection: Boolean }>({
      mutation: UPDATE_FAQ_SECTION,
      variables: input
    });
    if (data) {
      return data.updateFAQSection;
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// deleteFAQSection
// done
export const deleteFAQSectionMutation = createAsyncThunk<
  Boolean,
  deleteFAQSectionVariables,
  { rejectValue: string }
>('FAQ/deleteFAQSectionMutation', async (input, { rejectWithValue }) => {
  try {
    const { data } = await client.mutate<{ deleteFAQSection: Boolean }>({
      mutation: DELETE_FAQ_SECTION,
      variables: input
    });
    if (data) {
      return data.deleteFAQSection;
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// createFAQQuestion
export const createFAQQuestionMutation = createAsyncThunk<
  Boolean,
  createFAQQuestionVariables,
  { rejectValue: string }
>('FAQ/createFAQQuestionMutation', async (input, { rejectWithValue, getState, dispatch }) => {
  try {
    const { data } = await client.mutate<{ createFAQQuestion: Boolean }>({
      mutation: CREATE_FAQ_QUESTION,
      variables: input
    });
    if (data && data.createFAQQuestion) {
      return data.createFAQQuestion;
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// updateFAQQuestion
export const updateFAQQuestionMutation = createAsyncThunk<
  Boolean,
  updateFAQQuestionVariables,
  { rejectValue: string }
>('FAQ/updateFAQQuestionMutation', async (input, { rejectWithValue }) => {
  try {
    const { data } = await client.mutate<{ updateFAQQuestion: Boolean }>({
      mutation: UPDATE_FAQ_QUESTION,
      variables: input
    });
    if (data && data.updateFAQQuestion) {
      return data.updateFAQQuestion;
    }
    // const state = getState() as { FAQ: FAQState };
    // const currentFAQData = state.FAQ.FAQData[input.app];
    // if (!currentFAQData) {
    //   return rejectWithValue(`FAQ data for app ${input.app} not found`);
    // }
    // const updatedSections = currentFAQData.map((section) => {
    //   if (section.id === input.sectionId) {
    //     const updatedQuestions = section.questions.map((question) =>
    //       question.id === input.questionId ? { ...question, ...input.question } : question
    //     );
    //     return { ...section, questions: updatedQuestions };
    //   }
    //   return section;
    // });
    // dispatch(updateFAQData({ app: input.app, updatedSections }));
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// deleteFAQQuestion
export const deleteFAQQuestionMutation = createAsyncThunk<
  Boolean,
  deleteFAQQuestionVariables,
  { rejectValue: string }
>('FAQ/deleteFAQQuestionMutation', async (input, { rejectWithValue, getState, dispatch }) => {
  try {
    const { data } = await client.mutate<{ deleteFAQQuestion: Boolean }>({
      mutation: DELETE_FAQ_QUESTION,
      variables: input
    });
    if (data && data.deleteFAQQuestion) {
      const state = getState() as { FAQ: FAQState };
      const currentFAQData = state.FAQ.FAQData[input.app];
      if (!currentFAQData) {
        return rejectWithValue(`FAQ data for app ${input.app} not found`);
      }
      // update redux
      const updatedSections = currentFAQData.map((section) => {
        if (section.id === input.sectionId) {
          const updatedQuestions = section.questions.filter(
            (question) => question.id !== input.questionId
          );
          return { ...section, questions: updatedQuestions };
        }
        return section;
      });
      dispatch(updateFAQData({ app: input.app, updatedSections }));
      return data.deleteFAQQuestion;
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

const updateFAQData = createAction<{ app: OBApp; updatedSections: OBFAQSection[] }>(
  'FAQ/updateFAQData'
);
