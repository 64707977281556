import { Card } from '@mui/material';
import { Promotion } from '@type/promotion';
import {
  createPromoFunc,
  hasOfferVoucherSettings,
  updateEffectiveDateTime
} from 'components/_dashboard/promotion/tools';
import { CommonTable } from 'components/commonComponents/commonTable/commonTable';
import { ICommonTableHeadColumn } from 'components/commonComponents/commonTable/commonTableHead/commonTableHead';
import { ISearchBox } from 'components/commonComponents/commonTable/commonTableToolbar/commonTableToolbar';
import Scrollbar from 'components/Scrollbar';
import { useSnackbarHelper } from 'components/useSnackbarHelper';
import { cloneDeep } from 'lodash';
import { PromotionHistoryTableRow } from './promotionHistoryTableRow';

interface IPromotionTableHistoryProps {
  isLoading: boolean;
  promotions: Promotion[];
  tableTitle?: string;
}

const PROMOTION_HISTORY_TABLE_HEAD: ICommonTableHeadColumn<Promotion>[] = [
  { property: 'name', label: 'Name', align: 'left' },
  { property: 'description', label: 'Description', align: 'left' },
  { property: 'createdAt', label: 'Created At', align: 'left' },
  { property: null, label: 'Type', align: 'left' },
  { property: null, label: 'Action', align: 'right' }
];

const SEARCH_BOX: ISearchBox<Promotion> = {
  list: ['name'],
  placeholder: 'Search by name'
};

export const PromotionHistoryTable = (props: IPromotionTableHistoryProps) => {
  const { tableTitle, promotions, isLoading } = props;
  const { showSnackbar } = useSnackbarHelper();

  const duplicatePromotion = async (promotion: Promotion) => {
    if (!promotion) {
      showSnackbar('Promotion is null and duplicate fail', 'error');
      return;
    }
    try {
      const effectiveDateTime = updateEffectiveDateTime(promotion.effectiveDateTime);
      const newPromo = cloneDeep(promotion);
      if (newPromo) {
        newPromo.effectiveDateTime = effectiveDateTime;
        // if newPromo contains offerVoucherSettings, update effectiveDateTime
        if (hasOfferVoucherSettings(newPromo)) {
          newPromo.offerVoucherSettings.effectiveDateTime = effectiveDateTime;
        }
      }
      const shouldPublish = false;
      const isSuccess = await createPromoFunc(newPromo, promotion.type, shouldPublish);
      if (isSuccess) {
        showSnackbar('Duplicate Success', 'success');
        return;
      }
      showSnackbar('Duplicate Fail', 'error');
    } catch (error) {
      console.log('error', error);
      showSnackbar('Duplicate Fail', 'error');
    }
  };

  return (
    <>
      <Card>
        <Scrollbar>
          <CommonTable
            searchBox={SEARCH_BOX}
            columnList={PROMOTION_HISTORY_TABLE_HEAD}
            dataList={promotions}
            isLoading={isLoading}
            tableRowKey={'id'}
            RowBodyComponent={PromotionHistoryTableRow}
            rowBodyProps={{ duplicatePromotion }}
            tableTitle={tableTitle}
          />
        </Scrollbar>
      </Card>
    </>
  );
};
