import { gql } from '@apollo/client';

// queryFAQSections
// faq
// app: OBApp!
export const QUERY_FAQ_SECTIONS = gql`
  query faq($app: OBApp!) {
    faq(app: $app) {
      app
      sections {
        id
        title
        description
        sort
        questions {
          id
          question
          answer
          sort
          tags
          videoUrl
        }
      }
    }
  }
`;

// createFAQSection
// app: OBApp!
// section: OBFAQSectionInput!
export const CREATE_FAQ_SECTION = gql`
  mutation createFAQSection($app: OBApp!, $section: OBFAQSectionInput!) {
    createFAQSection(app: $app, section: $section)
  }
`;

// updateFAQSection
// app: OBApp!
// section: OBFAQSectionInput!
// sectionId: ID!
export const UPDATE_FAQ_SECTION = gql`
  mutation updateFAQSection($app: OBApp!, $section: OBFAQSectionInput!, $sectionId: ID!) {
    updateFAQSection(app: $app, section: $section, sectionId: $sectionId)
  }
`;

// deleteFAQSection
// app: OBApp!
// sectionId: ID!
export const DELETE_FAQ_SECTION = gql`
  mutation deleteFAQSection($app: OBApp!, $sectionId: ID!) {
    deleteFAQSection(app: $app, sectionId: $sectionId)
  }
`;

// createFAQQuestion
// app: OBApp!
// sectionId: ID!
// question: OBFAQQuestionInput!
export const CREATE_FAQ_QUESTION = gql`
  mutation createFAQQuestion($app: OBApp!, $sectionId: ID!, $question: OBFAQQuestionInput!) {
    createFAQQuestion(app: $app, sectionId: $sectionId, question: $question)
  }
`;

// updateFAQQuestion
// app: OBApp!
// sectionId: ID!
// question: OBFAQQuestionInput!
// questionId: ID!
export const UPDATE_FAQ_QUESTION = gql`
  mutation updateFAQQuestion(
    $app: OBApp!
    $sectionId: ID!
    $question: OBFAQQuestionInput!
    $questionId: ID!
  ) {
    updateFAQQuestion(
      app: $app
      sectionId: $sectionId
      question: $question
      questionId: $questionId
    )
  }
`;

// deleteFAQQuestion
// app: OBApp!
// sectionId: ID!
// questionId: ID!
export const DELETE_FAQ_QUESTION = gql`
  mutation deleteFAQQuestion($app: OBApp!, $sectionId: ID!, $questionId: ID!) {
    deleteFAQQuestion(app: $app, sectionId: $sectionId, questionId: $questionId)
  }
`;
