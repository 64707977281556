// ----------------------------------------------------------------------

import { Weekday } from '__generated__/globalTypes';
import { OpenTime } from './merchant';

export type BookingTableInput = {
  minimumNumberOfGuests: number | null;
  maximumNumberOfGuests: number | null;
  tableNumber: string;
};

export type BookingSessionsData = {
  id: string;
  name: string;
  description: string;
  guestCountLimit: number | null;
  OpenTime: OpenTime;
  hideEndTime: boolean;
  hideTableNumber: boolean;
  needDeposite: boolean;
  isEnabled: boolean;
  bookingTables?: BookingTableInput;
};

export interface weekdaysType {
  name: Weekday;
  status: boolean;
}

export let weekdays = [
  {
    name: Weekday.Monday,
    status: true
  },
  {
    name: Weekday.Tuesday,
    status: true
  },
  {
    name: Weekday.Wednesday,
    status: true
  },
  {
    name: Weekday.Thursday,
    status: true
  },
  {
    name: Weekday.Friday,
    status: true
  },
  {
    name: Weekday.Saturday,
    status: true
  },
  {
    name: Weekday.Sunday,
    status: true
  }
];

export type openHourType = {
  dateStart: any;
  dateEnd: any;
  openWeekdays: Array<any>;
};

export const TABLE_NUMBER = 'tableNumber';
export const MINIMUM_NUMBER_OF_GUEST = 'minimumNumberOfGuests';
export const MAXIMUM_NUMBER_OF_GUEST = 'maximumNumberOfGuests';
export const GUEST_COUNT_LIMIT = 'guestCountLimit';
