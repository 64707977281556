import { Container } from '@mui/material';

import { Box } from '@mui/system';
// components
// hooks
// routes

import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { restrictToParentElement, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { updateAppSectionVariables } from '__generated__/updateAppSection';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import LoadingComponent from 'components/LoadingComponent';
import Page from 'components/Page';
import { useSnackbarHelper } from 'components/useSnackbarHelper';
import useSettings from 'hooks/useSettings';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { fetchAPPSections, updateAppSectionMutation, updateSort } from 'redux/slices/appSection';
import { RootState, dispatch, useSelector } from 'redux/store';
import { PATH_DASHBOARD } from 'routes/paths';
import AppSectionItem from './components/AppSectionItem';
import { convertAppSectionInput } from './tools';
import AppSectionFormBtn from './components/AppSectionFormBtn';

export const ItemTypes = {
  BOX: 'box'
};

export default function AppSections() {
  const { showSnackbar } = useSnackbarHelper();
  const { themeStretch } = useSettings();
  const { t } = useTranslation();
  const { appSections } = useSelector((state: RootState) => state.appSection);
  const [isLoading, setIsLoading] = useState(true);
  const queryAppesctions = useCallback(async () => {
    try {
      setIsLoading(true);
      const resp = await dispatch(fetchAPPSections()).unwrap();
      if (resp) {
        showSnackbar('query app sections success', 'success');
        return;
      }
      showSnackbar('query app sections fail', 'error');
    } catch (error) {
      showSnackbar(error, 'error');
    } finally {
      setIsLoading(false);
    }
  }, [showSnackbar]);

  const updateAppSection = useCallback(
    async (input: updateAppSectionVariables) => {
      setIsLoading(true);
      try {
        const resp = await dispatch(updateAppSectionMutation(input)).unwrap();
        if (resp) {
          return true;
        } else {
          showSnackbar('update sections fail', 'error');
          return false;
        }
      } catch (error) {
        showSnackbar(error || 'An error occurred', 'error');
        return false;
      } finally {
        setIsLoading(false);
      }
    },
    [showSnackbar]
  );

  useEffect(() => {
    queryAppesctions();
  }, [queryAppesctions]);

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 0.1
    }
  });
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor, pointerSensor);

  const handleDragEnd = async (event: any) => {
    if (!event.active || !event.over) {
      return;
    }
    if (isLoading) return;
    const { active, over } = event;
    try {
      if (active.id !== over.id) {
        setIsLoading(true);
        const oldIndex = appSections.findIndex(({ id }: any) => id === active.id);
        const newIndex = appSections.findIndex(({ id }: any) => id === over.id);
        const oldSection = cloneDeep(appSections[oldIndex]);
        const newSection = cloneDeep(appSections[newIndex]);

        // swap the sort id
        oldSection.sort = newIndex;
        newSection.sort = oldIndex;
        // update with updateAppSection
        const updatePromises = [
          updateAppSection({
            sectionId: oldSection.id,
            section: convertAppSectionInput(oldSection)
          }),
          updateAppSection({
            sectionId: newSection.id,
            section: convertAppSectionInput(newSection)
          })
        ];
        const results = await Promise.all(updatePromises);
        // confirm the update
        results.forEach((isSuccess, index) => {
          if (isSuccess) {
            // Update the redux store based on the result
            if (index === 0) {
              dispatch(updateSort({ sectionIndex: oldIndex, sort: oldSection.sort }));
            } else if (index === 1) {
              dispatch(updateSort({ sectionIndex: newIndex, sort: newSection.sort }));
            }
          }
        });
        showSnackbar('update app section success', 'success');
      }
    } catch (error) {
      showSnackbar('update app section fail', 'error');
      showSnackbar(error, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Page title={t('App Sections')}>
      <DndProvider backend={HTML5Backend}>
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading={t('App Sections')}
            links={[
              { name: t('Dash Board'), href: PATH_DASHBOARD.root },
              { name: t('App Sections'), href: PATH_DASHBOARD.appSections.list },
              { name: t('List') }
            ]}
          />

          <AppSectionFormBtn />
          <Box
            display="flex"
            justifyContent="space-between"
            width="60%"
            style={{
              padding: '10px',
              border: '1px dashed black',
              borderRadius: '10px',
              marginTop: '20px'
            }}
          >
            <Box
              style={{
                width: '100%',
                minHeight: '600px',
                maxHeight: '600px',
                overflowY: 'scroll'
              }}
            >
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToParentElement, restrictToWindowEdges]}
              >
                <SortableContext
                  items={appSections?.map(({ id }: any) => id)}
                  strategy={rectSortingStrategy}
                >
                  {!isLoading ? (
                    appSections?.map((section: any, index: number) => (
                      <AppSectionItem key={section.id} section={section} index={index} />
                    ))
                  ) : (
                    <LoadingComponent />
                  )}
                </SortableContext>
              </DndContext>
            </Box>
          </Box>
        </Container>
      </DndProvider>
    </Page>
  );
}
