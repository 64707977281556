import { createSlice } from '@reduxjs/toolkit';
// utils
// import axios from '../../utils/axios';
import { MenuData } from '../../@type/menu';

// ----------------------------------------------------------------------

type MenuState = {
  isLoading: boolean;
  error: boolean;
  menuList: MenuData[];
};

const initialState: MenuState = {
  isLoading: false,
  error: false,
  menuList: []
};

const slice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state: any) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state: any, action: any) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MERCHANT
    getMenusSuccess(state: any, action: any) {
      state.isLoading = false;
      state.menuList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export const { getMenusSuccess } = slice.actions;
// Actions

// ----------------------------------------------------------------------
