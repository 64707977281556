import { Box, Paper, Typography } from '@mui/material';
import React from 'react';

interface IProps {
  popular: any;
  index: number;
}

const PopularRestaurantcategoriesItem = ({ popular, index }: IProps) => {
  return (
    <Paper
      variant="outlined"
      color="primary"
      sx={{ mt: index === 0 ? '0px' : '24px', position: 'relative', padding: '16px' }}
    >
      <Box
        sx={{
          cursor: 'move',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <Typography>{popular}</Typography>
      </Box>
    </Paper>
  );
};

export default PopularRestaurantcategoriesItem;
