import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  close?: () => void | Promise<void>;
  action?: () => void | Promise<void>;
  message?: String;
  title?: String;
  children?: React.ReactNode;
  hiddenButton?: boolean;
  sx?: CSSProperties;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

export default function ConfirmDialog({
  open,
  close,
  action,
  message,
  title,
  children,
  hiddenButton,
  fullWidth = false,
  maxWidth = 'sm',
  sx = {}
}: IProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const handleClose = async () => {
    if (close) await Promise.resolve(close());
  };

  const handleAction = async () => {
    setIsLoading(true);
    try {
      if (action) await Promise.resolve(action());
      if (close) await Promise.resolve(close());
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          style: { pointerEvents: 'none' }
        }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        PaperProps={{ style: sx }}
      >
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          {children && children}
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        {!hiddenButton && (
          <DialogActions>
            <Button onClick={handleClose}>{t('Cancel')}</Button>
            <LoadingButton loading={isLoading} onClick={handleAction} autoFocus>
              {t('Ok')}
            </LoadingButton>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}
