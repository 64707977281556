import {
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import Page from '../../../../components/Page';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import useSettings from '../../../../hooks/useSettings';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

export default function CookingCategory() {
  const { themeStretch } = useSettings();
  const { t } = useTranslation();
  return (
    <Page title={t('Menu Edit')}>
      <DndProvider backend={HTML5Backend}>
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading={t('Cooking Category')}
            links={[
              { name: t('Dash Board'), href: PATH_DASHBOARD.root },
              { name: t('Menu'), href: PATH_DASHBOARD.menu.list },
              { name: t('Cooking Category') }
            ]}
          />
          <Card sx={{ p: 3, marginTop: 3, minHeight: '500px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <TextField fullWidth label={t('Cooking Category Name')} />
              </Grid>
              <Grid item xs={6} md={3}>
                <FormControl fullWidth>
                  <InputLabel>{t('Select...')}</InputLabel>
                  <Select label={t('Select...')}>
                    <MenuItem>{t('Counter')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField fullWidth label="Print Copies" type="number" />
              </Grid>
              <Grid item>
                <Button
                  sx={{ lineHeight: '40px', minWidth: '100px', fontWeight: '16px' }}
                  variant="outlined"
                >
                  {t('Save')}
                </Button>
              </Grid>
            </Grid>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Name')}</TableCell>
                  <TableCell>{t('Printer')}</TableCell>
                  <TableCell>{t('Print Copies')}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover>
                  <TableCell>112312</TableCell>
                  <TableCell>Counter</TableCell>
                  <TableCell>1</TableCell>
                  <TableCell align="center">
                    <IconButton>
                      <DeleteIcon color="primary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        </Container>
      </DndProvider>
    </Page>
  );
}
