export interface GiftCardTermsAndConditionsProps {
  title: string;
  bodys: string[];
}

export const GiftCardTermsTitle =
  'Orderbuddy Balance and Orderbuddy Gift Card Terms and Conditions';
export const GiftCardTermsSubTitle = `The following terms and conditions apply to your Orderbuddy Balance and Orderbuddy Gift Cards. By purchasing, accepting, or using a Gift Card, you agree to be bound by these terms and conditions on behalf of yourself and all members of your household and others who purchase, accept, or use a Gift Card under your account.`;
export const GiftCardTermsUpdateStr = 'Last Updated: June 18, 2024';

export const GiftCardTermsAndConditions: GiftCardTermsAndConditionsProps[] = [
  {
    title: 'Gift Cards; Orderbuddy App Balance; Redemption.',
    bodys: [
      'Orderbuddy App Gift Cards ("Gift Cards") are issued by DNAPROPERTYSERVICE PTY LTD trading as ORDERBUDDY, an Australian Proprietary Limited Company. Once you redeem your claim code, the amount of funds available from your Gift Card ("Orderbuddy App Balance") will be displayed in your Account. Your Orderbuddy App Balance displays the total remaining balance of all Gift Cards that have been applied to your Orderbuddy App account but not yet applied to a purchase. We may refer to your Orderbuddy App Balance as your "Orderbuddy Balance," "Gift Card Balance," or "GC Balance." To view your Orderbuddy App Balance, visit your Account in the Orderbuddy App. Purchases are deducted from your Orderbuddy App Balance. Any unused Orderbuddy App Balance will remain associated with your Orderbuddy App account. If a purchase exceeds your Orderbuddy App Balance, the remaining amount must be paid with another payment method.',
      'Your Orderbuddy App Balance and your Gift Cards may only be redeemed toward the purchase of eligible goods and services on the Orderbuddy App and its associated applications. DNAPROPERTYSERVICE PTY LTD trading as ORDERBUDDY or its affiliates ("we," "us," and "our") may provide Gift Card purchasers with information about the redemption status of Gift Cards that they purchase.'
    ]
  },
  {
    title: 'Limitations.',
    bodys: [
      'Your Orderbuddy App Balance cannot be used to purchase other Gift Cards. Gift Cards cannot be reloaded, resold, transferred for value, or redeemed for cash, except to the extent required by law. No portion of your Orderbuddy App Balance may be transferred to another Orderbuddy App account.'
    ]
  },
  {
    title: 'Risk of Loss.',
    bodys: [
      'The risk of loss and title for Gift Cards pass to the purchaser upon our electronic transmission of the Gift Card to the purchaser or designated recipient, or our delivery to the carrier, whichever is applicable. We are not responsible if any Gift Card is lost, stolen, or destroyed, or if your Orderbuddy App Balance or any Gift Card is used without your permission.'
    ]
  },
  {
    title:
      'Use of Orderbuddy App Balance or any Gift Card in Violation of these Terms and Conditions.',
    bodys: [
      'We reserve the right to void Gift Cards (including as a component of your Orderbuddy App Balance), close customer accounts, and bill alternative forms of payment if we reasonably suspect that a Gift Card is obtained, used, or applied to an Orderbuddy App account (or your Orderbuddy App Balance is applied to a purchase) fraudulently, unlawfully, or otherwise in violation of these terms and conditions.'
    ]
  },
  {
    title: 'Limitation of Liability.',
    bodys: [
      'YOU HAVE CERTAIN RIGHTS UNDER THE AUSTRALIAN CONSUMER LAW WHICH CANNOT BE EXCLUDED AND ARE NOT AFFECTED BY THESE TERMS AND CONDITIONS. TO THE EXTENT PERMITTED BY LAW, WE MAKE NO WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO GIFT CARDS OR YOUR ORDERBUDDY APP BALANCE, INCLUDING WITHOUT LIMITATION, ANY EXPRESS OR IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. TO THE EXTENT PERMITTED BY LAW, IN THE EVENT A GIFT CARD IS NON-FUNCTIONAL, YOUR SOLE REMEDY, AND OUR SOLE LIABILITY, WILL BE THE REPLACEMENT OF THAT GIFT CARD.'
    ]
  },
  {
    title: 'Governing Law.',
    bodys: [
      `The laws of Victoria govern these terms and conditions and any dispute of any sort that might arise between you and DNAPROPERTYSERVICE PTY LTD trading as ORDERBUDDY and its affiliates. Any dispute relating in any way to these terms and conditions or your use of a Gift Card or your Orderbuddy App Balance will only be adjudicated in the courts of Victoria. We and you consent to exclusive jurisdiction and venue in these courts. Notwithstanding the foregoing, either we or you may seek injunctive relief in any state, federal, or national court of competent jurisdiction for any actual or alleged infringement of such party's, its affiliates', or any third party's intellectual property or other proprietary rights. The United Nations Convention on Contracts for the International Sale of Goods, and any local laws implementing the Convention on Contracts for the International Sale of Goods, do not apply to these terms and conditions or your use of a Gift Card or your Orderbuddy App Balance.`
    ]
  },
  {
    title: 'General Terms.',
    bodys: [
      'The Orderbuddy App Conditions of Use apply. Fees may apply to Gift Cards. We reserve the right to change these terms and conditions from time to time at our discretion where it is reasonably necessary to protect the legitimate interests of our business and provided that the change is not materially detrimental to you. Changes to these terms and conditions will be notified by updating the terms and conditions as set out on this webpage. All terms and conditions are applicable to the extent permitted by law. If any of these terms and conditions are deemed invalid, void, or for any reason unenforceable, that unenforceable term will be deemed severable and will not affect the validity and enforceability of any remaining terms and conditions.'
    ]
  },
  {
    title: 'Orderbuddy App Balance Expiration.',
    bodys: [
      'Your Orderbuddy App Balance and Gift Cards expire three years from the date of purchase.'
    ]
  }
];
