import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppSection } from '@type/appSection';
import { createAppSectionVariables } from '__generated__/createAppSection';
import { deleteAppSectionVariables } from '__generated__/deleteAppSection';
import { ContentStatus } from '__generated__/globalTypes';
import { updateAppSectionVariables } from '__generated__/updateAppSection';
import {
  APP_SECTIONS,
  CREATE_APP_SECTION,
  DELETE_APP_SECTION,
  UPDATE_APP_SECTION
} from '_apis_/queries/appSections';
import { removeTypenameKey } from 'components/_dashboard/promotion/tools';
import { client } from 'index';
import { cloneDeep } from 'lodash';

// ----------------------------------------------------------------------
type APPSectionState = {
  appSections: AppSection[];
};

const initialState: APPSectionState = {
  appSections: []
};

const slice = createSlice({
  name: 'appSection',
  initialState,
  reducers: {
    // update sort property
    updateSort(state, action: PayloadAction<{ sectionIndex: number; sort: number }>) {
      const { sectionIndex, sort } = action.payload;
      // check if the section exists
      const section = state.appSections.find((_, index) => index === sectionIndex);
      if (!section) return;
      // make a copy
      const sectionCopy = { ...section };
      // update the sort property
      sectionCopy.sort = sort;
      // update the state
      state.appSections[sectionIndex] = sectionCopy;
      // sort the sections
      state.appSections = state.appSections.sort((a: AppSection, b: AppSection) => {
        const sortA = a.sort ?? 0;
        const sortB = b.sort ?? 0;
        return sortA - sortB;
      });
    },
    updateSatus(state, action: PayloadAction<{ sectionId: string; status: ContentStatus }>) {
      const { sectionId, status } = action.payload;
      // check if the section exists
      const sectionIndex = state.appSections.findIndex((section) => section.id === sectionId);
      if (sectionIndex === -1) return;
      // make a copy
      const sectionCopy = { ...state.appSections[sectionIndex] };
      // update the status property
      sectionCopy.status = status;
      // update the state
      state.appSections[sectionIndex] = sectionCopy;
    },
    deleteAppSection(state, action: PayloadAction<{ sectionId: string }>) {
      const { sectionId } = action.payload;
      const index = state.appSections.findIndex((section) => section.id === sectionId);
      if (index === -1) return;
      // remove the section and update the state
      state.appSections.splice(index, 1);
    },
    updateAppSection(state, action: PayloadAction<{ sectionId: string; section: AppSection }>) {
      const { sectionId, section } = action.payload;
      const index = state.appSections.findIndex((section) => section.id === sectionId);
      if (index === -1) return;
      // update the section and update the state
      state.appSections[index] = section;
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchAPPSections.fulfilled, (state, { payload }) => {
      state.appSections = payload.slice().sort((a: AppSection, b: AppSection) => {
        const sortA = a.sort ?? 0;
        const sortB = b.sort ?? 0;
        return sortA - sortB;
      });
    });
  }
});

// export const APP_SECTIONS = gql`
//   query appSections {
//     appSections {
export const fetchAPPSections = createAsyncThunk(
  'appSection/queryAPPSections',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query<{ appSections: AppSection[] }>({
        query: APP_SECTIONS,
        fetchPolicy: 'network-only'
      });
      if (response.errors) return rejectWithValue(JSON.stringify(response.errors));
      if (!response.data) rejectWithValue('No data returned');
      // response.data.appSections is read-only object
      const copy = cloneDeep(response.data.appSections);
      removeTypenameKey(copy);
      return copy;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// export const UPDATE_APP_SECTION = gql`
//   mutation updateAppSection($section: AppSectionInput!, $sectionId: ID!) {
//     updateAppSection(section: $section, sectionId: $sectionId)
//   }
// `;
export const updateAppSectionMutation = createAsyncThunk<
  boolean,
  updateAppSectionVariables,
  { rejectValue: string }
>('appSection/updateAppSectionMutation', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{ updateAppSection: boolean }>({
      mutation: UPDATE_APP_SECTION,
      variables: input
    });
    if (response.errors) {
      return rejectWithValue(JSON.stringify(response.errors));
    }
    if (response.data) {
      return response.data.updateAppSection;
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// export const CREATE_APP_SECTION = gql`
//   mutation createAppSection($section: AppSectionInput!) {
//     createAppSection(section: $section)
//   }
// `;
export const createAppSectionMutation = createAsyncThunk<
  boolean,
  createAppSectionVariables,
  { rejectValue: string }
>('appSection/createAppSectionMutation', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{ createAppSection: boolean }>({
      mutation: CREATE_APP_SECTION,
      variables: input
    });
    if (response.errors) {
      return rejectWithValue(JSON.stringify(response.errors));
    }
    if (response.data) {
      return response.data.createAppSection;
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// export const DELETE_APP_SECTION = gql`
//   mutation deleteAppSection($sectionId: ID!) {
//     deleteAppSection(sectionId: $sectionId)
//   }
// `;
export const deleteAppSectionMutation = createAsyncThunk<
  boolean,
  deleteAppSectionVariables,
  { rejectValue: string }
>('appSection/deleteAppSectionMutation', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{ deleteAppSection: boolean }>({
      mutation: DELETE_APP_SECTION,
      variables: input
    });
    if (response.errors) {
      return rejectWithValue(JSON.stringify(response.errors));
    }
    if (response.data) {
      return response.data.deleteAppSection;
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// export updateSort
export const { updateSort, updateSatus, updateAppSection, deleteAppSection } = slice.actions;

// Reducer
export default slice.reducer;
