import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';
import { useTranslation } from 'react-i18next';
import LoadingComponent from 'components/LoadingComponent';
import { Box } from '@mui/system';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important' as 'relative',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

// ----------------------------------------------------------------------

interface IProps {
  dataBooking: number;
  dataOrder: number;
  loading: boolean;
}

export default function AppCurrentDownload({ dataBooking, dataOrder, loading }: IProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const CHART_DATA = [dataOrder, dataBooking];
  const CHART_DATA_DEFAULT = [0, 0];

  const chartOptions = merge(BaseOptionChart(), {
    colors: [theme.palette.primary.light, theme.palette.primary.main],
    labels: [`${t('Order')}`, `${t('Booking')}`],
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName: string) => fNumber(seriesName),
        title: {
          formatter: (seriesName: string) => `${seriesName}`
        }
      }
    },
    plotOptions: {
      pie: {
        donut: {
          size: '90%',
          labels: {
            value: {
              formatter: (val: number | string) => fNumber(val)
            },
            total: {
              label: t('Total'),
              formatter: (w: { globals: { seriesTotals: number[] } }) => {
                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                return fNumber(sum);
              }
            }
          }
        }
      }
    }
  });

  return (
    <Card>
      <CardHeader title={t('Sales')} />
      <Box style={{ height: '392px' }}>
        {loading ? (
          <LoadingComponent />
        ) : (
          <ChartWrapperStyle dir="ltr">
            <ReactApexChart
              type="donut"
              series={
                (dataOrder || dataOrder === 0) && (dataBooking || dataBooking === 0)
                  ? CHART_DATA
                  : CHART_DATA_DEFAULT
              }
              options={chartOptions}
              height={280}
            />
          </ChartWrapperStyle>
        )}
      </Box>
    </Card>
  );
}
