import { gql } from '@apollo/client';

export const ORDERS = gql`
  query orders($dateString: String!) {
    orders(dateString: $dateString) {
      orderNumber
      merchant {
        merchantId
        tableId
      }
      createdAt
      orderFrom
      order {
        pickupTime
        total
        paymentType
        type
        selectedDate
        notes
        orderTime
        adjustments {
          adjustmentType
          amount
          voucherNo
          description
        }
        items {
          size {
            name
            price
            groups {
              id
              name
              menuId
              options {
                id
                name
                shortName
                price
              }
            }
          }
          subTotal
          quantity
          specialInstructions
          isTakeaway
          id
          options {
            id
            quantity
            price
            name
            shortName
            price
          }
          dish {
            id
            price
            description
            name
          }
        }
      }
      user {
        name
        email
        phoneNumber
        guestNum
        address
        postcode
        foodAllergies
        geolocation {
          longitude
          latitude
        }
      }
    }
  }
`;

export const ORDER = gql`
  query order($dateString: String!, $orderNumber: String!) {
    order(dateString: $dateString, orderNumber: $orderNumber) {
      orderNumber
      merchant {
        merchantId
        tableId
      }
      createdAt
      orderFrom
      order {
        pickupTime
        total
        paymentType
        type
        selectedDate
        notes
        orderTime
        adjustments {
          adjustmentType
          priceDecimal
          name
        }
        items {
          size {
            name
            price
            groups {
              id
              name
              menuId
              options {
                id
                name
                shortName
                price
              }
            }
          }
          subTotal
          quantity
          specialInstructions
          isTakeaway
          id
          options {
            id
            quantity
            price
            name
            shortName
            price
          }
          dish {
            id
            price
            description
            name
          }
        }
      }
      user {
        name
        email
        phoneNumber
        guestNum
        address
        postcode
        foodAllergies
        geolocation {
          longitude
          latitude
        }
      }
    }
  }
`;
