import { RestaurantType } from '__generated__/globalTypes';

export const restaurantTypes = [
  {
    name: 'Bakery',
    value: RestaurantType.Bakery
  },
  {
    name: 'Beverages',
    value: RestaurantType.Beverages
  },
  {
    name: 'Breakfast And Brunch',
    value: RestaurantType.BreakfastAndBrunch
  },
  {
    name: 'Bubble Tea',
    value: RestaurantType.BubbleTea
  },
  {
    name: 'Buffet',
    value: RestaurantType.Buffet
  },
  {
    name: 'Cafe',
    value: RestaurantType.Cafe
  },
  {
    name: 'Casual Dining',
    value: RestaurantType.CasualDining
  },
  {
    name: 'Coffee',
    value: RestaurantType.Coffee
  },
  {
    name: 'Family Style',
    value: RestaurantType.FamilyStyle
  },

  {
    name: 'Fast Food',
    value: RestaurantType.FastFood
  },
  {
    name: 'Fine Dining',
    value: RestaurantType.FineDining
  },
  {
    name: 'Fish Chips',
    value: RestaurantType.FishChips
  },
  {
    name: 'Halal',
    value: RestaurantType.Halal
  },
  {
    name: 'Ice Cream And Frozen Yoghurt',
    value: RestaurantType.IceCreamAndFrozenYoghurt
  },
  {
    name: 'Juice And Smoothies',
    value: RestaurantType.JuiceAndSmoothies
  },
  {
    name: 'Seafood',
    value: RestaurantType.Seafood
  },
  {
    name: 'Steakhouse',
    value: RestaurantType.Steakhouse
  },
  {
    name: 'Vegetarian',
    value: RestaurantType.Vegetarian
  }
];
