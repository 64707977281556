import { useState } from 'react';
import { Breadcrumbs, Link, Typography, Box, Button, Grid } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { createSteps, Step } from './steps';
import { LoadingButton } from '@mui/lab';
import { hexToRGBA } from 'utils/rgb';
import { Promotion } from '@type/promotion';
import { useSnackbarHelper } from 'components/useSnackbarHelper';

export interface canMoveProps {
  message: string;
  canMove: boolean;
}
interface props {
  isSubmitting: boolean;
  action: (isPublished: boolean) => void;
  promotion: Promotion;
}

export const usePromoStepper = (props: props) => {
  const { isSubmitting, action, promotion } = props;
  const { isOffer = false, isLoyalty = false } = promotion;
  const [steps] = useState<Step[]>(createSteps(isOffer, isLoyalty));
  const [currentStep, setCurrentStep] = useState(steps[0].label);
  const currentIndex = steps.findIndex((step) => step.label === currentStep);
  const maxLength = steps.length;
  // first step is always activated
  const [activatedSteps, setActivatedSteps] = useState(
    Array.from({ length: maxLength }, (_, index) => index === 0)
  );
  const { showSnackbar } = useSnackbarHelper();
  const btnBackgroundColor = isOffer ? '#DCC179' : '';
  const btnHoverBackgroundColor = isOffer ? '#DCC179' : '';
  const btnShadowColor = isOffer ? '#DCC179' : '';
  const btnTextColor = isOffer ? '#D0AC4B' : '';
  const activateStep = (index: number) => {
    // get copy
    const newActivatedSteps = [...activatedSteps];
    // activate the step
    newActivatedSteps[index] = true;
    setActivatedSteps(newActivatedSteps);
  };
  const [canMoveFunc, setCanMoveFunc] = useState<() => Promise<canMoveProps>>(async () => ({
    message: '',
    canMove: true
  }));

  const next = async () => {
    const { message, canMove } = await canMoveFunc();
    if (!canMove) {
      showSnackbar(message, 'error');
      return;
    }
    const currentIndex = steps.findIndex((step) => step.label === currentStep);
    if (currentIndex < maxLength - 1) {
      const nextStep = steps[currentIndex + 1].label;
      activateStep(currentIndex + 1);
      setCurrentStep(nextStep);
    }
  };

  const back = () => {
    const currentIndex = steps.findIndex((step) => step.label === currentStep);
    if (currentIndex > 0) {
      const prevStep = steps[currentIndex - 1].label;
      setCurrentStep(prevStep);
    }
  };

  const MoveToStepComponent = () => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
      {currentIndex > 0 && (
        <Button
          sx={{
            width: 100,
            borderColor: btnBackgroundColor,
            color: btnTextColor,
            '&:hover': {
              backgroundColor: hexToRGBA(btnBackgroundColor, 0.2),
              borderColor: btnBackgroundColor,
              color: btnTextColor
            }
          }}
          variant="outlined"
          onClick={back}
        >
          Back
        </Button>
      )}

      <div style={{ flexGrow: 1 }}></div>

      {currentIndex < steps.length - 1 && (
        <Button
          sx={{
            width: 100,
            backgroundColor: btnBackgroundColor,
            '&:hover': {
              backgroundColor: btnHoverBackgroundColor
            },
            boxShadow: `0px 4px 8px 0px ${btnShadowColor}`
          }}
          variant="contained"
          onClick={next}
        >
          Next
        </Button>
      )}
      {currentIndex === steps.length - 1 && (
        <>
          <LoadingButton
            sx={{
              width: 100,
              mx: 1,
              borderColor: btnBackgroundColor,
              color: btnTextColor,
              '&:hover': {
                backgroundColor: hexToRGBA(btnBackgroundColor, 0.2),
                borderColor: btnBackgroundColor,
                color: btnTextColor
              }
            }}
            variant="outlined"
            onClick={() => {
              action(false);
            }}
            loading={isSubmitting}
          >
            Draft
          </LoadingButton>
          <LoadingButton
            sx={{
              width: 100,
              mx: 1,
              backgroundColor: btnBackgroundColor,
              '&:hover': {
                backgroundColor: btnHoverBackgroundColor
              },
              boxShadow: `0px 4px 8px 0px ${btnShadowColor}`
            }}
            variant="contained"
            onClick={() => {
              action(true);
            }}
            loading={isSubmitting}
          >
            Publish
          </LoadingButton>
        </>
      )}
    </Box>
  );

  const BreadcrumbsComponent = () => (
    <Grid container justifyContent="center" sx={{ width: '100%', my: 2 }}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        {steps.map((step, index) => {
          // Calculate the visible steps based on the index and currentStep
          const isActivated = activatedSteps[index];
          return (
            <Grid item xs="auto" key={index} sx={{ textAlign: 'center' }}>
              {currentStep === step.label ? (
                <Typography variant="subtitle2" color="text.primary">
                  {step.label}
                </Typography>
              ) : (
                <Link
                  underline="hover"
                  color={isActivated ? 'text.primary' : 'inherit'}
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentStep(step.label); // Set current step based on link click
                    activateStep(index); // Mark the step as activated
                  }}
                >
                  <Typography variant="body2">{step.label}</Typography>
                </Link>
              )}
            </Grid>
          );
        })}
      </Breadcrumbs>
    </Grid>
  );
  return { currentStep, setCanMoveFunc, BreadcrumbsComponent, MoveToStepComponent };
};
