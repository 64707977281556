import { Grid } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { getOfferVoucherBaseInfoValidationList } from 'components/_dashboard/promotion/tools';
import { CommonInput } from 'components/_dashboard/promotion/formik/commonInput';
import { renderContent, tabContent } from '../tabContent';
import { IPromoProps } from '../../type';
export const OfferVoucherBaseInfo: React.FC<IPromoProps> = (props) => {
  const { formik, setCanMoveFunc, setFieldTouchedNameFunc } = props;

  const canMove = useCallback(async () => {
    let message = '';
    let canMove = true;
    try {
      const list = getOfferVoucherBaseInfoValidationList();
      for (const path of list) {
        await setFieldTouchedNameFunc(path);
      }
    } catch (e) {
      message = e.message;
      canMove = false;
    } finally {
      return {
        message: message,
        canMove: canMove
      };
    }
  }, [setFieldTouchedNameFunc]);

  const wrappedCanMove = useCallback(() => canMove(), [canMove]);
  useEffect(() => {
    setCanMoveFunc(() => wrappedCanMove);
  }, [setCanMoveFunc, wrappedCanMove]);

  const commonContents: tabContent[] = [
    {
      mdSize: 12,
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'offerVoucherSettings.offerName'}
          label="Voucher Name"
          placeholder="Please enter the name of the voucher"
        />
      )
    },
    {
      mdSize: 12,
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'offerVoucherSettings.offerDescription'}
          label="Voucher Description"
          placeholder="Please enter the description of the voucher"
          multiline={true}
        />
      )
    }
  ];

  return (
    <Grid sx={{ width: '100%' }} container spacing={1}>
      {renderContent(commonContents)}
    </Grid>
  );
};
