import React, { Suspense, lazy } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import FAQPreview from 'components/_dashboard/FAQ/preview/preview';
import AppSections from 'pages/dashboard/appSections/AppSections';
import BookingDetail from 'pages/dashboard/booking/BookingDetail';
import CookingCategory from 'pages/dashboard/menu/menuCategory/CookingCategory';
import MerchantList from 'pages/dashboard/merchants/MerchantList';
import MerchantSettings from 'pages/dashboard/merchants/merchantSettings/MerchantSettings';
import PaymentError from 'pages/dashboard/merchants/subscriptions/payment/PaymentError';
import PaymentSuccess from 'pages/dashboard/merchants/subscriptions/payment/PaymentSuccess';
import ProductList from 'pages/dashboard/merchants/subscriptions/ProductList';
import Subscriptions from 'pages/dashboard/merchants/subscriptions/Subscriptions';
import PopularRestaurantCategories from 'pages/dashboard/PopularRestaurantCategories/PopularRestaurantCategories';
import { Promotion } from 'pages/dashboard/promotions/Promotion';
import PromotionHistory from 'pages/dashboard/promotions/PromotionHistory';
import FAQ from 'pages/dashboard/public/FAQ/faq';
import GiftCardTerms from 'pages/dashboard/public/giftCard/terms';
import LoadingScreen from '../components/LoadingScreen';
// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },
    {
      path: '/public',
      element: <LogoOnlyLayout />,
      children: [
        {
          path: 'giftCard',
          children: [{ path: 'terms', element: <GiftCardTerms /> }]
        },
        {
          path: 'FAQ',
          children: [{ path: ':app', element: <FAQ /> }]
        }
      ]
    },
    // Dashboard Routes
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/generalApp" replace /> },
        { path: '', element: <GeneralApp /> },
        { path: 'generalApp', element: <GeneralApp /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: 'edit/:userId', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> }
          ]
        },
        {
          path: 'promotion',
          children: [
            { element: <Navigate to="/dashboard/promotion/list" replace /> },
            { path: 'list', element: <PromotionList /> },
            { path: 'new', element: <PromotionCreate /> },
            { path: 'history', element: <PromotionHistory /> },
            { path: 'offer', element: <Promotion /> },
            { path: 'offer/:name', element: <Promotion /> },
            { path: 'loyalty/:promoType', element: <Promotion isLoyalty={true} /> },
            { path: 'loyalty/:promoType/:name', element: <Promotion isLoyalty={true} /> },
            { path: ':promoType', element: <Promotion /> },
            { path: ':promoType/:name', element: <Promotion /> }
          ]
        },
        {
          path: 'loyalty',
          children: [
            { element: <Navigate to="/dashboard/loyalty/member" replace /> },
            { path: 'member', element: <LoyaltyMember /> }
          ]
        },
        {
          path: 'giftcard',
          children: [
            { element: <Navigate to="/dashboard/giftCard/balance" replace /> },
            { path: 'accounts', element: <GiftCardAccounts /> },
            { path: 'balance/', element: <GiftCardBalance /> },
            { path: 'history', element: <GiftCardHistory /> }
          ]
        },
        {
          path: 'FAQ',
          children: [
            { element: <Navigate to="/FAQ/sections" replace /> },
            { path: 'sections', element: <FAQSections /> },
            { path: 'preview', element: <FAQPreview /> }
          ]
        },
        {
          path: 'appSections',
          children: [
            { element: <Navigate to="/dashboard/appSections/list" replace /> },
            { path: 'list', element: <AppSections /> }
          ]
        },
        {
          path: 'popular-restaurant-categories',
          children: [
            { element: <Navigate to="/dashboard/popular-restaurant-categories/list" replace /> },
            { path: 'list', element: <PopularRestaurantCategories /> }
          ]
        },
        {
          path: 'merchant',
          children: [
            { element: <Navigate to="/dashboard/merchant/list" replace /> },
            { path: 'list', element: <MerchantList /> },
            { path: 'new', element: <MerchantCreate /> },
            { path: 'update-merchant', element: <MerchantUpdate /> },
            { path: 'surcharges', element: <MerchantUpdateSurchargeList /> },
            { path: 'update-opening-hours', element: <MerchantOpentime /> },
            { path: ':id/edit', element: <MerchantCreate /> },
            { path: ':id/settings', element: <MerchantSettings /> },
            { path: 'subscriptions', element: <Subscriptions /> },
            { path: 'products', element: <ProductList /> }
          ]
        },
        {
          path: 'menu',
          children: [
            { element: <Navigate to="/dashboard/menu/list" replace /> },
            { path: 'list', element: <MenuList /> },
            { path: 'new', element: <MenuCreate /> },
            { path: ':id/edit', element: <MenuCreate /> },
            { path: ':id/menuCategory', element: <MenuCategory /> },
            { path: 'menuGroup/:menuId', element: <MenuGroup /> },
            { path: 'createMenuGroup/:menuId', element: <CreateMenuGroup /> },
            { path: ':id/cookingCategory', element: <CookingCategory /> }
          ]
        },
        {
          path: 'order',
          children: [
            { element: <Navigate to="/dashboard/order/list" replace /> },
            { path: 'list', element: <OrderList /> },
            { path: ':orderNumber/detail', element: <OrderDetail /> }
          ]
        },
        {
          path: 'sessions-booking',
          children: [
            { element: <Navigate to="/dashboard/sessions-booking/list" replace /> },
            { path: 'list', element: <BookingSessionsList /> },
            { path: 'new', element: <BookingSessionsCreate /> },
            { path: ':id/edit', element: <BookingSessionsCreate /> }
          ]
        },
        {
          path: 'booking',
          children: [
            { element: <Navigate to="/dashboard/booking/list" replace /> },
            { path: 'list', element: <BookingList /> },
            { path: ':id/detail', element: <BookingDetail /> }
          ]
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace /> },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new-post', element: <BlogNewPost /> }
          ]
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace /> },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> }
          ]
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> }
      ]
    },
    {
      path: 'subscriptions',
      element: <LogoOnlyLayout />,
      children: [
        {
          path: ':id/payment',
          children: [
            { path: 'success', element: <PaymentSuccess /> },
            { path: 'error', element: <PaymentError /> }
          ]
        }
      ]
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <LandingPage />
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
// const Promotions = Loadable(lazy(() => import('../pages/dashboard/Promotions')));
const PromotionList = Loadable(lazy(() => import('../pages/dashboard/promotions/PromotionList')));
const PromotionCreate = Loadable(
  lazy(() => import('../pages/dashboard/promotions/PromotionCreate'))
);
const LoyaltyMember = Loadable(
  lazy(() => import('../pages/dashboard/loyalty/member/LoyaltMember'))
);
const GiftCardBalance = Loadable(lazy(() => import('../pages/dashboard/giftCard/balance')));
const GiftCardAccounts = Loadable(lazy(() => import('../pages/dashboard/giftCard/accounts')));
const GiftCardHistory = Loadable(lazy(() => import('../pages/dashboard/giftCard/history')));
// FAQ
const FAQSections = Loadable(lazy(() => import('../pages/dashboard/FAQ/sections')));
// const MerchantList = Loadable(lazy(() => import('../pages/dashboard/merchants/MerchantList')));
const MerchantCreate = Loadable(lazy(() => import('../pages/dashboard/merchants/MerchantCreate')));
const MerchantUpdate = Loadable(lazy(() => import('../pages/dashboard/merchants/MerchantUpdate')));
const MerchantUpdateSurchargeList = Loadable(
  lazy(() => import('../pages/dashboard/merchants/surcharges/MerchantUpdateSurchargeList'))
);
const MerchantOpentime = Loadable(
  lazy(() => import('../pages/dashboard/merchants/MerchantOpentime'))
);

const MenuList = Loadable(lazy(() => import('../pages/dashboard/menu/MenuList')));
const MenuCreate = Loadable(lazy(() => import('../pages/dashboard/menu/MenuCreate')));
const MenuGroup = Loadable(lazy(() => import('../pages/dashboard/menu/MenuGroup')));
const CreateMenuGroup = Loadable(lazy(() => import('../pages/dashboard/menu/CreateMenuGroup')));

const OrderList = Loadable(lazy(() => import('../pages/dashboard/order/OrderList')));
const OrderDetail = Loadable(lazy(() => import('../pages/dashboard/order/OrderDetail')));

const BookingList = Loadable(lazy(() => import('../pages/dashboard/booking/BookingList')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

const BookingSessionsList = Loadable(lazy(() => import('../pages/dashboard/BookingSessionsList')));
const BookingSessionsCreate = Loadable(
  lazy(() => import('../pages/dashboard/BookingSessionsCreate'))
);

const MenuCategory = Loadable(lazy(() => import('pages/dashboard/menu/menuCategory/MenuCategory')));
