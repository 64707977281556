import { useLazyQuery, useMutation } from '@apollo/client';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
// material
import {
  Box,
  Button,
  Card,
  Container,
  InputAdornment,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { getMerchantsSuccess } from 'redux/slices/merchant';
import { GET_ALL_MERCHANTS, SWITCH_MERCHANT } from '_apis_/queries/merchant';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { useNavigate } from 'react-router-dom';

// @types
// components
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import { MerchantListHead } from '../../../components/_dashboard/merchant/list';
// hooks
import { styled } from '@mui/material/styles';
import useSettings from '../../../hooks/useSettings';
// redux
import { RootState, useDispatch, useSelector } from '../../../redux/store';
// routes
import searchFill from '@iconify/icons-eva/search-fill';
import clear from '@iconify/icons-ic/clear';
import ConfirmDialog from 'components/commonComponents/ConfirmDialog';
import LoadingComponent from 'components/LoadingComponent';
import SearchNotFound from 'components/SearchNotFound';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { applySortFilter, getComparator } from 'utils/functions';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { MerchantStatus } from '__generated__/globalTypes';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'altName', label: 'Alt Name', alignRight: false },
  { id: 'merchantId', label: 'merchantId', alignRight: false },
  { id: 'posMerchantId', label: 'POS MerchantId', alignRight: false },
  { id: 'merchantStatus', label: 'Status', alignRight: false },
  { id: 'appServices', label: 'Services', alignRight: false }
];

// ----------------------------------------------------------------------
export const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 56,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

export default function MerchantList() {
  const authMerchantId = useSelector((state: RootState) => state.auth.user?.merchantId || null);
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const navigate = useNavigate();
  const [dataMerchant] = useLazyQuery(GET_ALL_MERCHANTS, {
    onCompleted(response) {
      onGetAllMerchantCompleted(response);
    },
    onError(error) {
      console.log('Login Failed: ', error);
      setIsLoading(false);
    },
    fetchPolicy: 'network-only'
  });
  const onGetAllMerchantCompleted = async (response: any) => {
    if (response.getAllMerchants && response.getAllMerchants.length > 0) {
      dispatch(getMerchantsSuccess(response.getAllMerchants));
    }
    setIsLoading(false);
  };
  const getData = () => {
    setIsLoading(true);
    dataMerchant();
  };
  const merchantList = useSelector((state: RootState) => state.merchant.merchantList);
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [filteredMerchantList, setFilteredMerchantList] = useState(merchantList);
  const { enqueueSnackbar } = useSnackbar();
  const [isSwitchMerchant, setIsSwitchMerchant] = useState(false);
  const [merchantId, setMerchantId] = useState('');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [switchMerchantMutation] = useMutation(SWITCH_MERCHANT, {
    onCompleted(response) {
      enqueueSnackbar(t('Switch Merchant Success'), { variant: 'success' });
    },
    onError(error) {
      enqueueSnackbar(t('Switch Merchant Fail'), { variant: 'error' });
    }
  });

  const handleMerchantNameOnClick = (merchantId: string) => {
    const path = `${PATH_DASHBOARD.merchant.root}/${merchantId}/edit`;
    // 使用navigate进行跳转
    navigate(path);
  };

  const handleMerchantIdOnClick = (merchantId: string) => {
    setMerchantId(merchantId);
    setIsSwitchMerchant(true);
  };

  const handleSwitchMerchant = async () => {
    const variables = {
      merchantId: merchantId
    };
    await switchMerchantMutation({ variables });
    await setIsSwitchMerchant(false);
    window.location.reload();
  };

  const canSwitchMerchant = (merchantId: string) => {
    if (authMerchantId === merchantId) {
      return false;
    }
    return true;
  };

  const getMerchantStatusColor = (status: MerchantStatus) => {
    switch (status) {
      case MerchantStatus.active:
        return 'success';
      case MerchantStatus.inactive:
        return 'error';
      default:
        return 'inherit';
    }
  };

  useEffect(() => {
    const sortedMerchantList = applySortFilter(merchantList, getComparator(order, orderBy), '');
    const searchedMerchant = sortedMerchantList.filter((merchant) => {
      return (
        merchant.merchantId?.toLowerCase().includes(searchValue.toLowerCase()) ||
        merchant.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
        merchant.address?.toLowerCase().includes(searchValue.toLowerCase()) ||
        merchant.phoneNumber?.toLowerCase().includes(searchValue.toLowerCase()) ||
        merchant.altName?.toLowerCase().includes(searchValue.toLowerCase()) ||
        merchant.abn?.toLowerCase().includes(searchValue.toLowerCase()) ||
        merchant.merchantStatus?.toLowerCase().includes(searchValue.toLowerCase()) ||
        (merchant.merchantAdminEmail &&
          merchant.merchantAdminEmail?.toLowerCase().includes(searchValue.toLowerCase())) ||
        merchant.type?.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
    setFilteredMerchantList(searchedMerchant);
  }, [searchValue, merchantList, order, orderBy]);

  const isMerchantNotFound = filteredMerchantList?.length === 0;
  const isNoItem = merchantList?.length === 0;

  return (
    <Page title={t('Merchant List')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading={t('Merchant List')}
          links={[
            { name: t('Dash Board'), href: PATH_DASHBOARD.root },
            { name: t('Merchant'), href: PATH_DASHBOARD.merchant.list },
            { name: t('List') }
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.merchant.newMerchant}
              startIcon={<Icon icon={plusFill} />}
            >
              {t('New Merchant')}
            </Button>
          }
        />
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '40ch' }
          }}
          noValidate
          autoComplete="off"
        >
          <SearchStyle
            placeholder={t('Search merchant...')}
            value={searchValue}
            onChange={(e: any) => {
              setSearchValue(e.target.value);
            }}
            startAdornment={
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <Box
                  component={Icon}
                  icon={clear}
                  sx={{ color: 'text.disabled', cursor: 'pointer' }}
                  onClick={() => setSearchValue('')}
                />
              </InputAdornment>
            }
          />
        </Box>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <MerchantListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={merchantList.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {isLoading ? (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={8}>
                        <LoadingComponent />
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredMerchantList
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          merchantId,
                          name,
                          orderbuddyMerchantId,
                          altName,
                          merchantStatus,
                          settings
                        } = row;
                        return (
                          <TableRow hover key={merchantId} tabIndex={-1} role="checkbox">
                            <TableCell
                              onClick={() => handleMerchantNameOnClick(merchantId)}
                              align="left"
                              style={{ cursor: 'pointer' }}
                            >
                              <Typography
                                component="span"
                                sx={{
                                  textDecoration: 'underline'
                                }}
                              >
                                {name}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">{altName}</TableCell>
                            <TableCell
                              onClick={() =>
                                canSwitchMerchant(merchantId) && handleMerchantIdOnClick(merchantId)
                              }
                              style={{
                                cursor: canSwitchMerchant(merchantId) ? 'pointer' : 'default'
                              }}
                              align="left"
                            >
                              <Typography
                                component="span"
                                sx={{
                                  textDecoration: canSwitchMerchant(merchantId)
                                    ? 'underline'
                                    : 'none'
                                }}
                              >
                                {merchantId}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">{orderbuddyMerchantId}</TableCell>
                            <TableCell align="left">
                              <Button
                                size="small"
                                variant="outlined"
                                color={getMerchantStatusColor(merchantStatus)}
                              >
                                {merchantStatus ? merchantStatus : 'unknown'}
                              </Button>
                            </TableCell>
                            <TableCell align="left" style={{ display: 'flex' }}>
                              {settings?.appServices ? (
                                settings.appServices.length > 0 ? (
                                  settings.appServices.map((merchantStatus, index) => (
                                    <Button
                                      key={index}
                                      size="small"
                                      variant="outlined"
                                      color="success"
                                      style={{ margin: '2px' }}
                                    >
                                      {merchantStatus || 'unknown'}
                                    </Button>
                                  ))
                                ) : (
                                  <span>No Services</span>
                                )
                              ) : (
                                <span></span>
                              )}
                            </TableCell>

                            {/* <TableCell align="center">
                              <MerchantMoreMenu
                                onDelete={() => handleDeleteMerchant(merchantId)}
                                setIsSwitchMerchant={setIsSwitchMerchant}
                                setMerchantId={setMerchantId}
                                merchantId={merchantId}
                              />
                            </TableCell> */}
                          </TableRow>
                        );
                      })
                  )}
                </TableBody>
                {isMerchantNotFound && !isLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={searchValue} isNoItem={isNoItem} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[50, 100]}
            component="div"
            count={filteredMerchantList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <ConfirmDialog
        title={t('Switch')}
        message={t('Are you sure to switch merchant')}
        open={isSwitchMerchant}
        close={() => setIsSwitchMerchant(false)}
        action={handleSwitchMerchant}
      />
    </Page>
  );
}
