import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@type/authentication';

// Apollo
import { useMutation } from '@apollo/client';
import { LOGIN, LOGOUT } from '_apis_/queries/auth';
import { CREATE_USER } from '_apis_/queries/user';
// ----------------------------------------------------------------------

export enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  CreateUser = 'CREATE_USER'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
  [Types.CreateUser]: {
    userManage: any;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'CREATE_USER':
      return {
        ...state
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const user = localStorage.getItem('user') as AuthUser;
        if (user) {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error({ err });
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = (email: string, password: string) => {
    const variables = {
      email,
      password
    };
    loginMutation({ variables });
  };

  const [loginMutation] = useMutation(LOGIN, {
    onCompleted(response) {
      onLoginCompleted(response);
    },
    onError(error) {
      console.log('Login Failed: ', error);
    }
  });

  const onLoginCompleted = (response: any) => {
    console.log(response);
    const data = response?.login;
    localStorage.setItem('user', data);
    dispatch({
      type: Types.Login,
      payload: {
        user: data
      }
    });
  };

  // Create user ======================================
  const createUser = (
    email: string,
    password: string,
    mechantId: string,
    role: string,
    name: string
  ) => {
    const variables = {
      email,
      password,
      mechantId,
      role,
      name
    };
    createUserMutation({ variables });
  };
  const [createUserMutation] = useMutation(CREATE_USER, {
    onCompleted(response) {
      onCreateUserCompleted(response);
    },
    onError(error) {
      console.log('Login Failed: ', error);
    }
  });
  const onCreateUserCompleted = (response: any) => {
    console.log('data', response); //handle message when success
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: Types.Register,
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    localStorage.removeItem('user');
    // document.cookie = 'session= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
    logoutMutation();
  };

  const [logoutMutation] = useMutation(LOGOUT, {
    onCompleted(response) {
      onLogoutCompleted(response);
    },
    onError(error) {
      console.log('Logout Failed: ', error);
    }
  });

  const onLogoutCompleted = (response: any) => {
    dispatch({
      type: Types.Logout
    });
  };
  const resetPassword = (email: string) => console.log(email);

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        createUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
