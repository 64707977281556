// material
import { visuallyHidden } from '@mui/utils';
import { Box, TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

type MerchantListHeadProps = {
  order: 'asc' | 'desc';
  orderBy: string;
  rowCount: number;
  headLabel: any[];
  onRequestSort: (id: string) => void;
};

export default function MerchantListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  onRequestSort
}: MerchantListHeadProps) {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell, index: number) => (
          <TableCell
            key={headCell.id}
            style={{
              width: index !== headLabel.length - 1 ? `${90 / (headLabel.length - 1)}%` : '10%'
            }}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() => {
                if (index !== headLabel.length - 1) {
                  onRequestSort(headCell.id);
                }
              }}
            >
              {t(`${headCell.label}`)}
              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
