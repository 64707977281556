// import { useNavigate } from 'react-router-dom';
import { Box, Card, styled, Typography } from '@mui/material';
import { Booking } from '@type/booking';
import LoadingComponent from 'components/LoadingComponent';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fDateTimeSecond } from 'utils/formatTime';

// ----------------------------------------------------------------------

type OrderDetailFormProps = {
  currentBooking: Booking;
};

const BoxWrrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  margin: '20px'
});

const BoxRow = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 2
});

const BoxItem = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  margin: '10px'
});

export default function BookingDetailForm({ currentBooking }: OrderDetailFormProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (currentBooking) {
      setIsLoading(false);
    }
  }, [currentBooking]);
  return (
    <Card sx={{ minHeight: '50vh' }}>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <Box sx={{ width: '50%' }}>
          <BoxWrrapper>
            <BoxRow>
              <BoxItem>
                <Typography sx={{ flex: 4 }}>{`${t('No')} :`}</Typography>
                <Typography sx={{ flex: 6 }}>{currentBooking?.id || '---'}</Typography>
              </BoxItem>
              <BoxItem>
                <Typography sx={{ flex: 4 }}>{`${t('Name')} :`}</Typography>
                <Typography sx={{ flex: 6 }}>{currentBooking?.customer?.name || '---'}</Typography>
              </BoxItem>
              <BoxItem>
                <Typography sx={{ flex: 4 }}>{`${t('Phone Number')} :`}</Typography>
                <Typography sx={{ flex: 6 }}>
                  {currentBooking?.customer?.phoneNumber || '---'}
                </Typography>
              </BoxItem>
              <BoxItem>
                <Typography sx={{ flex: 4 }}>{`${t('Guest Number')} :`}</Typography>
                <Typography sx={{ flex: 6 }}>
                  {currentBooking?.customer?.guestNum || '---'}
                </Typography>
              </BoxItem>
              <BoxItem>
                <Typography sx={{ flex: 4 }}>{`${t('Post Code')} :`}</Typography>
                <Typography sx={{ flex: 6 }}>
                  {currentBooking?.customer?.postcode || '---'}
                </Typography>
              </BoxItem>
              <BoxItem>
                <Typography sx={{ flex: 4 }}>{`${t('Booking Time')} :`}</Typography>
                <Typography sx={{ flex: 6 }}>
                  {(currentBooking?.bookingDetails?.bookingTime &&
                    fDateTimeSecond(currentBooking.bookingDetails?.bookingTime)) ||
                    '---'}
                </Typography>
              </BoxItem>
              <BoxItem>
                <Typography sx={{ flex: 4 }}>{`${t('Special Instructions')} :`}</Typography>
                <Typography sx={{ flex: 6 }}>
                  {currentBooking?.bookingDetails?.specialInstructions || '---'}
                </Typography>
              </BoxItem>
            </BoxRow>
          </BoxWrrapper>
        </Box>
      )}
    </Card>
  );
}
