import { Weekday } from '__generated__/globalTypes';
import { ReactNode, createContext, useContext } from 'react';

export type EffectiveDateTimeType = {
  startDate: string;
  endDate: string;
  recurringWeekDays: Weekday[];
  hours: HoursType[];
};
export type HoursType = {
  openStart: any;
  openEnd: any;
};

export const weekdayList: Weekday[] = [
  Weekday.Monday,
  Weekday.Tuesday,
  Weekday.Wednesday,
  Weekday.Thursday,
  Weekday.Friday,
  Weekday.Saturday,
  Weekday.Sunday
];

interface EffectDateTimeListContextType {
  dateTimeList: EffectiveDateTimeType[];
  setDateTimeList: (dateTimeList: EffectiveDateTimeType[]) => void;
  isOffer?: boolean;
  updateWeekday: (index: number, newWeekdays: Weekday[]) => void;
  updateStartDate: (index: number, newStartDate: string) => void;
  updateEndDate: (index: number, newEndDate: string) => void;
  updateHours: (index: number, newHours: HoursType[]) => void;
}

interface DateTimeListProviderProps {
  children: ReactNode;
  initialDateTimeList: EffectiveDateTimeType[];
  externalSetDateTimeList: (dateTimeList: EffectiveDateTimeType[]) => void;
  isOffer?: boolean;
}

const DateTimeListContext = createContext<EffectDateTimeListContextType | undefined>(undefined);

export const DateTimeListProvider: React.FC<DateTimeListProviderProps> = ({
  children,
  initialDateTimeList,
  externalSetDateTimeList,
  isOffer = false
}) => {
  const updateWeekday = (index: number, newWeekdays: Weekday[]) => {
    // get an copy
    const newDateTimeList = [...initialDateTimeList];
    // update the value
    newDateTimeList[index].recurringWeekDays = newWeekdays;
    // set the new value
    externalSetDateTimeList(newDateTimeList);
  };
  const updateStartDate = (index: number, newStartDate: string) => {
    // get an copy
    const newDateTimeList = [...initialDateTimeList];
    // update the value
    newDateTimeList[index].startDate = newStartDate;
    // set the new value
    externalSetDateTimeList(newDateTimeList);
  };
  const updateEndDate = (index: number, newEndDate: string) => {
    // get an copy
    const newDateTimeList = [...initialDateTimeList];
    // update the value
    newDateTimeList[index].endDate = newEndDate;
    // set the new value
    externalSetDateTimeList(newDateTimeList);
  };

  const updateHours = (index: number, newHours: HoursType[]) => {
    // get an copy
    const newDateTimeList = [...initialDateTimeList];
    // update the value
    newDateTimeList[index].hours = newHours;
    // set the new value
    externalSetDateTimeList(newDateTimeList);
  };

  return (
    <DateTimeListContext.Provider
      value={{
        dateTimeList: initialDateTimeList,
        setDateTimeList: externalSetDateTimeList,
        isOffer,
        updateWeekday,
        updateStartDate,
        updateEndDate,
        updateHours
      }}
    >
      {children}
    </DateTimeListContext.Provider>
  );
};

export const useEffectDateTimeList = (): EffectDateTimeListContextType => {
  const context = useContext(DateTimeListContext);
  if (!context) {
    throw new Error('useDateTimeList must be used within a DateTimeListProvider');
  }
  return context;
};
