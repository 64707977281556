import mock from './mock';
import mockData from '../utils/mock-data';

// ----------------------------------------------------------------------

mock.onGet('/api/menu/all').reply(() => {
  const menus = [...Array(24)].map((_, index) => ({
    id: mockData.id(index),
    name: 'string',
    quantity: 10000,
    option: 'string',
    price: '$ 100.0',
    orderNumber: 'string',
    createTime: 'string',
    status: 'string',
    total: 'string',
    guest: 'string',
    orderType: 'string'
  }));

  return [200, { menus }];
});
