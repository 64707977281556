import { gql } from '@apollo/client';

export const APP_SECTIONS = gql`
  query appSections {
    appSections {
      id
      contentType
      location
      viewType
      sort
      merchants {
        merchantId
        name
        altName
        address
        suburb
        city
        phoneNumber
        abn
        latitude
        longitude
        openTime {
          closeDates
          openHours {
            openStart
            openEnd
            weekDays
          }
        }
        logo
        logoImages
        merchantAdminEmail
        createdAt
        restaurantType
        restaurantCategory
        type
        tags
        onlineStatus
        onlineOrderCount
        orderbuddyMerchantId
        bookingMerchantId
      }
      items {
        id
        title
        description
        image
      }
      title
      description
      image
      status
      radiumInKm
      coordinate {
        latitude
        longitude
      }
    }
  }
`;

export const CREATE_APP_SECTION = gql`
  mutation createAppSection($section: AppSectionInput!) {
    createAppSection(section: $section)
  }
`;

export const UPDATE_APP_SECTION = gql`
  mutation updateAppSection($section: AppSectionInput!, $sectionId: ID!) {
    updateAppSection(section: $section, sectionId: $sectionId)
  }
`;

export const PUBLISH_APP_SECTION = gql`
  mutation publishAppSection($sectionId: ID!) {
    publishAppSection(sectionId: $sectionId)
  }
`;

export const DELETE_APP_SECTION = gql`
  mutation deleteAppSection($sectionId: ID!) {
    deleteAppSection(sectionId: $sectionId)
  }
`;
