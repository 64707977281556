export const FLOAT = 'FLOAT';
export const FLOATING_POINT = 'FLOATING_POINT';
export const INTEGER = 'INTEGER';
export const NAME_TABLE_TAB_START = 'NAME_TABLE_TAB_START';
export const NAME_TABLE_TAB_SPECIAL = 'NAME_TABLE_TAB_SPECIAL';
export const REGEX_INTERGER = 'REGEX_INTERGER';
export const INVALID_DATE = 'Invalid date';
export const EMAIL = 'EMAIL';

export const STRIPE_ACCOUNT_ID = 'stripe_account_id';
export const MAX_PROMOTIONS = 'max_promotions';
export const DEFAULT_TIME_ZONE = 'Australia/Sydney';

export type Only<Key, U> = Key extends U ? Key : never;
