import { Checkbox, FormControl, FormControlLabel, Stack } from '@mui/material';
import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { Label } from '../styles';
import { useTranslation } from 'react-i18next';
import { OrderType } from '__generated__/globalTypes';

interface deliveredTypeSelectorProp {
  formik: FormikProps<Promotion>;
}

export const DeliveredTypeSelector: React.FC<deliveredTypeSelectorProp> = (props) => {
  const { t } = useTranslation();
  const { formik } = props;
  const { availableType } = formik.values;
  const isOffer = formik.values.isOffer || false;

  const checkBoxStyle = {
    color: isOffer ? '#DCC179' : 'default', // 默认状态下的颜色
    '&.Mui-checked': {
      color: isOffer ? '#DCC179' : 'primary' // 勾选后的颜色
    }
  };

  const isAvailableTypeChecked = (type: OrderType) => {
    if (availableType === undefined) return false;
    return availableType.includes(type);
  };
  const handleAvailableTypeCheckBoxOnChange = (type: OrderType) => {
    let newAvailableType = availableType || [];
    if (isAvailableTypeChecked(type)) {
      newAvailableType = newAvailableType.filter((item) => item !== type);
    } else {
      newAvailableType = [...newAvailableType, type];
    }
    formik.setFieldValue('availableType', newAvailableType);
  };

  return (
    <>
      <FormControl>
        <Label>{t('Order Type')}</Label>
        <Stack direction="row" spacing={1}>
          {Object.entries(OrderType).map(([key, value]) => (
            <FormControlLabel
              key={key}
              value={key}
              checked={isAvailableTypeChecked(key as OrderType)}
              onChange={() => handleAvailableTypeCheckBoxOnChange(key as OrderType)}
              control={<Checkbox sx={checkBoxStyle} />}
              label={value}
              labelPlacement="end"
            />
          ))}
        </Stack>
      </FormControl>
    </>
  );
};
