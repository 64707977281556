import { createSlice } from '@reduxjs/toolkit';
// utils

// ----------------------------------------------------------------------

type PromotionsManagementState = {
  featuredList: any[];
  newMerchantList: any[];
  recommendationList: any[];
};

const initialState: PromotionsManagementState = {
  featuredList: [],
  newMerchantList: [],
  recommendationList: []
};

const slice = createSlice({
  name: 'promotions-management',
  initialState,
  reducers: {
    setFeaturedList(state: any, action: any) {
      state.featuredList = action.payload;
    },

    setNewMerchantList(state: any, action: any) {
      state.newMerchantList = action.payload;
    },

    setRecommentdationList(state: any, action: any) {
      state.recommendationList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;
export const { setFeaturedList, setNewMerchantList, setRecommentdationList } = slice.actions;
