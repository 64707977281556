import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { DeepKeyOf } from 'components/_dashboard/promotion/formik/type';
import { useState } from 'react';
import { v4 } from 'uuid';

export enum SortType {
  ASC = 'asc',
  DESC = 'desc'
}

export interface ICommonTableHeadColumn<T> {
  property: DeepKeyOf<T> | null;
  label?: string;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
}

interface ICommonTableHeadProps<T> {
  columnList: ICommonTableHeadColumn<T>[];
  handleColumnTitleOnClick: (property: DeepKeyOf<T>, sortType: SortType) => void;
  backgroundColor?: string;
}

export const ICommonTableHead = <T,>(props: ICommonTableHeadProps<T>) => {
  const { columnList, handleColumnTitleOnClick, backgroundColor } = props;
  const [sortType, setSortType] = useState<SortType>(SortType.DESC);
  const [orderBy, setOrderBy] = useState<DeepKeyOf<T> | null>(null);
  const noColumn = columnList.filter((item: any) => {
    return item.property === null;
  })?.length;

  const handleColumnOnClick = (column: ICommonTableHeadColumn<T>) => {
    const newSortType = sortType === SortType.ASC ? SortType.DESC : SortType.ASC;
    setSortType(newSortType);
    setOrderBy(column.property);
    if (column.property) {
      handleColumnTitleOnClick(column.property, newSortType);
    }
  };

  const getColumnWidth = (column: ICommonTableHeadColumn<T>) => {
    if (column.property === null) {
      return '10%';
    }
    return `${(100 - noColumn * 10) / (columnList.length - noColumn)}%`;
  };

  return (
    <TableHead>
      <TableRow>
        {columnList.map((column) => (
          <TableCell
            key={v4()}
            style={{
              // background: '#27A647',
              background: backgroundColor || '#27A647',
              color: 'white',
              width: getColumnWidth(column)
            }}
            align={column.align || 'left'}
            sortDirection={orderBy === column.property ? sortType : false}
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === column.property}
              direction={orderBy === column.property ? sortType : SortType.ASC}
              onClick={() => {
                handleColumnOnClick(column);
              }}
            >
              {column.label}
              {/* {orderBy === column.property ? (
                                <Box sx={{ ...visuallyHidden }}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
