import { merge } from 'lodash';
import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box, TextField } from '@mui/material';
//
import { BaseOptionChart } from '../../charts';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const CHART_DATA = () => {
  const { t } = useTranslation();
  return [
    {
      year: 2019,
      data: [
        { name: t('Asia'), data: [10, 41, 35, 51, 49, 62, 69, 91, 148] },
        { name: t('America'), data: [10, 34, 13, 56, 77, 88, 99, 77, 45] }
      ]
    },
    {
      year: 2020,
      data: [
        { name: t('Asia'), data: [148, 91, 69, 62, 49, 51, 35, 41, 10] },
        { name: t('America'), data: [45, 77, 99, 88, 77, 56, 13, 34, 10] }
      ]
    }
  ];
};

export default function AppAreaInstalled() {
  const [seriesData, setSeriesData] = useState(2019);
  const { t } = useTranslation();

  const handleChangeSeriesData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSeriesData(Number(event.target.value));
  };

  const chartOptions = merge(BaseOptionChart(), {
    xaxis: {
      categories: [
        t('Jan'),
        t('Feb'),
        t('Mar'),
        t('Apr'),
        t('May'),
        t('Jun'),
        t('Jul'),
        t('Aug'),
        t('Sep')
      ]
    }
  });

  return (
    <Card>
      <CardHeader
        title={t('Orders')}
        subheader={`(+43%) ${t('than last year')}`}
        action={
          <TextField
            select
            fullWidth
            value={seriesData}
            SelectProps={{ native: true }}
            onChange={handleChangeSeriesData}
            sx={{
              '& fieldset': { border: '0 !important' },
              '& select': {
                pl: 1,
                py: 0.5,
                pr: '24px !important',
                typography: 'subtitle2'
              },
              '& .MuiOutlinedInput-root': {
                borderRadius: 0.75,
                bgcolor: 'background.neutral'
              },
              '& .MuiNativeSelect-icon': {
                top: 4,
                right: 0,
                width: 20,
                height: 20
              }
            }}
          >
            {CHART_DATA()?.map((option) => (
              <option key={option.year} value={option.year}>
                {option.year}
              </option>
            ))}
          </TextField>
        }
      />

      {CHART_DATA()?.map((item) => (
        <Box key={item.year} sx={{ mt: 3, mx: 3 }} dir="ltr">
          {item.year === seriesData && (
            <ReactApexChart type="line" series={item.data} options={chartOptions} height={364} />
          )}
        </Box>
      ))}
    </Card>
  );
}
