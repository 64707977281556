// ----------------------------------------------------------------------

import {
  OrderType,
  PromotionStatus,
  PromotionType,
  PromotionUseType
} from '__generated__/globalTypes';
import { Booking } from './booking';
import { OBOrder } from './order';
import { IDay } from './recurring-days';
import { EffectiveDateTimeType } from 'components/_dashboard/promotion/effectiveDateTime/type/type';

export type Promotion = {
  id: string;
  name: string;
  description: string;
  status: string;
  type: PromotionType;
  numberOfDeals: number | string;
  usedQuantity?: number;
  createdAt?: number;
  minimumAmount?: number | string;
  merchantId: string;
  canBeUsedWithOtherPromotions?: boolean;
  effectiveDateTime: EffectiveDateTimeType[];
  excludedPromotionTags?: string[];
  includedPromotionTags?: string[];
  includedGetItemsPromoTags?: string[];
  availableType?: OrderType[];
  buyN?: number | string;
  freeN?: number | string | 'Infinite';
  percentage: number | string;
  discountAmount: number | string;
  isPercentage: boolean;
  applyOptionDiscount: boolean;
  discountedItemQty: number | string | 'Infinite';
  isSignUpPromotion: boolean;
  upToAmount?: number;
  isOffer?: boolean;
  offerVoucherSettings?: OfferVoucherSettings;
  useType: PromotionUseType;
  prizeDescription?: string;
  winnerCount?: number | string;
  entryLimitPerUser?: number | string;
  winCountLimitPerPerson?: number | string;
  isEntryAutomatic?: boolean;
  resultingPromotions?: string[];
  isPaymentRequired?: boolean;
  isHiddenInApp?: boolean;
  isLoyalty?: boolean;
  loyaltyDetails?: LoyaltyDetails;
  loyaltySettings: LoyaltySettings;
};

export type PromotionData = {
  id: string;
  name: string;
  description: string;
  status: string;
  type: PromotionType;
  numberOfDeals: number;
  usedQuantity: number | null;
  createdAt: number | null;
  minimumAmount?: number;
  merchantId: string;
  orders: OBOrder[];
  bookings: Booking[];
  canBeUsedWithOtherPromotions?: boolean;
  effectiveDateTime: EffectiveDateTimeType[];
  excludedPromotionTags?: string[];
  includedPromotionTags?: string[];
  availableType?: OrderType[];
  buyN?: number;
  freeN?: number;
  percentage?: number;
  discountAmount?: number;
  isPercentage?: boolean;
  upToAmount?: number;
  isOffer?: boolean;
  offerVoucherSettings?: OfferVoucherSettings;
  useType: PromotionUseType;
  isHiddenInApp?: boolean;
  isLoyalty?: boolean;
  loyaltySettings?: LoyaltySettings;
};

export interface PercentageDiscountData {
  percentageDiscount: number;
  excludingDishCategories: string;
  startDate: Date;
  endDate: Date;
  recurringDays: IDay | 'all';
  recurringStartTime: string;
  recurringEndTime: string;
  recurringNumberOfDeal: number;
  recurringAvailable: string[];
  discountType: string;
  currencyCode: string;
  bussinessType: string;
}

export interface PromotionBase {
  id?: string;
  name: string;
  description: string;
  percentage: number;
  discountAmount: number;
  isPercentage: boolean;
  numberOfDeals: number;
  availableType: OrderType[];
  effectiveDateTime: EffectiveDateTimeType[];
  status?: PromotionStatus;
  merchantId?: string;
  canBeUsedWithOtherPromotions: boolean;
  usedQuantity?: number;
  createdDate?: string;
  isPublished: boolean;
  includedPromotionTags?: string[];
  excludedPromotionTags?: string[];
  applyOptionDiscount: boolean;
  minimumAmount: number;
  upToAmount?: number;
  isOffer?: boolean;
  offerVoucherSettings?: OfferVoucherSettings;
  useType: PromotionUseType;
}

export interface PercentagePromotion extends PromotionBase {
  isSignUpPromotion: boolean;
}

export interface SpendPromotion extends PromotionBase {
  includedGetItemsPromoTags?: string[];
  discountedItemQty: number;
  isSignUpPromotion: boolean;
}

export interface BuyNGetNFreePromotion extends PromotionBase {
  buyN: number;
  freeN: number;
  includedGetItemsPromoTags?: string[];
}
export interface LuckyDrawPromotion extends PromotionBase {
  prizeDescription: string;
  winnerCount: number;
  entryLimitPerUser: number;
  winCountLimitPerPerson: number;
  isEntryAutomatic: boolean;
  resultingPromotions: string[];
}

export interface OfferVoucherSettings {
  offerName: string;
  offerDescription?: string;
  effectiveHoursAfterOfferActivated: number;
  effectiveDateTime: EffectiveDateTimeType[];
  offerExcludedItemTags?: string[];
  minimumAmount?: number;
  upToAmount?: number;
  voucherTerms: string;
}

export interface LoyaltyDetails {
  expiryDays: number;
  loyaltyReviewDate: number;
}

export interface LoyaltySettings {
  pointsPerDollar: number;
  maxPointsPerTransaction: number;
  expiryDays: number;
  loyaltyReviewDate: number;
  loyaltyBenefits: LoyaltyBenefit[];
}

export interface LoyaltyBenefit {
  id: string;
  name: string;
  description: string;
  requiredPoints: number;
  benefitPromotionIds: string[];
}

export interface LoyaltyProgram {
  type: LoyaltyProgramType;
  pointsLoyaltyId: string;
  progressLoyaltyId: string;
  stampLoyaltyId: string;
}

enum LoyaltyProgramType {
  points,
  stamp,
  progress
}

export enum DiscountType {
  Percentage = 'Percentage',
  Amount = 'Amount'
}

export enum SpendDiscountType {
  FreeItem = 'FreeItem',
  Percentage = 'Percentage',
  Amount = 'Amount'
}

export enum PromotionFunctionType {
  Normal = 'NormalPromo',
  Loyalty = 'LoyaltyPromo',
  Offer = 'OfferPromo'
}

export const NUMBER_OF_DEALS = 'numberOfDeals';
export const MINMUM_AMOUNT = 'minimumAmount';
export const BUY_N = 'buyN';
export const FREE_N = 'freeN';
export const MINIMUM_AMOUNT = 'minimumAmount';
export const PERCENTAGE = 'percentage';
export const DISCOUNT_AMOUNT = 'discountAmount';
