import moment from 'moment';

export const convertTime = (timeValue: any, type: string) => {
  const time = moment(timeValue);
  if (type === 'time') {
    const hours = time.hour() * 60;
    const minutes = time.minutes();
    return hours + minutes;
  }
  return timeValue.getTime();
};

export const parseTime = (time: number) => {
  const formatTime = moment.parseZone(0).startOf('day').add(time, 'minutes');
  return formatTime;
};
