import { ApolloError, useLazyQuery } from '@apollo/client';
import { getMenu_getMenu } from '__generated__/getMenu';
import { posMenu_posMenu } from '__generated__/posMenu';
import { GET_MENU } from '_apis_/queries/menu';
import { GET_POS_MENU } from '_apis_/queries/merchant';
import { useEffect, useState } from 'react';

type getMenu = getMenu_getMenu & posMenu_posMenu;

interface QueryResult {
  data: getMenu | undefined;
  loading: boolean;
  error: ApolloError | undefined;
}

const useGetMenu = (menuId: string, orderbuddyMerchantId: string | undefined) => {
  const getQueryPath = (orderbuddyMerchantId: string | undefined) => {
    if (orderbuddyMerchantId) {
      return GET_POS_MENU;
    }
    return GET_MENU;
  };

  const [getMenu, { data, loading, error }] = useLazyQuery(getQueryPath(orderbuddyMerchantId), {
    variables: { menuId: menuId },
    fetchPolicy: 'network-only',
    onCompleted(response) {}
  });

  const [result, setResult] = useState<QueryResult>({
    data: undefined,
    loading: true,
    error: undefined
  });

  useEffect(() => {
    if (menuId) {
      getMenu();
    }
  }, [menuId, getMenu]);

  useEffect(() => {
    setResult((r) => ({ ...r, loading: loading, error: error }));
  }, [loading, error, setResult]);

  useEffect(() => {
    if (!data) return;
    if (orderbuddyMerchantId) {
      setResult({ loading: loading, error: error, data: data.posMenu });
    }
    if (!orderbuddyMerchantId) {
      setResult({ loading: loading, error: error, data: data.getMenu });
    }
  }, [data, loading, error, orderbuddyMerchantId, setResult]);

  return result;
};

export default useGetMenu;
