import plusIcon from '@iconify/icons-eva/plus-outline';
import deleteIcon from '@iconify/icons-eva/trash-fill';
import { Icon } from '@iconify/react';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Button, Stack, TextField, useTheme } from '@mui/material';
import moment from 'moment';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { convertTime, parseTime } from 'utils/helperTime';
import { darkenColor } from 'utils/rgb';
import { DeleteButton } from '../styles';
import { EffectiveDateTimeType, HoursType, useEffectDateTimeList } from './type/type';
interface EffectDateSelectorProps {
  index: number;
  effectDate: EffectiveDateTimeType;
}

interface CustomDatePickerProps {
  label: string;
  isError: boolean;
  textError: string;
  value: string;
  isReadOnly: boolean;
  onChange: (newValue: Date | null) => void;
}

function CustomDatePicker(props: CustomDatePickerProps) {
  return (
    <DesktopDatePicker
      label={props.label}
      allowSameDateSelection
      readOnly={props.isReadOnly}
      renderInput={(params: any) => (
        <TextField
          fullWidth
          {...params}
          error={Boolean(props.isError)}
          helperText={Boolean(props.isError) && props.textError}
        />
      )}
      inputFormat="yyyy/MM/dd"
      value={props.value}
      onChange={props.onChange}
    />
  );
}
export const EffectDateSelector: React.FC<EffectDateSelectorProps> = (props) => {
  const { index, effectDate } = props;
  const { t } = useTranslation();
  const { updateStartDate, updateEndDate, updateHours, isOffer = false } = useEffectDateTimeList();
  const [errorStartDate, setErrorStartDate] = useState(false);
  const [errorEndDate, setErrorEndDate] = useState(false);
  const { pathname } = useLocation();
  const theme = useTheme();
  const btnBackgroundColor = isOffer ? '#DCC179' : theme.palette.primary.main;
  const isEdit = pathname.includes('edit');
  // useMemo() with   const now = new Date();
  const now = useMemo(() => new Date(), []);
  const handleChangeStartDate = (newValue: Date | null) => {
    if (!newValue) return;
    // format date yyyy-MM-DD string with monent lib
    const date = moment(newValue).format('yyyy-MM-DD');
    updateStartDate(index, date);
  };

  const handleChangeEndDate = (newValue: Date | null) => {
    if (!newValue) return;
    // format date yyyy-MM-DD string with monent lib
    const date = moment(newValue).format('yyyy-MM-DD');
    updateEndDate(index, date);
  };

  const handleChangeOpenTimeStart = (startTime: any, hoursIndex: number) => {
    if (!startTime) return;
    const newHours = [...effectDate.hours];
    newHours[hoursIndex].openStart = convertTime(startTime, 'time');
    updateHours(index, newHours);
  };
  const handleChangeOpenTimeEnd = (endTime: any, hoursIndex: number) => {
    if (!endTime) return;
    const newHours = [...effectDate.hours];
    newHours[hoursIndex].openEnd = convertTime(endTime, 'time');
    updateHours(index, newHours);
  };

  const handleDeleteEffectiveTime = (hoursIndex: number) => {
    // make an copy
    const newHours = [...effectDate.hours];
    // remove the item
    newHours.splice(hoursIndex, 1);
    updateHours(index, newHours);
  };
  const handleAddEffectiveTime = () => {
    let newHours = [...effectDate.hours];
    newHours.push({
      openStart: convertTime(moment().startOf('day'), 'time'),
      openEnd: convertTime(moment().endOf('day'), 'time')
    });
    updateHours(index, newHours);
  };

  const checkErrorStartDate = useCallback(() => {
    return moment(effectDate.startDate).diff(now, 'days') < 0;
  }, [effectDate.startDate, now]);

  // useCallback
  const checkErrorEndDate = useCallback(() => {
    const isEndDatePassed = moment(effectDate.endDate).diff(now, 'days') < 0;
    const isEndDateBeforeStartDate =
      moment(effectDate.endDate).diff(moment(effectDate.startDate), 'minutes') < 0;
    return isEndDatePassed || isEndDateBeforeStartDate;
  }, [effectDate.endDate, effectDate.startDate, now]);

  useEffect(() => {
    if (isEdit) {
      setErrorEndDate(checkErrorEndDate());
    } else {
      setErrorStartDate(checkErrorStartDate());
      setErrorEndDate(checkErrorEndDate());
    }
  }, [effectDate.startDate, effectDate.endDate, checkErrorEndDate, checkErrorStartDate, isEdit]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
          <CustomDatePicker
            label={t('Start Date')}
            isError={errorStartDate}
            isReadOnly={isEdit ? true : false}
            value={effectDate.startDate}
            onChange={handleChangeStartDate}
            textError={t('Invalid Date')}
          />
          <CustomDatePicker
            label={t('End Date')}
            isError={errorEndDate}
            isReadOnly={false}
            value={effectDate.endDate}
            onChange={handleChangeEndDate}
            textError={t('Invalid Date')}
          />
        </Stack>
        {effectDate.hours.length > 0 &&
          effectDate.hours.map((item: HoursType, index: number) => (
            <Fragment key={index}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                marginTop={1}
                spacing={{ xs: 3, sm: 2 }}
              >
                <TimePicker
                  ampm={false}
                  label={t('Open Time')}
                  value={parseTime(item.openStart)}
                  onChange={(event) => {
                    handleChangeOpenTimeStart(event, index);
                  }}
                  renderInput={(params) => <TextField error fullWidth {...params} />}
                />
                <TimePicker
                  ampm={false}
                  label={t('Close Time')}
                  value={parseTime(item.openEnd)}
                  onChange={(event) => {
                    handleChangeOpenTimeEnd(event, index);
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Stack>
              {effectDate.hours.length > 1 && (
                <DeleteButton onClick={() => handleDeleteEffectiveTime(index)}>
                  <Icon icon={deleteIcon} width={16} height={16} />
                  &nbsp;
                  <span>{t('Remove')}</span>
                </DeleteButton>
              )}
            </Fragment>
          ))}
      </LocalizationProvider>
      <Box
        sx={{
          mt: 12,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Button
          sx={{
            width: '100%',
            backgroundColor: btnBackgroundColor,
            '&:hover': {
              backgroundColor: darkenColor(btnBackgroundColor, 20)
            },
            color: '#FFFFFF'
          }}
          onClick={handleAddEffectiveTime}
        >
          <Icon icon={plusIcon} width={16} height={16} />
          &nbsp;
          <span>{t('Add effective time')}</span>
        </Button>
      </Box>
    </>
  );
};
