import { useLazyQuery } from '@apollo/client';
import { Card, Grid, Typography } from '@mui/material';
import { Product } from '@type/merchant';
import LoadingComponent from 'components/LoadingComponent';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_PRODUCTS } from '_apis_/queries/merchant';
import Productitem from './Productitem';

const Products = () => {
  const { t } = useTranslation();
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [getAllProducts] = useLazyQuery(GET_PRODUCTS, {
    fetchPolicy: 'no-cache',
    onCompleted(res) {
      if (res?.getProducts) {
        setAllProducts(res?.getProducts);
      }
      setLoading(false);
    },
    onError(error) {
      setLoading(false);
    }
  });

  useEffect(() => {
    getAllProducts();
  }, [getAllProducts]);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{ p: 3 }} style={{ minHeight: 600 }}>
          <Typography sx={{ margin: '10px 0 20px' }} gutterBottom variant="h5" component="div">
            {t('Select a plan')}
          </Typography>
          <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
            {allProducts.map((product: Product, index: number) => {
              return <Productitem key={index} product={product} />;
            })}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Products;
