export const convertCamelCase = (name: string) => {
  let temp = name;
  let indexInit = 0;
  const allUpper = name.match(/[A-Z]/g);
  temp = temp.slice(0, 0) + temp[0].toUpperCase() + temp.slice(1);
  allUpper?.forEach((item: any, indexUpper: number) => {
    const index = temp.indexOf(item, indexInit + indexUpper);
    if (index !== 0) {
      indexInit = index;
      temp = temp.slice(0, index) + ' ' + temp.slice(index);
    }
  });
  return temp;
};
