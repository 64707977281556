import { gql } from '@apollo/client';
export const GET_ALL_USER = gql`
  query getAllUsers {
    getAllUsers {
      id
      name
      role
      email
      merchantId
      phoneNumber
      permissions
      createdAt
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser(
    $email: String!
    $merchantId: ID
    $password: String!
    $role: UserRole!
    $name: String!
  ) {
    createUser(
      email: $email
      merchantId: $merchantId
      password: $password
      role: $role
      name: $name
    )
  }
`;
export const DELETE_USER = gql`
  mutation deleteUser($email: String!) {
    deleteUser(email: $email)
  }
`;

export const GET_USER_DETAIL = gql`
  query getUserDetail {
    getUserDetail {
      id
      name
      role
      email
      merchantId
      permissions
      phoneNumber
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

export const UPDATE_USER_DETAILS = gql`
  mutation updateUserDetails($user: UserInput!) {
    updateUserDetails(user: $user)
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($user: AccountInput!) {
    updateUser(user: $user)
  }
`;
