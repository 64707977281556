import { FormikProps } from 'formik';
import { DeepKeyOf } from '../formik/type';
import { Stack } from '@mui/material';
import ItemButton from './itemButton';

export interface TagsIconsProps<T> {
  fieldPath: DeepKeyOf<T>;
  formik: FormikProps<T>;
}

export const TagsIcons = <T,>(props: TagsIconsProps<T>) => {
  const { formik, fieldPath } = props;
  const { getFieldProps, setFieldValue } = formik;
  const tags = getFieldProps(fieldPath).value;

  const handleIncludedPromotionTagsOnClick = (tag: string) => {
    const tags = getFieldProps(fieldPath).value;
    if (Array.isArray(tags) && tags.every((item) => typeof item === 'string')) {
      if (tags.includes(tag)) {
        const index = tags.indexOf(tag);
        const newTags = [...tags];
        newTags.splice(index, 1);
        setFieldValue(fieldPath, newTags);
      }
    } else {
      console.error('Tags is not a string array.');
    }
  };
  return (
    <Stack direction={'row'} sx={{ flexWrap: 'wrap' }}>
      {Array.isArray(tags) &&
        tags.every((tag) => typeof tag === 'string') &&
        tags.map((tag, index) => (
          <div key={index}>
            <ItemButton onClick={() => handleIncludedPromotionTagsOnClick(tag)} content={tag} />
          </div>
        ))}
    </Stack>
  );
};
