import SearchIcon from '@mui/icons-material/Search';
import { Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import { useState } from 'react';
interface IFAQSearchInputProps {
  handleKeywordChange: (keyword: string) => void;
}

export const FAQSearchInput = (props: IFAQSearchInputProps) => {
  const { handleKeywordChange } = props;
  const [keyword, setKeyword] = useState('');
  const handleMyKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setKeyword(keyword);
    handleKeywordChange(keyword);
  };
  return (
    <Paper
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        border: '1px solid #ccc',
        boxShadow: 'none'
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search FAQ"
        inputProps={{ 'aria-label': 'search FAQ' }}
        value={keyword}
        onChange={handleMyKeywordChange}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};
