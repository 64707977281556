import { useMutation, useApolloClient } from '@apollo/client';
import homeFill from '@iconify/icons-eva/home-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Icon } from '@iconify/react';
import { Box, Button, Divider, MenuItem, Typography } from '@mui/material';
// material
import { alpha } from '@mui/material/styles';
import { Types } from 'contexts/JWTContext';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { logoutSuccess } from 'redux/slices/auth';
import { RootState, useSelector } from 'redux/store';
import { LOGOUT } from '_apis_/queries/auth';
// components
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import MyAvatar from '../../components/MyAvatar';
// import useIsMountedRef from '../../hooks/useIsMountedRef';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const MENU_OPTIONS = () => {
  const { t } = useTranslation();
  return [
    {
      label: t('home'),
      icon: homeFill,
      linkTo: '/'
    },
    {
      label: t('Settings'),
      icon: settings2Fill,
      linkTo: PATH_DASHBOARD.user.account
    }
  ];
};

// ----------------------------------------------------------------------
interface IProps {
  setIsLoading: any;
}

export default function AccountPopover({ setIsLoading }: IProps) {
  const navigate = useNavigate();
  const client = useApolloClient();
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.auth.user);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const logout = async () => {
    localStorage.removeItem('user');
    // document.cookie = 'session= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
    logoutMutation();
  };

  const [logoutMutation] = useMutation(LOGOUT, {
    onCompleted(response) {
      client.clearStore();
      localStorage.clear();
      onLogoutCompleted(response);
    },
    onError(error) {
      console.log('Logout Failed: ', error);
    }
  });

  const onLogoutCompleted = async (response: any) => {
    await client.clearStore();
    await localStorage.clear();
    await dispatch(logoutSuccess());
    await dispatch({
      type: Types.Logout
    });
    await navigate('/');
    setIsLoading(false);
  };
  const handleLogout = async () => {
    try {
      setIsLoading(true);
      handleClose();
      await logout?.();
    } catch (error) {
      enqueueSnackbar(t('Unable to logout'), { variant: 'error' });
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user?.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS()?.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            {t('Logout')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
