import { useEffect, useState } from 'react';

// material
import { Container } from '@mui/material';
// redux
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import { useLazyQuery } from '@apollo/client';
import LoadingComponent from 'components/LoadingComponent';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { fDateString } from 'utils/formatTime';
import { BOOKING } from '_apis_/queries/booking';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Page from '../../../components/Page';
import BookingDetailForm from './components/BookingDetailForm';

// ----------------------------------------------------------------------

export default function BookingDetail() {
  const { themeStretch } = useSettings();
  const { t } = useTranslation();
  const params: any = useParams();
  const today = new Date();
  const [currentBooking, setCurrentBooking] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const dateBooking = localStorage.getItem('dateBooking') || fDateString(today);

  useEffect(() => {
    const variables = {
      dateString: dateBooking,
      bookingId: params?.id
    };
    ordersQuery({ variables });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [ordersQuery] = useLazyQuery(BOOKING, {
    onCompleted(response) {
      if (response.booking) setCurrentBooking(response.booking);
      setIsLoading(false);
    },
    onError(error) {
      console.log(error);
    },
    fetchPolicy: 'no-cache'
  });

  return (
    <Page title={t('Booking Detail')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={t('Booking Detail')}
          links={[
            { name: t('Dash Board'), href: PATH_DASHBOARD.root },
            { name: t('Booking'), href: PATH_DASHBOARD.booking.list },
            { name: currentBooking?.id || '' }
          ]}
        />
        {isLoading ? <LoadingComponent /> : <BookingDetailForm currentBooking={currentBooking} />}
      </Container>
    </Page>
  );
}
