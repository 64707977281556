import mock from './mock';
import mockData from '../utils/mock-data';

// ----------------------------------------------------------------------

mock.onGet('/api/promotion/all').reply(() => {
  const promotions = [...Array(24)].map((_, index) => ({
    id: mockData.id(index),
    status: 'open',
    name: 'Burger',
    menuName: 'Most delicious burger',
    locations: 'HongKong',
    coupon: 'coupon code',
    used: 'N/A',
    associatedWith: 'mcdonalds'
  }));

  return [200, { promotions }];
});
