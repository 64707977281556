import { createSlice } from '@reduxjs/toolkit';
export type AuthUser = null | Record<string, any>;
export type AuthMerchant = null | Record<string, any>;
export interface LoginAuth {
  email: String;
  password: String;
}

interface IAuth {
  isLoading: Boolean;
  isCheckUser: Boolean;
  error: Boolean;
  isAuthenticated: Boolean;
  isInitialized: Boolean;
  user: AuthUser;
  merchant: AuthMerchant;
  isLoadingGetMerchantSuccess: Boolean;
}

const initialState: IAuth = {
  isLoading: false,
  isCheckUser: false,
  error: false,
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  merchant: null,
  isLoadingGetMerchantSuccess: true
};
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state: IAuth) {
      state.isLoading = true;
    },
    startCheckUser(state: IAuth) {
      state.isCheckUser = true;
    },
    endCheckUser(state: IAuth) {
      state.isCheckUser = false;
    },
    // HAS ERROR
    hasError(state: IAuth, action: any) {
      state.isLoading = false;
      state.error = action.payload;
    },
    loginSuccess(state: IAuth, action: any) {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.isInitialized = true;
    },
    logoutSuccess(state: IAuth) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.isInitialized = false;
      state.user = null;
      state.isLoadingGetMerchantSuccess = true;
      state.merchant = null;
    },
    getUserLogin(state: IAuth, action: any) {
      state.isLoading = false;
      state.user = action.payload;
    },
    updateMerchantId(state: IAuth, action: any) {
      // if (state.user) {
      //   if (state.user.email) {
      //     if(state.user.email === action.payload.userEmail)
      //   }
      // }
    },
    getAuthMerchantSuccess(state: IAuth, action: any) {
      state.isLoading = false;
      state.merchant = action.payload;
      state.isLoadingGetMerchantSuccess = false;
    },
    updateOpeningHoursSuccess(state: IAuth) {
      state.isLoadingGetMerchantSuccess = true;
    }
  },
  extraReducers: {}
});
export const {
  startCheckUser,
  endCheckUser,
  loginSuccess,
  logoutSuccess,
  getUserLogin,
  updateMerchantId,
  getAuthMerchantSuccess,
  updateOpeningHoursSuccess
} = authSlice.actions;
export default authSlice.reducer;
