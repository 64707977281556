import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import ConfirmDialog from 'components/commonComponents/ConfirmDialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppSectionFormDialogContent } from './AppSectionFormDialogContent';
import { AppSection } from '@type/appSection';

interface IProps {
  section?: AppSection;
}

export default function AppSectionFormBtn(props: IProps) {
  const { t } = useTranslation();
  const { section } = props;
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  return (
    <>
      {section && (
        <Button sx={{ padding: 0, minWidth: '40px', height: '30px' }} onClick={handleDialogOpen}>
          <EditIcon color="secondary" fontSize="small" />
        </Button>
      )}
      {!section && (
        <Button sx={{ marginTop: '16px' }} variant="contained" onClick={handleDialogOpen}>
          {t('Add App Section')}
        </Button>
      )}
      <ConfirmDialog
        title={section ? t('Update Section') : t('Create Section')}
        open={isDialogOpen}
        fullWidth={true}
        hiddenButton={true}
        maxWidth="sm"
        sx={{ height: '80vh' }}
        close={() => setIsDialogOpen(false)}
        action={() => setIsDialogOpen(false)}
      >
        <AppSectionFormDialogContent section={section} setIsDialogOpen={setIsDialogOpen} />
      </ConfirmDialog>
    </>
  );
}
