// hooks
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import createAvatar from '../utils/createAvatar';
//
import { MAvatar } from './@material-extend';
import { MAvatarProps } from './@material-extend/MAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
  // const { user } = useAuth();
  const user = useSelector((state: RootState) => state.auth.user);

  return (
    <MAvatar
      src={user?.photoURL}
      alt={user?.name}
      color={user?.photoURL ? 'default' : createAvatar(user?.name).color}
      {...other}
    >
      {createAvatar(user?.name).name}
    </MAvatar>
  );
}
