import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GET_ALL_MERCHANTS } from '_apis_/queries/merchant';
import { removeTypenameKey } from 'components/_dashboard/promotion/tools';
import { client } from 'index';
import { Merchant } from '../../@type/merchant';
import { cloneDeep } from 'lodash';

// ----------------------------------------------------------------------

type MerchantState = {
  isLoading: boolean;
  error: boolean;
  merchantList: Merchant[];
};

const initialState: MerchantState = {
  isLoading: false,
  error: false,
  merchantList: []
};

const slice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state: any) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state: any, action: any) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MERCHANT
    getMerchantsSuccess(state: any, action: any) {
      state.isLoading = false;
      state.merchantList = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(queryAllMerchants.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(queryAllMerchants.fulfilled, (state, action) => {
      state.isLoading = false;
      state.merchantList = action.payload;
    });
    builder.addCase(queryAllMerchants.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export const queryAllMerchants = createAsyncThunk(
  'merchant/queryAllMerchants',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query<{ getAllMerchants: Merchant[] }>({
        query: GET_ALL_MERCHANTS,
        fetchPolicy: 'network-only'
      });
      if (!response.data) rejectWithValue('No data returned');
      if (response.errors) rejectWithValue(response.errors);
      // response.data.getAllMerchants is read-only object
      const copy = cloneDeep(response.data.getAllMerchants);
      removeTypenameKey(copy);
      return copy;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Reducer
export default slice.reducer;
export const { getMerchantsSuccess } = slice.actions;
// Actions

// ----------------------------------------------------------------------
