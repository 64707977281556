import { Container, Divider, Typography } from '@mui/material';
import { OBFAQSection } from '@type/faq';
import { PreviewSection } from './previewSection';
import { FAQSearchInput } from './searchInput';
import { useState } from 'react';

interface IPreviewSectionsProps {
  sections: OBFAQSection[];
}
export const PreviewSectionsBox = (props: IPreviewSectionsProps) => {
  const { sections } = props;
  const [mySections, setMySections] = useState(sections);
  const handleKeywordChange = (keyword: string) => {
    if (keyword === '') {
      setMySections(sections);
      return;
    }

    const newSections = sections
      .map((section) => {
        const filteredQuestions = section.questions.filter(
          (question) =>
            question.question.toLowerCase().includes(keyword.toLowerCase()) ||
            question.answer.toLowerCase().includes(keyword.toLowerCase())
        );

        if (filteredQuestions.length > 0) {
          return {
            ...section,
            questions: filteredQuestions
          };
        }
        return null;
      })
      .filter((section) => section !== null);

    setMySections(newSections as OBFAQSection[]);
  };

  return (
    <>
      <Container
        sx={{
          mt: 10,
          width: { xs: '100vw', sm: '70vw' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography variant="h3" gutterBottom>
          {'Frequently Asked Questions'}
        </Typography>
        <FAQSearchInput handleKeywordChange={handleKeywordChange} />
        {mySections.map((section, index) => (
          <PreviewSection key={index} section={section} />
        ))}
        <Divider variant="middle" />
      </Container>
    </>
  );
};
