import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import { Product } from '@type/merchant';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'routes/paths';

interface IProps {
  product: Product;
}

const Productitem = ({ product }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubscribe = () => {
    if (product.id) {
      console.log(PATH_DASHBOARD.paymentSubscriptions.success(product.id));
      navigate(PATH_DASHBOARD.paymentSubscriptions.success(product.id));
    }
  };
  return (
    <Grid item xs={4}>
      <Card sx={{ maxWidth: 345 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {product.product.name}
          </Typography>
          <Typography sx={{ marginTop: '10px' }} gutterBottom variant="h5" component="div">
            ${product.unit_amount / 100}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {product.product.description}
          </Typography>
        </CardContent>
        <CardActions sx={{ margin: '16px' }}>
          <Button variant="outlined" onClick={onSubscribe}>
            {t('Subscribe')}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default Productitem;
