import { weekdays, weekdaysType } from '@type/booking-sessions';
import { OpenTimeInput } from '__generated__/globalTypes';
import { parseTime } from './helperTime';
import _, { isObject } from 'lodash';
import { DeepKeyOf } from 'components/_dashboard/promotion/formik/type';

type Anonymous = Record<string | number, string>;
export function applySortFilter(
  array: any[],
  comparator: (a: any, b: any) => number,
  query: string
) {
  const stabilizedThis = array.map((el, index) => [el, index] as const);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    const filterName = array.filter(
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
    // const filterEmail = array.filter(
    //   (_user) => _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1
    // );
    // const filterRole = array.filter(
    //   (_user) => _user.role.toLowerCase().indexOf(query.toLowerCase()) !== -1
    // );
    // const filterMerchantId = array.filter(
    //   (_user) => _user.merchantId?.toLowerCase().indexOf(query.toLowerCase()) !== -1
    // );
    if (filterName.length > 0) {
      return filterName;
    }
    // else if (filterEmail.length > 0) {
    //   return filterEmail;
    // } else if (filterRole.length > 0) {
    //   return filterRole;
    // } else if (filterMerchantId.length > 0) {
    //   return filterMerchantId;
    // }
  }
  return stabilizedThis.map((el) => el[0]);
}

export function getComparator(order: string, orderBy: string) {
  return order === 'desc'
    ? (a: Anonymous, b: Anonymous) => descendingComparator(a, b, orderBy)
    : (a: Anonymous, b: Anonymous) => -descendingComparator(a, b, orderBy);
}

export function descendingComparator(a: Anonymous, b: Anonymous, orderBy: string) {
  if (b[orderBy] === null || b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (a[orderBy] === null || b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export const renderDataOpenTime = (
  dataOpenTime: OpenTimeInput,
  setDayoff: any,
  setOpenHours: any
) => {
  if (dataOpenTime) {
    if (dataOpenTime?.closeDates) {
      setDayoff(dataOpenTime.closeDates);
    }
    if (dataOpenTime?.openHours) {
      const tempOpenHours = [...dataOpenTime?.openHours]?.map((itemTime, index) => {
        if (itemTime) {
          if (dataOpenTime?.openHours[index]?.weekDays) {
            const tempWeekdays = weekdays.map((weekday: weekdaysType) =>
              dataOpenTime?.openHours[index]?.weekDays.includes(weekday.name)
                ? { ...weekday, status: true }
                : { ...weekday, status: false }
            );
            return {
              dateStart: parseTime(itemTime.openStart),
              dateEnd: parseTime(itemTime.openEnd),
              openWeekdays: tempWeekdays
            };
          }
          return {
            dateStart: parseTime(itemTime.openStart),
            dateEnd: parseTime(itemTime.openEnd),
            openWeekdays: weekdays
          };
        } else {
          return itemTime;
        }
      });
      setOpenHours(tempOpenHours);
    }
  }
};

export const renderEffectiveDataTime = (data: any, setData?: any) => {
  const tempEffectiveDateTime = [...data]?.map((item, index) => {
    if (data[index]?.recurringWeekDays || data[index]?.hours) {
      const tempRecurringWeekDays = weekdays?.map((weekday: weekdaysType) =>
        data[index]?.recurringWeekDays?.includes(weekday.name)
          ? { ...weekday, status: true }
          : { ...weekday, status: false }
      );
      const tempHours: any = data[index]?.hours.map((item: any) => {
        return { openStart: item.openStart, openEnd: item.openEnd };
      });
      return {
        startDate: item.startDate,
        endDate: item.endDate,
        recurringWeekDays: tempRecurringWeekDays,
        hours: tempHours
      };
    }
    return {
      startDate: item.startDate,
      endDate: item.endDate,
      recurringWeekDays: weekdays,
      hours: []
    };
  });
  if (setData) {
    setData(tempEffectiveDateTime);
  }
  return tempEffectiveDateTime;
};

export const sortList = <T>(list: T[], order: 'asc' | 'desc', orderBy: DeepKeyOf<T>) => {
  let copyList = [...list];
  return _.orderBy(copyList, [orderBy], [order]);
};

export const filterListByKeys = <T>(list: T[], keys: DeepKeyOf<T>[], value: string): T[] => {
  if (keys.length === 0 || value.trim() === '') return list;
  const lowercaseValue = value.toLowerCase();
  const newList = _.filter(list, (item) =>
    _.some(keys, (key) => {
      const fieldValue = _.get(item, key);
      if (fieldValue == null) return false;
      if (typeof fieldValue === 'string') return fieldValue.toLowerCase().includes(lowercaseValue);
      if (typeof fieldValue === 'number')
        return fieldValue.toString().toLowerCase().includes(lowercaseValue);
      if (typeof fieldValue === 'boolean')
        return fieldValue.toString().toLowerCase().includes(lowercaseValue);

      return false;
    })
  );
  return newList;
};

export const getAllKeys = (obj: any, prefix = ''): string[] => {
  return Object.keys(obj).reduce((acc: string[], key: string) => {
    const fullPath = prefix ? `${prefix}.${key}` : key;
    acc.push(fullPath);
    if (isObject(obj[key])) {
      acc = acc.concat(getAllKeys(obj[key], fullPath));
    }
    return acc;
  }, []);
};
