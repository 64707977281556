// material
import { Box, Container } from '@mui/material';
import { useSelectedAPPRadio } from '../components/useSelectedAPPRadio';
import { FAQPreviewBox } from '../components/preview/preview';

// ----------------------------------------------------------------------
export default function FAQPreview() {
  const { OBAppRadioGroups, selectedOBAppValue } = useSelectedAPPRadio();
  return (
    <>
      <Container>
        <Box sx={{ mt: 3 }} display="flex" justifyContent="center" alignItems="center">
          <OBAppRadioGroups />
        </Box>
        <FAQPreviewBox app={selectedOBAppValue} />
      </Container>
    </>
  );
}
