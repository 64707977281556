import { Grid, Typography } from '@mui/material';
import React from 'react';

export type Value = string | React.ReactElement | KeyValue[] | number;

export interface KeyValue {
  key: string;
  value: Value;
}

export interface KeyValueListProps {
  items: KeyValue[];
  backgroundColor?: string;
}

const RenderValue: React.FC<{ value: Value }> = ({ value }) => {
  if (typeof value === 'string') {
    return <Typography>{value}</Typography>;
  } else if (typeof value === 'number') {
    return <Typography>{value}</Typography>;
  } else if (React.isValidElement(value)) {
    return value;
  } else if (Array.isArray(value)) {
    return <KeyValueList items={value} />;
  }
  return null;
};

export const KeyValueList: React.FC<KeyValueListProps> = ({ items, backgroundColor }) => {
  return (
    <Grid container spacing={2}>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <Grid item xs={3}>
            <Typography variant="body1" component="span">
              {item.key}
            </Typography>
          </Grid>
          <Grid item xs={9} sx={{ backgroundColor: backgroundColor }}>
            <RenderValue value={item.value} />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};
