import { gql } from '@apollo/client';

// createGiftCardAccount
export const CREATE_GIFTCARD_ACCOUNT = gql`
  mutation createGiftCardAccount($input: GiftCardAccountInput!) {
    createGiftCardAccount(input: $input)
  }
`;
export const DELETE_GIFTCARD_ACCOUNT = gql`
  mutation deleteGiftCardAccount($giftCardAccountId: ID!) {
    deleteGiftCardAccount(giftCardAccountId: $giftCardAccountId)
  }
`;
export const UPDATE_GIFTCARD_ADMIN_USERS = gql`
  mutation updateGiftCardAdminUsers($giftCardAccountId: ID!, $userIds: [ID!]!) {
    updateGiftCardAdminUsers(giftCardAccountId: $giftCardAccountId, userIds: $userIds) {
      id
    }
  }
`;
export const ADJUST_GIFTCARD_ACCOUNT = gql`
  mutation adjustGiftCardAccount($input: AdjustGiftCardInput!) {
    adjustGiftCardAccount(input: $input) {
      id
    }
  }
`;

export const CREATE_GIFTCARD = gql`
  mutation createGiftCard($input: GiftCardInput!) {
    createGiftCard(input: $input) {
      id
    }
  }
`;

export const CREATE_GIFTCARDS = gql`
  mutation createGiftCards($input: [GiftCardInput!]!) {
    createGiftCards(input: $input)
  }
`;

export const VOID_GIFTCARD = gql`
  mutation voidGiftCard($giftCardId: ID!) {
    voidGiftCard(giftCardId: $giftCardId)
  }
`;

export const RESENT_GIFTCARD = gql`
  mutation resentGiftCard($giftCardId: ID!) {
    resentGiftCard(giftCardId: $giftCardId)
  }
`;

export const SWITCH_GIFTCARD_ACCOUNT = gql`
  mutation switchGiftCardAccount($giftCardAccountId: ID!) {
    switchGiftCardAccount(giftCardAccountId: $giftCardAccountId)
  }
`;
//giftCardAccounts
export const GET_ALL_GIFTCARD_ACCOUNTS = gql`
  query giftCardAccounts {
    giftCardAccounts {
      id
      name
      description
      adminUserIds
      activeGiftCards {
        id
        message
        description
        giftCardAccountId
        serialNumber
        recipientName
        recipientPhone
        recipientEmail
        amount
        createdAt
        updatedAt
        redeemCode
        status
        merchantId
        expiredAt
        createdBy
        createdUserId
        sender
      }
      redeemedGiftCards {
        id
        message
        description
        giftCardAccountId
        serialNumber
        recipientName
        recipientPhone
        recipientEmail
        amount
        createdAt
        updatedAt
        redeemCode
        status
        merchantId
        expiredAt
        createdBy
        createdUserId
        sender
      }
      voidedGiftCards {
        id
        message
        description
        giftCardAccountId
        serialNumber
        recipientName
        recipientPhone
        recipientEmail
        amount
        createdAt
        updatedAt
        redeemCode
        status
        merchantId
        expiredAt
        createdBy
        createdUserId
        sender
      }
      balance
      createdAt
      updatedAt
      updatedUserId
      adjustmentRecords {
        id
        giftCardAccountId
        updatedUserId
        amount
        updatedAt
        type
        adjustmentType
        description
      }
    }
  }
`;

export const GET_ALL_GIFTCARD_ACCOUNTS_BASE_INFO = gql`
  query giftCardAccountsBaseInfo {
    giftCardAccounts {
      id
      name
      description
      adminUserIds
      balance
      createdAt
      updatedAt
      updatedUserId
    }
  }
`;
export const GET_GIFTCARD_ACCOUNT_BASE = gql`
  query giftCardAccount {
    giftCardAccount {
      id
      name
      description
      adminUserIds
      balance
      createdAt
      updatedAt
      updatedUserId
    }
  }
`;

export const GET_MY_GIFTCARDS = gql`
  query myGiftCards {
    giftCardAccount {
      activeGiftCards {
        id
        message
        description
        giftCardAccountId
        serialNumber
        recipientName
        recipientPhone
        recipientEmail
        amount
        createdAt
        updatedAt
        redeemCode
        status
        merchantId
        expiredAt
        createdBy
        createdUserId
        sender
      }
      redeemedGiftCards {
        id
        message
        description
        giftCardAccountId
        serialNumber
        recipientName
        recipientPhone
        recipientEmail
        amount
        createdAt
        updatedAt
        redeemCode
        status
        merchantId
        expiredAt
        createdBy
        createdUserId
        sender
      }
      voidedGiftCards {
        id
        message
        description
        giftCardAccountId
        serialNumber
        recipientName
        recipientPhone
        recipientEmail
        amount
        createdAt
        updatedAt
        redeemCode
        status
        merchantId
        expiredAt
        createdBy
        createdUserId
        sender
      }
    }
  }
`;

export const GET_MY_ADJUSTMENT_RECORDS = gql`
  query myAdjustmentRecords {
    giftCardAccount {
      adjustmentRecords {
        id
        giftCardAccountId
        updatedUserId
        amount
        updatedAt
        type
        adjustmentType
        description
        updatedEmail
      }
    }
  }
`;
