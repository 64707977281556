import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DeleteIcon from '@mui/icons-material/Delete';
import PublishedWithChangesSharpIcon from '@mui/icons-material/PublishedWithChangesSharp';
import { Box, Paper, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { AppSection } from '@type/appSection';
import { deleteAppSectionVariables } from '__generated__/deleteAppSection';
import { ContentStatus } from '__generated__/globalTypes';
import { updateAppSectionVariables } from '__generated__/updateAppSection';
import ConfirmDialog from 'components/commonComponents/ConfirmDialog';
import { useSnackbarHelper } from 'components/useSnackbarHelper';
import { cloneDeep } from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  deleteAppSection,
  deleteAppSectionMutation,
  updateAppSectionMutation,
  updateSatus
} from 'redux/slices/appSection';
import { dispatch } from 'redux/store';
import { convertAppSectionInput } from '../tools';
import AppSectionFormBtn from './AppSectionFormBtn';

interface IProps {
  section: AppSection;
  index: number;
}

export const ItemTypes = {
  BOX: 'box'
};

export default function AppSectionItem({ section, index }: IProps) {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbarHelper();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isPublishedDialogOpen, setIsPublishedDialogOpen] = useState(false);

  const deleteSection = useCallback(
    async (sectionId: string) => {
      if (!sectionId) return;
      if (isLoading) return;
      try {
        setIsLoading(true);
        const input: deleteAppSectionVariables = {
          sectionId: sectionId
        };
        const resp = await dispatch(deleteAppSectionMutation(input)).unwrap();
        if (resp) {
          showSnackbar('Delete Success', 'success');
          dispatch(deleteAppSection({ sectionId }));
          return;
        }
        showSnackbar('Delete Fail', 'error');
      } catch (error) {
        showSnackbar('Delete Fail', 'error');
        showSnackbar(error, 'error');
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading, showSnackbar]
  );

  const updateSectionStatus = useCallback(
    async (sectionId: string) => {
      if (!section) return;
      if (isLoading) return;
      try {
        setIsLoading(true);
        const sectionCopy = cloneDeep(section);
        sectionCopy.status =
          section.status === ContentStatus.published
            ? ContentStatus.draft
            : ContentStatus.published;
        const input: updateAppSectionVariables = {
          section: convertAppSectionInput(sectionCopy),
          sectionId: sectionId
        };
        const resp = await dispatch(updateAppSectionMutation(input)).unwrap();
        if (resp) {
          showSnackbar('Update Success', 'success');
          dispatch(updateSatus({ sectionId: sectionCopy.id, status: sectionCopy.status }));
          return;
        }
        showSnackbar('Update Fail', 'error');
      } catch (error) {
        showSnackbar('Update Fail', 'error');
        showSnackbar(error, 'error');
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading, showSnackbar, section]
  );

  const handleDeleteBtnOnClick = async () => {
    await deleteSection(section.id);
  };

  const handlePublishBtnOnClick = async () => {
    await updateSectionStatus(section.id);
  };

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: section.id
  });

  return (
    <Paper
      variant="outlined"
      color="primary"
      sx={{
        mt: index === 0 ? '0px' : '24px',
        position: 'relative',
        padding: '16px',
        cursor: 'move',
        transform: `${CSS.Transform.toString(transform)}`,
        transition
      }}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      <Box
        sx={{
          cursor: 'move',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography sx={{ flex: 4 }}>{`${t('Title')} :`}</Typography>
            <Typography sx={{ flex: 6 }}>{section.title}</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography sx={{ flex: 4 }}>{`${t('Content Type')} :`}</Typography>
            <Typography sx={{ flex: 6 }}>{section.contentType}</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography sx={{ flex: 4 }}>{`${t('View Type')} :`}</Typography>
            <Typography sx={{ flex: 6 }}>{section.viewType}</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography sx={{ flex: 4 }}>{`${t('Status')} :`}</Typography>
            <Typography sx={{ flex: 6 }}>{section.status}</Typography>
          </Box>
        </Box>
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <AppSectionFormBtn section={section} />
          <Button
            sx={{ padding: 0, minWidth: '40px', height: '30px' }}
            onClick={() => setIsDeleteDialogOpen(true)}
          >
            <DeleteIcon color="secondary" fontSize="small" />
          </Button>
          <Button
            disabled={section.status === ContentStatus.published}
            sx={{ padding: 0, minWidth: '40px', height: '30px' }}
            onClick={() => setIsPublishedDialogOpen(true)}
          >
            <PublishedWithChangesSharpIcon
              color={section.status === ContentStatus.published ? 'disabled' : 'secondary'}
              fontSize="small"
            />
          </Button>
        </Box>
        <ConfirmDialog
          title={t('Delete')}
          message={`${t('Are you sure for delete section')} ${section.contentType}`}
          open={isDeleteDialogOpen}
          close={() => setIsDeleteDialogOpen(false)}
          action={handleDeleteBtnOnClick}
        />
        <ConfirmDialog
          title={t('Publish')}
          message={`${t('Are you sure for publish section')} ${section.contentType}`}
          open={isPublishedDialogOpen}
          close={() => setIsPublishedDialogOpen(false)}
          action={handlePublishBtnOnClick}
        />
      </Box>
    </Paper>
  );
}
