import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Subscription } from './CurrentSubscriptions';

interface IProps {
  subscription: Subscription;
}

const SubscriptionItem = ({ subscription }: IProps) => {
  const { t } = useTranslation();
  return (
    <Grid item xs={4}>
      <Card sx={{ maxWidth: 345, minHeight: 220 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {subscription.name}
          </Typography>
          <Typography sx={{ marginTop: '10px' }} gutterBottom variant="h5" component="div">
            ${subscription.unit_amount} / {t('month')}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {t('Status')} : {subscription.status}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {t('Current period start')} : {subscription.currentPeriodStart}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SubscriptionItem;
