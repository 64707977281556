import mock from './mock';
import mockData from '../utils/mock-data';

// ----------------------------------------------------------------------

mock.onGet('/api/order/all').reply(() => {
  const orders = [...Array(24)].map((_, index) => ({
    id: mockData.id(index),
    orderNumber: `000000${index + 1}`,
    createTime: '2022-06-20',
    total: '$300.0',
    guest: 'Mr.Bean',
    orderType: `Takeaway`,
    promotion: `Decrease ${index + 1}%`,
    qrcode: 'JRT5KQOTqnawYNiRbgHD',
    items: [
      {
        image: 'https://www3.nhk.or.jp/nhkworld/en/radio/cooking/update/meal_200228_l.jpg',
        name: 'Fried Okayama Oyster with OMI Sauce',
        quantity: 2,
        options: 'Salted Egg',
        price: '$ 100.0',
        status: 'Success',
        size: 'SM',
        specialInstructions: 'No'
      },
      {
        image: 'https://www.hopscotch.sg/wp-content/uploads/2020/06/Wagyu-Burger.jpg',
        name: 'Signature Wagyu Burger',
        quantity: 3,
        options: 'Black Truffle Sauce',
        price: '$ 100.0',
        status: 'Fail',
        size: 'XL',
        specialInstructions: 'No'
      },
      {
        image: 'https://www3.nhk.or.jp/nhkworld/en/radio/cooking/update/meal_200228_l.jpg',
        name: 'Aburi Hokkaido Scallop',
        quantity: 4,
        options: 'Signature Teriyaki',
        price: '$ 100.0',
        status: 'Success',
        size: 'XXL',
        specialInstructions: 'No'
      }
    ]
  }));

  return [200, { orders }];
});
