import { Card, Grid } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
// routes
// @types
import { Promotion } from '../../../../@type/promotion';

// import DatePicker from '@mui/lab/DatePicker';
import { PromotionType } from '__generated__/globalTypes';
import LoadingComponent from 'components/LoadingComponent';
import { useSnackbarHelper } from 'components/useSnackbarHelper';
import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { RootState } from 'redux/store';
import { PATH_DASHBOARD } from 'routes/paths';
import { DeepKeyOf } from '../formik/type';
import { promoValidationSchema } from '../promoValidationSchema';
import {
  createPromoFunc,
  getInitialPromotionState,
  queryPromoFunc,
  updatePromoFunc
} from '../tools';
import { usePromoStepper } from './stepper/stepper';
import { StepLabel } from './stepper/steps';
import { VoucherBaseInfo } from './tabs/voucher/baseInfo';
import { VoucherConditions } from './tabs/voucher/conditions';
import { VoucherDetails } from './tabs/voucher/details';
import { VoucherReward } from './tabs/voucher/reward';
import { Summary } from './tabs/voucher/summary';
import { PromotionProps } from './type';

export const CreatePromotionForm = (props: PromotionProps) => {
  const { showSnackbar } = useSnackbarHelper();
  const user = useSelector((state: RootState) => state.auth.user);
  const params = useParams();
  const navigate = useNavigate();
  const { promoType = PromotionType.percentage } = useParams<{ promoType: PromotionType }>();
  const { isLoyalty } = props;
  // get user form redux
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isEdit = params.name ? true : false;
  const [promotion] = useState<Promotion>(
    getInitialPromotionState(promoType, false, isLoyalty, user?.merchantId)
  );
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: promotion,
    validationSchema: promoValidationSchema,
    onSubmit: async () => {}
  });
  const { setFieldTouched, setValues } = formik;

  const { currentStep, setCanMoveFunc, BreadcrumbsComponent, MoveToStepComponent } =
    usePromoStepper({
      isSubmitting,
      action: CreateOrUpdatePromotion(isEdit),
      promotion: formik.values
    });

  const setFieldTouchedName = useCallback(
    async (name: DeepKeyOf<Promotion>) => {
      if (!name) return;
      const formikErrors = await setFieldTouched(name, true, true);
      if (!formikErrors) return;
      const error = get(formikErrors, name) as string | undefined;
      if (!error) return;
      throw new Error(error);
    },
    [setFieldTouched]
  );

  const fetchData = useCallback(async () => {
    if (!params.name) return;
    try {
      setIsLoading(true);
      const promotion = await queryPromoFunc(promoType, params.name);
      if (!promotion) return;
      setValues(promotion);
    } catch (error) {
      console.error('Error fetching promotion:', error);
    } finally {
      setIsLoading(false);
    }
  }, [params.name, promoType, setValues]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function createPromotion(isPublished: boolean) {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const isSuccess = await createPromoFunc(formik.values, promoType, isPublished);
      if (isSuccess) {
        showSnackbar('Promotion created successfully', 'success');
        setIsSubmitting(false);
        navigate(PATH_DASHBOARD.promotion.list);
      }
    } catch (error) {
      showSnackbar(error.message, 'error');
      setIsSubmitting(false);
    }
  }

  async function updatePromotion(isPublished: boolean) {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const isSuccess = await updatePromoFunc(formik.values, promoType, isPublished);
      if (isSuccess) {
        showSnackbar('Promotion update successfully', 'success');
        setIsSubmitting(false);
        navigate(PATH_DASHBOARD.promotion.list);
      }
    } catch (error) {
      showSnackbar(error.message, 'error');
      setIsSubmitting(false);
    }
  }

  function CreateOrUpdatePromotion(isEdit: boolean) {
    if (isEdit) {
      return updatePromotion;
    } else {
      return createPromotion;
    }
  }

  if (isLoading && isEdit) {
    return <LoadingComponent />;
  }
  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off">
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={10} md={10}>
            <Card sx={{ p: 3 }}>
              <BreadcrumbsComponent />
              {currentStep === StepLabel.VoucherBaseInfo && (
                <VoucherBaseInfo
                  formik={formik}
                  setCanMoveFunc={setCanMoveFunc}
                  setFieldTouchedNameFunc={setFieldTouchedName}
                />
              )}
              {currentStep === StepLabel.VoucherDetails && (
                <VoucherDetails
                  formik={formik}
                  setCanMoveFunc={setCanMoveFunc}
                  setFieldTouchedNameFunc={setFieldTouchedName}
                />
              )}
              {currentStep === StepLabel.VoucherConditions && (
                <VoucherConditions
                  formik={formik}
                  setCanMoveFunc={setCanMoveFunc}
                  setFieldTouchedNameFunc={setFieldTouchedName}
                />
              )}
              {currentStep === StepLabel.LoyaltyReward && (
                <VoucherReward
                  formik={formik}
                  setCanMoveFunc={setCanMoveFunc}
                  setFieldTouchedNameFunc={setFieldTouchedName}
                />
              )}
              {currentStep === StepLabel.VoucherSummary && <Summary formik={formik} />}
              <MoveToStepComponent />
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};
