import { Box, CircularProgress } from '@mui/material';
import { OBApp } from '__generated__/globalTypes';
import { useCallback, useEffect, useState } from 'react';
import { queryFAQ } from 'redux/slices/faq';
import { dispatch, RootState, useSelector } from 'redux/store';
import { PreviewSectionsBox } from '../sections/previewSectionsBox';

interface IFAQPreviewBoxBoxProps {
  app: OBApp;
}
export const FAQPreviewBox = (props: IFAQPreviewBoxBoxProps) => {
  const { app } = props;
  // const { showSnackbar } = useSnackbarHelper();
  const FAQData = useSelector((state: RootState) => state.FAQ.FAQData);
  const sections = FAQData[app] || [];

  const [isLoading, setIsLoading] = useState(false);

  const fetchFAQ = useCallback(async () => {
    setIsLoading(true);
    try {
      await dispatch(queryFAQ({ app }));
    } catch (error) {
      console.error('Failed to fetch FAQ data', error);
    } finally {
      setIsLoading(false);
    }
  }, [app, setIsLoading]);

  useEffect(() => {
    fetchFAQ();
  }, [fetchFAQ]);

  return (
    <>
      {isLoading && (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CircularProgress />
        </Box>
      )}
      {!isLoading && <PreviewSectionsBox sections={sections} />}
    </>
  );
};
