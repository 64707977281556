import { useEffect, useState } from 'react';
// material
import {
  Box,
  Button,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
// utils
//
import { useLazyQuery } from '@apollo/client';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Icon } from '@iconify/react';
import { PromotionData } from '@type/promotion';
import LoadingComponent from 'components/LoadingComponent';
import SearchNotFound from 'components/SearchNotFound';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'routes/paths';
import { GET_ALL_PROMOTIONS } from '_apis_/queries/promotion';
import { PromotionStatus } from '__generated__/globalTypes';
import Scrollbar from '../../Scrollbar';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ActivePromotions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activePromotions, setActivePromotions] = useState<PromotionData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [queryGetAllPromotions] = useLazyQuery(GET_ALL_PROMOTIONS, {
    onCompleted(response) {
      if (response.getPromotions) {
        const temp = response.getPromotions?.filter((item: PromotionData) => {
          return item.status === PromotionStatus.published;
        });
        setActivePromotions(temp);
      }
      setIsLoading(false);
    },
    fetchPolicy: 'network-only',
    onError(error) {
      setIsLoading(false);
    }
  });

  const handleSeeAll = () => {
    navigate(PATH_DASHBOARD.promotion.list);
  };

  useEffect(() => {
    queryGetAllPromotions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryGetAllPromotions]);

  const isNoItem = activePromotions?.length === 0;
  return (
    <Card>
      <CardHeader title={t('Active Promotions')} sx={{ mb: 3 }} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '25%' }}>{t('Name')}</TableCell>
                <TableCell style={{ width: '25%' }}>{t('Description')}</TableCell>
                <TableCell style={{ width: '25%' }}>{t('Type')}</TableCell>
                <TableCell style={{ width: '25%' }}>{t('Used Quantity')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow style={{ height: 53 }}>
                  <TableCell colSpan={6}>
                    <LoadingComponent />
                  </TableCell>
                </TableRow>
              ) : (
                activePromotions?.map((row) => {
                  const { id, name, description, usedQuantity, type } = row;
                  return (
                    <TableRow key={id}>
                      <TableCell align="left">{name}</TableCell>
                      <TableCell align="left">{description}</TableCell>
                      <TableCell align="left">{type}</TableCell>
                      <TableCell align="left">{usedQuantity}</TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
            {isNoItem && !isLoading && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound isNoItem={isNoItem} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          size="small"
          color="inherit"
          onClick={handleSeeAll}
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          {t('View All')}
        </Button>
      </Box>
    </Card>
  );
}
