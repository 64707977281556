import { FLOAT } from '@type/global';
import { Promotion } from '@type/promotion';
import { PromotionType, PromotionUseType } from '__generated__/globalTypes';
import {
  createListValidation,
  createStringValidation,
  createStrNumberValidation
} from 'utils/regexConfig';
import { SchemaOf, object, string, number, boolean, array, mixed } from 'yup';

export const promoValidationSchema: SchemaOf<Partial<Promotion>> = object({
  id: string().optional(),
  name: createStringValidation({}),
  description: createStringValidation({ isRequired: false }),
  status: string().optional(),
  type: mixed<PromotionType>()
    .oneOf(Object.values(PromotionType), 'Invalid promotion type')
    .optional(),
  numberOfDeals: createStrNumberValidation({}),
  usedQuantity: number().optional(),
  createdAt: number().optional(),
  minimumAmount: createStrNumberValidation({
    type: FLOAT,
    isGreaterThanZero: false,
    isRequired: false
  }),
  merchantId: createStringValidation({ isRequired: true }),
  canBeUsedWithOtherPromotions: boolean().required(),
  effectiveDateTime: createListValidation({ isRequired: true }),
  excludedPromotionTags: createListValidation({ isRequired: false }),
  includedPromotionTags: createListValidation({ isRequired: false }),
  includedGetItemsPromoTags: createListValidation({ isRequired: false }),
  availableType: createListValidation({ isRequired: false }),
  buyN: createStrNumberValidation({ isRequired: false }),
  freeN: createStrNumberValidation({}),
  percentage: createStrNumberValidation({
    isGreaterThanZero: false,
    type: FLOAT,
    isRequired: false
  }),
  discountAmount: createStrNumberValidation({
    isGreaterThanZero: false,
    type: FLOAT,
    isRequired: false
  }),
  isPercentage: boolean().required(),
  applyOptionDiscount: boolean().required(),
  discountedItemQty: createStrNumberValidation({}),
  isSignUpPromotion: boolean().required(),
  upToAmount: createStrNumberValidation({ type: FLOAT, isGreaterThanZero: false }),
  isOffer: boolean().required(),
  offerVoucherSettings: object()
    .shape({
      offerName: createStringValidation({ isRequired: true }),
      offerDescription: createStringValidation({ isRequired: false }),
      effectiveHoursAfterOfferActivated: createStrNumberValidation({
        isGreaterThanZero: false,
        isRequired: true
      }),
      effectiveDateTime: createListValidation({ isRequired: true }),
      offerExcludedItemTags: createListValidation({ isRequired: true }),
      minimumAmount: createStrNumberValidation({ type: FLOAT, isGreaterThanZero: false }),
      upToAmount: createStrNumberValidation({ type: FLOAT, isGreaterThanZero: false }),
      voucherTerms: createStringValidation({ isRequired: true })
    })
    .optional(),
  useType: mixed<PromotionUseType>()
    .oneOf(Object.values(PromotionUseType), 'Invalid promotion use type')
    .optional(),
  prizeDescription: string().optional(),
  winnerCount: createStrNumberValidation({ isRequired: true }),
  entryLimitPerUser: createStrNumberValidation({ isRequired: true }),
  winCountLimitPerPerson: createStrNumberValidation({ isRequired: true }),
  isEntryAutomatic: boolean().optional(),
  resultingPromotions: createListValidation({ isRequired: true }),
  isPaymentRequired: boolean().optional(),
  isHiddenInApp: boolean().optional(),
  isLoyalty: boolean().optional(),
  // shape is required for nested objects
  loyaltyDetails: object().shape({
    expiryDays: createStrNumberValidation({ isGreaterThanZero: false }),
    loyaltyReviewDate: createStrNumberValidation({})
  }),
  loyaltySettings: object().shape({
    pointsPerDollar: createStrNumberValidation({}),
    maxPointsPerTransaction: createStrNumberValidation({ isGreaterThanZero: false }),
    expiryDays: createStrNumberValidation({ isGreaterThanZero: false }),
    loyaltyReviewDate: createStrNumberValidation({}),
    loyaltyBenefits: array()
      .of(
        object().shape({
          name: createStringValidation({ isRequired: true }),
          description: createStringValidation({ isRequired: true }),
          requiredPoints: createStrNumberValidation({ isGreaterThanZero: true }),
          benefitPromotionIds: array()
            .of(createStringValidation({ isRequired: true }))
            .min(1, 'At least one promotion ID is required')
        })
      )
      .min(1, 'At least one loyalty benefit is required')
      .default([])
  })
})
  .test(
    'minimumAmount-buyN-check',
    'minimumAmount and buyN cannot be both empty, and at least one must meet the condition',
    function (values) {
      const { minimumAmount, buyN } = values;

      // If both minimumAmount and buyN are undefined or null, throw error
      if (!minimumAmount && !buyN) {
        return this.createError({
          path: 'minimumAmount',
          message: 'minimumAmount and buyN cannot be both empty'
        });
      }

      // If minimumAmount is null or undefined, buyN must be greater than 0
      if (!minimumAmount) {
        const buyNValue = Number(buyN);
        if (buyNValue <= 0) {
          return this.createError({
            path: 'buyN',
            message: 'If minimumAmount is empty, buyN must be greater than 0'
          });
        }
      }

      // If buyN is null or undefined, minimumAmount must be greater than or equal to 0
      if (!buyN) {
        const minimumAmountValue = Number(minimumAmount);
        if (minimumAmountValue < 0) {
          return this.createError({
            path: 'minimumAmount',
            message: 'If buyN is empty, minimumAmount must be greater than or equal to 0'
          });
        }
      }

      // If all conditions are met, pass the validation
      return true;
    }
  )
  .test('percentage-discountAmount-check', 'discount cannot be empty', function (values) {
    const { percentage, discountAmount } = values;

    // If both minimumAmount and buyN are undefined or null, throw error
    if (!percentage && !discountAmount) {
      return this.createError({
        path: 'percentage',
        message: 'discount cannot be empty'
      });
    }

    // If minimumAmount is null or undefined, buyN must be greater than 0
    if (!percentage) {
      const discountAmountValue = Number(discountAmount);
      if (discountAmountValue < 0) {
        return this.createError({
          path: 'discountAmountValue',
          message: 'If percentage is empty, discount amount must be greater than 0'
        });
      }
    }

    // If buyN is null or undefined, minimumAmount must be greater than or equal to 0
    if (!discountAmount) {
      const percentageValue = Number(percentage);
      if (percentageValue < 0) {
        return this.createError({
          path: 'percentage',
          message: 'If discount amount is empty, percentage must be greater than to 0'
        });
      }
    }

    // If all conditions are met, pass the validation
    return true;
  });
