import { OBApp } from '__generated__/globalTypes';
import { FAQPreviewBox } from 'components/_dashboard/FAQ/components/preview/preview';
import { useParams } from 'react-router';

const FAQ = () => {
  const { app } = useParams();
  const validAPP = Object.values(OBApp).includes(app as OBApp)
    ? (app as OBApp)
    : OBApp.orderbuddyApp;

  return (
    <>
      <FAQPreviewBox app={validAPP} />
    </>
  );
};
export default FAQ;
