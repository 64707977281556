import Button from '@mui/material/Button';
import { Icon } from '@iconify/react';

export enum ItemButtonColor {
  DEFAULT = 'DEFAULT',
  NEUTRALS = 'NEUTRALS',
  OFFER = 'OFFER'
}

interface ItemButtonProps {
  onClick?: () => void;
  content: string;
  color?: ItemButtonColor;
}

const ItemButton: React.FC<ItemButtonProps> = (props) => {
  const { onClick, content, color = ItemButtonColor.DEFAULT } = props;
  const getBackgroundColor = () => {
    switch (color) {
      case ItemButtonColor.NEUTRALS:
        return { backgroundColor: '#B8B8B8' };
      case ItemButtonColor.OFFER:
        return { backgroundColor: '#DCC179' };
      default:
        return { backgroundColor: '' }; // 默认背景色
    }
  };
  return (
    <>
      <Button
        variant="contained"
        onClick={onClick ? onClick : () => {}}
        size="small"
        endIcon={onClick ? <Icon icon="material-symbols:close" /> : null}
        sx={{
          borderRadius: '15px',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: getBackgroundColor().backgroundColor,
            boxShadow: 'none'
          },
          ...getBackgroundColor()
        }}
      >
        {content}
      </Button>
    </>
  );
};

export default ItemButton;
