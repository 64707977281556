import { LoadingButton } from '@mui/lab';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { AppSection } from '@type/appSection';
import { Merchant } from '@type/merchant';
import { createAppSectionVariables } from '__generated__/createAppSection';
import {
  AppContentLocation,
  AppContentType,
  AppViewType,
  ContentStatus,
  MerchantStatus
} from '__generated__/globalTypes';
import { updateAppSectionVariables } from '__generated__/updateAppSection';
import { renderContent, tabContent } from 'components/_dashboard/promotion/create/tabs/tabContent';
import { CommonInput } from 'components/_dashboard/promotion/formik/commonInput';
import { CommonSelect } from 'components/_dashboard/promotion/formik/commonSelect';
import { DeepKeyOf } from 'components/_dashboard/promotion/formik/type';
import { CommonTable } from 'components/commonComponents/commonTable/commonTable';
import { ICommonTableHeadColumn } from 'components/commonComponents/commonTable/commonTableHead/commonTableHead';
import { ISearchBox } from 'components/commonComponents/commonTable/commonTableToolbar/commonTableToolbar';
import { useSnackbarHelper } from 'components/useSnackbarHelper';
import { Form, FormikProvider, useFormik } from 'formik';
import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createAppSectionMutation,
  fetchAPPSections,
  updateAppSection,
  updateAppSectionMutation
} from 'redux/slices/appSection';
import { queryAllMerchants } from 'redux/slices/merchant';
import { dispatch, RootState, useSelector } from 'redux/store';
import { appSectionValidationSchema } from '../appSectionValidationSchema';
import { convertAppSectionInput, initAppSection } from '../tools';
import { APPSectionFormTableRow } from './APPSectionFormTableRow';

interface AppSectionFormDialogContentProps {
  section?: AppSection;
  setIsDialogOpen: (isOpen: boolean) => void;
}
const MERCHANT_TABLE_HEAD: ICommonTableHeadColumn<Merchant>[] = [
  { property: null, label: '', align: 'left' },
  { property: 'name', label: 'Name', align: 'left' },
  { property: 'type', label: 'Type', align: 'left' }
];

const SEARCH_BOX: ISearchBox<Merchant> = {
  list: ['name', 'altName'],
  placeholder: 'Search by name, altName'
};

export const AppSectionFormDialogContent = (props: AppSectionFormDialogContentProps) => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbarHelper();
  const { section, setIsDialogOpen } = props;
  const { appSections } = useSelector((state: RootState) => state.appSection);
  const { merchantList } = useSelector((state: RootState) => state.merchant);
  const [filteredMerchantList, setFilteredMerchantList] = useState<Merchant[]>(merchantList);
  const [sort, setSort] = useState<number>(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [appSection] = useState<AppSection>(section || initAppSection);
  const [isLoading, setIsLoading] = useState(false);

  const isEdit = section ? true : false;
  const formik = useFormik<AppSection>({
    enableReinitialize: true,
    initialValues: appSection,
    validationSchema: appSectionValidationSchema,
    onSubmit: async () => {}
  });
  const { values, setFieldValue, setFieldTouched } = formik;
  const { merchants } = values;

  const handleTabIndexOnChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const updateSection = useCallback(async () => {
    try {
      setIsLoading(true);
      const input: updateAppSectionVariables = {
        sectionId: values.id,
        section: convertAppSectionInput(values)
      };
      const resp = await dispatch(updateAppSectionMutation(input)).unwrap();
      if (resp) {
        dispatch(updateAppSection({ sectionId: values.id, section: values }));
        showSnackbar('Update Success', 'success');
        setIsLoading(false);
        setIsDialogOpen(false);
        return;
      }
      showSnackbar('Update Fail', 'error');
    } catch (error) {
      showSnackbar('Update Fail', 'error');
      setIsLoading(false);
    }
  }, [values, setIsLoading, showSnackbar, setIsDialogOpen]);

  const createAppSection = useCallback(async () => {
    try {
      setIsLoading(true);
      const input: createAppSectionVariables = {
        section: convertAppSectionInput(values)
      };
      input.section.sort = sort;
      const resp = await dispatch(createAppSectionMutation(input)).unwrap();
      if (resp) {
        showSnackbar('Create Success', 'success');
        await dispatch(fetchAPPSections());
        setIsLoading(false);
        setIsDialogOpen(false);
        return;
      }
      showSnackbar('Create Fail', 'error');
    } catch (error) {
      showSnackbar('Create Fail', 'error');
      setIsLoading(false);
    }
  }, [values, sort, setIsLoading, showSnackbar, setIsDialogOpen]);

  const setFieldTouchedName = useCallback(
    async (name: DeepKeyOf<AppSection>) => {
      if (!name) return;
      const formikErrors = await setFieldTouched(name, true, true);
      if (!formikErrors) return;
      const error = get(formikErrors, name) as string | undefined;
      if (!error) return;
      throw new Error(error);
    },
    [setFieldTouched]
  );

  const createSection = async () => {
    // pass the validation?
    // set the touched on all fields
    console.log('values', values);
    try {
      await setFieldTouchedName('title');
      await setFieldTouchedName('radiumInKm');
      await setFieldTouchedName('coordinate.latitude');
      await setFieldTouchedName('coordinate.longitude');
      if (isEdit) {
        updateSection();
      } else {
        createAppSection();
      }
    } catch (error) {
      showSnackbar(error.message, 'error');
      return;
    }
  };

  const queryMerchants = useCallback(async () => {
    try {
      await dispatch(queryAllMerchants()).unwrap();
    } catch (error) {
      showSnackbar(error.message, 'error');
    } finally {
    }
  }, [showSnackbar]);

  const checkBoxOnChange = (merchant: Merchant) => {
    const merchantId = merchant.merchantId;

    const currentMerchants = merchants || [];

    const isMerchantSelected = currentMerchants.some((m) => m.merchantId === merchantId);

    if (currentMerchants.length === 0) {
      setFieldValue('merchants', [merchant]);
      return;
    }

    if (isMerchantSelected) {
      setFieldValue(
        'merchants',
        currentMerchants.filter((m) => m.merchantId !== merchantId)
      );
      return;
    }

    if (currentMerchants.length >= 4) {
      showSnackbar('Maximum 4 merchants for the list', 'error');
      return;
    }

    setFieldValue('merchants', [...currentMerchants, merchant]);
  };

  useEffect(() => {
    queryMerchants();
  }, [queryMerchants]);

  useEffect(() => {
    // find the merchant is active
    const filteredMerchants = merchantList.filter(
      (merchant) => merchant.merchantStatus === MerchantStatus.active
    );
    setFilteredMerchantList(filteredMerchants);
  }, [merchantList, setFilteredMerchantList]);

  useEffect(() => {
    const sortList = appSections.map((section) => section.sort);
    const maxSort = Math.max(...sortList);
    setSort(maxSort + 1);
  }, [appSections]);

  const commonContents: tabContent[] = [
    {
      mdSize: 12,
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'title'}
          label="Title"
          placeholder="Please enter title"
        />
      )
    },
    {
      mdSize: 12,
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'description'}
          label="Description"
          placeholder="Please enter the description"
          multiline={true}
        />
      )
    },
    {
      node: (
        <CommonSelect
          formik={formik}
          fieldPath={'contentType'}
          options={AppContentType}
          label="Content Type"
        />
      ),
      mdSize: 6
    },
    {
      node: (
        <CommonSelect
          formik={formik}
          fieldPath={'location'}
          options={AppContentLocation}
          label="Location"
        />
      ),
      mdSize: 6
    },
    {
      node: (
        <CommonSelect formik={formik} fieldPath={'viewType'} options={AppViewType} label="View" />
      ),
      mdSize: 6
    },
    {
      node: (
        <CommonSelect formik={formik} fieldPath={'status'} label="Status" options={ContentStatus} />
      ),
      mdSize: 6
    },
    {
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'radiumInKm'}
          type={'number'}
          label="Radium In Km"
        />
      ),
      mdSize: 12
    },
    {
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'coordinate.latitude'}
          label="Latitude"
          type={'number'}
        />
      ),
      mdSize: 6
    },
    {
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'coordinate.longitude'}
          label="Longitude"
          type={'number'}
        />
      ),
      mdSize: 6
    }
  ];

  return (
    <Box sx={{ height: '100%' }}>
      <Tabs
        value={tabIndex}
        onChange={handleTabIndexOnChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="action tabs example"
        sx={{ mb: '20px' }}
      >
        <Tab label={t('Information')} iconPosition="end" />
        <Tab label={t('Merchants')} iconPosition="end" />
      </Tabs>
      {tabIndex === 0 && (
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={createSection}>
            <Grid sx={{ width: '100%' }} container spacing={1}>
              {renderContent(commonContents)}
            </Grid>
          </Form>
        </FormikProvider>
      )}
      {tabIndex === 1 && (
        <Box>
          <Typography sx={{ marginLeft: '24px' }}>
            {t('Maximum 4 merchants for the list')}
          </Typography>
          <CommonTable
            searchBox={SEARCH_BOX}
            columnList={MERCHANT_TABLE_HEAD}
            dataList={filteredMerchantList}
            isLoading={isLoading}
            tableRowKey={'merchantId'}
            RowBodyComponent={APPSectionFormTableRow}
            rowBodyProps={{ merchants, checkBoxOnChange }}
            sx={{ minWidth: '100%', maxHeight: '100%' }}
          />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 'auto'
        }}
      >
        <LoadingButton
          loading={isLoading}
          onClick={() => {
            setIsDialogOpen(false);
          }}
        >
          Close
        </LoadingButton>
        <LoadingButton loading={isLoading} onClick={() => createSection()}>
          Save
        </LoadingButton>
      </Box>
    </Box>
  );
};
