// material
import { Container, Divider, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import {
  GiftCardTermsAndConditions,
  GiftCardTermsSubTitle,
  GiftCardTermsTitle,
  GiftCardTermsUpdateStr
} from './components/consts';
// ----------------------------------------------------------------------
export default function Terms() {
  return (
    <Container
      sx={{
        mt: 10,
        width: { xs: '100vw', sm: '70vw' },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: { xs: 2, sm: 3 },
          justifyContent: 'center'
        }}
      >
        <Typography variant="h3" gutterBottom>
          {GiftCardTermsTitle}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {GiftCardTermsSubTitle}
        </Typography>
        <Divider variant="middle" />
        <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
          {GiftCardTermsUpdateStr}
        </Typography>
        <List>
          {GiftCardTermsAndConditions.map((Terms, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={
                  <Typography variant="body1" component="span" sx={{ fontWeight: 'bold' }}>
                    {index + 1}. {Terms.title}
                  </Typography>
                }
                primaryTypographyProps={{ component: 'div' }}
                secondary={
                  <div>
                    {Terms.bodys.map((line, idx) => (
                      <Typography key={idx} component="div" sx={{ mt: idx === 0 ? 0 : 2 }}>
                        {line}
                      </Typography>
                    ))}
                  </div>
                }
                secondaryTypographyProps={{ component: 'div' }}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Container>
  );
}
